import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createViewAggregated } from '../../../store/ApiSlice/canvasSlice';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Loader from '../../loader/Loader';
import HighchartsMore from 'highcharts/highcharts-more';
import Heatmap from 'highcharts/modules/heatmap';
import Treemap from 'highcharts/modules/treemap';


Heatmap(Highcharts);
Treemap(Highcharts);
HighchartsMore(Highcharts);

const ChartMain = ({ groupName, setLoader, finalData, loader, aggregatedViewData }) => {

  const { filterDetail } = useSelector((state) => state.canvas);
  const { pending, chartPending } = useSelector((state) => state.widget)
  const dispatch = useDispatch();
  const [chartType, setChartType] = useState();

  const formatTimePeriods = (timePeriods) => {
    return (finalData?.widgetUID?.widgetSubType === "Measure Data" || finalData?.widgetUID?.widgetType === "Chart") && `Time Periods (${timePeriods})`;
  };
  const timePeriodsFormatted = formatTimePeriods(finalData?.widgetUID?.chart?.timePeriods?.periodType);
  const getChartWidgetsData = async (findFilterId) => {
    setLoader({
      ...loader,
      [groupName]: true
    });
    const timeName = finalData?.widgetUID?.chart?.timePeriods?.periodType;

    const payload = {
      ...(finalData?.widgetUID?.chart?.measures?.length && {
        measures: finalData?.widgetUID?.chart?.measures,
      }),
      timeFields: {
        [timeName]: finalData?.widgetUID?.chart?.timePeriods?.periodValues,
      },
      aggregationFields: finalData?.widgetUID?.chart?.attributes?.map(item => item?.attributeName),
      scenario: ['Baseline'],
      ...(finalData?.widgetUID?.cycleID?.length && {
        cycleID: finalData?.widgetUID?.cycleID,
      }),
      ...(finalData?.widgetUID?.lagID?.length && {
        lagID: finalData?.widgetUID?.lagID,
      }),
      ...(findFilterId?.attributes?.length && {
        additionalFilters: findFilterId?.attributes?.map(item => ({
          attribute: item?.attributeUID?.name,
          operator: item?.operator,
          values: item?.values,
        })),
      }),
    };

    const result = await dispatch(createViewAggregated({ payload }));
    if (result?.meta?.requestStatus === 'fulfilled') {
      setLoader({
        ...loader,
        [groupName]: false
      });
    } else if (result?.meta?.requestStatus === 'rejected') {
      toast.error(result?.error?.message);
      setLoader({
        ...loader,
        [groupName]: false
      });
    }
  };

  // useEffect(() => {
  //   if (filterDetail && (finalData?.widgetUID?.filter || finalData?.widgetUID?.filter?._id)) {

  //     const findFilterId = filterDetail?.find(item =>
  //       item?._id === finalData?.widgetUID?.filter || item?._id === finalData?.widgetUID?.filter?._id
  //     );
  //     if (findFilterId) {
  //       getChartWidgetsData(findFilterId);
  //     }
  //   } else {
  //     getChartWidgetsData("")
  //   }
  // }, [finalData?.widgetUID]);

  useEffect(() => {
    setChartType(finalData?.widgetUID.chart.chartSettings.type);
  }, [finalData?.widgetUID.chart.chartSettings.type]);

  let seriesData = [];
  let categories = [];
  let chartData = []
  let chartMeasureName = []
  let isPercentageData = false;
  const attributeDataName = finalData?.widgetUID?.chart?.attributes?.map((item) => item?.attributeName)
  const extractNumber = (str) => parseInt(str?.split('_')[1], 10);

  // Dynamic sort function
  const dynamicSort = (data, attributes) => {
    // Create a shallow copy of the array to avoid modifying the original
    const dataCopy = [...data];

    return dataCopy?.sort((a, b) => {
      for (const attribute of attributes) {
        const diff = extractNumber(a[attribute]) - extractNumber(b[attribute]);
        if (diff !== 0) {
          return diff;
        }
      }
      return 0; // If all comparisons are equal, they are the same in order
    });
  };

  // Sort the data based on dynamic attributes
  const sortedData = dynamicSort([...aggregatedViewData || []], attributeDataName);

  const state = finalData?.widgetUID?.chart?.attributes?.reduce((acc, attr, index) => {
    const attributeName = attr.attributeName;
    const key = `name${index + 1}`;

    const uniqueData = sortedData?.length > 0
      ? Array?.from(sortedData?.map(data => data[attributeName]))
      : [];

    acc[key] = uniqueData;
    return acc;
  }, {});

  if (sortedData?.length) {

    let dataByMeasure = {};

    sortedData.forEach(entry => {
      const measureName = entry["Measure Name"];

      const customerData = Object.keys(entry)
        .filter(key => key !== "Measure Name" && (key?.startsWith("M") || key?.startsWith("Q") || key?.startsWith("Y") || key?.startsWith("D") || key?.startsWith("W")))
        .map(key => {
          const value = entry[key];

          if (typeof value === 'string' && value?.includes('%')) {
            isPercentageData = true;
            return parseFloat(value?.replace('%', ''));
          } else if (value) {
            if (/\d/.test(value) && !value?.includes('%')) {
              isPercentageData = false;
              return Number(value);
            } else {
              return typeof value === 'number' ? value : 0;
            }
          }
          return typeof value === 'number' ? value : 0;
        });

      if (!dataByMeasure[measureName]) {
        dataByMeasure[measureName] = [];
      }
      dataByMeasure[measureName] = dataByMeasure[measureName]?.concat(customerData);

      if (!categories?.length) {
        categories = Object.keys(entry)?.filter(key => key !== "Measure Name" && (key?.startsWith("M") || key?.startsWith("Q") || key?.startsWith("Y") || key?.startsWith("D") || key?.startsWith("w")));
      }
    });

    seriesData = Object.keys(dataByMeasure)?.map(measureName => ({
      name: measureName,
      data: dataByMeasure[measureName]
    }));
  }

  const getDataSlices = () => {
    const columnCount = Math.min(3, seriesData.length);
    const lineCount = Math.min(1, seriesData.length - columnCount);
    const pieCount = Math.max(0, seriesData.length - columnCount - lineCount);
    return {
      columnData: seriesData.slice(0, columnCount),
      lineData: seriesData.slice(columnCount, columnCount + lineCount),
      pieData: seriesData.slice(columnCount + lineCount, columnCount + lineCount + pieCount),
    };
  };

  const { columnData, lineData, pieData } = getDataSlices();
  const splineData = seriesData?.length > 0 ? seriesData?.map((family, index) => {
    const colorKey = finalData?.widgetUID.chart.chartSettings ? finalData?.widgetUID.chart.chartSettings[family?.name] : '';
    const color = finalData?.widgetUID.chart.chartSettings?.type === "spline" ? colorKey : 'rgba(0, 100, 200, 0.3)';
    return {
      type: "spline",
      name: family.name,
      data: family.data,
      color: color
    }
  }) : []

  const uniqueSeriesData = seriesData?.filter((family, index, self) =>
    index === self.findIndex(f => f.name === family.name)
  );

  const barChartData = uniqueSeriesData?.length > 0 ? uniqueSeriesData?.map((family, index) => {
    const colorKey = finalData?.widgetUID.chart.chartSettings ? finalData?.widgetUID.chart.chartSettings[family?.name] : '';
    const color = finalData?.widgetUID.chart.chartSettings?.type === "bar" ? colorKey : 'rgba(0, 100, 200, 0.3)';
    return {
      type: finalData?.widgetUID.chart.chartSettings?.type === "bar" && finalData?.widgetUID.chart.chartSettings?.orientation === "vertical" ? "column" : "bar",
      name: family.name,
      data: family.data,
      color: color
    }
  }) : []

  const areaChartData = uniqueSeriesData?.length > 0 ? uniqueSeriesData?.map((family, index) => {
    const colorKey = finalData?.widgetUID.chart.chartSettings ? finalData?.widgetUID.chart.chartSettings[family?.name] : '';
    const color = finalData?.widgetUID.chart.chartSettings?.type === "area" ? colorKey : 'rgba(0, 100, 200, 0.3)';
    return {
      type: 'area',
      name: family?.name,
      data: family?.data,
      color: color
    }
  }) : []

  const areaSplineData = seriesData?.length > 0 ? seriesData?.map(series => {
    const colorKey = finalData?.widgetUID.chart.chartSettings ? finalData?.widgetUID.chart.chartSettings[series?.name] : '';
    const color = finalData?.widgetUID.chart.chartSettings?.type === "areaspline" ? colorKey : 'rgba(0, 100, 200, 0.3)';
    return {
      ...series,
      type: 'areaspline',
      color: color
    }
  }) : [];


  const heatmapDatas = seriesData?.length > 0 && seriesData?.flatMap((series, seriesIndex) => {
    return series.data.map((value, index) => [index, seriesIndex, value])
  });
  // }) : [[0, 0, 0]];


  const treemapData = seriesData?.length > 0 ? seriesData?.map(series => ({
    name: series.name,
    value: series.data.reduce((a, b) => a + b, 0)
  })) : [];

  const spiderData = seriesData?.length > 0 ? seriesData?.map(series => ({
    name: series.name,
    data: series.data,
    pointPlacement: "on"
  })) : [];

  const CombineDataChart = finalData?.widgetUID?.chart?.chartSettings?.type === "combination" && seriesData?.map(series => {
    const seriesType = finalData?.widgetUID?.chart?.chartSettings?.measure?.[series.name] || 'column';
    const measureChartType = (seriesType === 'bar' && finalData?.widgetUID?.chart?.chartSettings?.orientation === 'vertical')
      ? 'column'
      : seriesType ? seriesType : "spline";
    const colorKey = finalData?.widgetUID.chart.chartSettings ? finalData?.widgetUID.chart.chartSettings[series?.name] : '';
    const color = finalData?.widgetUID.chart.chartSettings?.type === "combination" ? colorKey : 'rgba(0, 100, 200, 0.3)';

    return {
      ...series,
      type: measureChartType,
      yAxis: seriesType === 'spline' ? 1 : 0,
      color: color
    };
  });

  const areaSplineChart = [...areaSplineData]

  const heatMapData = seriesData?.map((item) => ({
    name: item?.name,
    data: heatmapDatas,
    type: 'heatmap'
  }))
  // [
  //   {
  //     name: 'Heatmap',
  //     data: heatmapDatas,
  //     type: 'heatmap'
  //   },
  // ]

  const treeMapData = [
    {
      name: 'Treemap',
      data: treemapData,
      type: 'treemap'
    }
  ]

  const areaRangeData = seriesData?.length > 0 && seriesData?.map((family, index) => {
    const colorKey = finalData?.widgetUID.chart.chartSettings ? finalData?.widgetUID.chart.chartSettings[family?.name] : '';
    const color = finalData?.widgetUID.chart.chartSettings?.type === "arearange" ? colorKey : 'rgba(0, 100, 200, 0.3)';
    const xValues = family?.data.map((_, i) => i);
    const areaShadeColor = finalData?.widgetUID.chart.chartSettings?.areaShadeColor ? finalData?.widgetUID.chart.chartSettings?.areaShadeColor : 'rgba(255, 0, 0, 0.3)'

    const dataPoints = xValues?.map((x, i) => [x, family.data[i]]);
    if (index === 0) {
      return {
        name: `${family.name}`,
        type: 'arearange',
        color: color,
        data: seriesData[0]?.data.map((upperValue, i) => [
          xValues[i],
          seriesData[1]?.data[i] || 0,
          upperValue,
        ]),
        fillColor: areaShadeColor
      };
    } else {
      return {
        name: `${family?.name}`,
        type: 'spline',
        color: color,
        data: dataPoints,
      };
    }
  });

  const transformToBubbleData = (data, categories) => {
    return data.flatMap((entry, index) => {
      return categories.map((key, idx) => {
        const value = entry[key];
        return value !== null ? {
          x: idx,
          y: index,
          z: value,
          name: entry["Measure Name"]
        } : null;
      }).filter(item => item !== null);
    });
  };

  const bubbleData = transformToBubbleData(sortedData || [], categories);

  let yAxisMax;
  const getMaxYAxisValue = (data) => {
    const values = data.flatMap(series => series.data.map(point => {
      if (typeof point === 'string' && point.includes('%')) {
        return parseFloat(point.replace('%', ''));
      }
      return typeof point === 'number' ? point : 0;
    }));
    const maxValue = Math.max(...values);

    if (maxValue >= 1000000) {
      return Math.ceil(maxValue / 1000000) * 1000000;
    } else if (maxValue >= 1000) {
      return Math.ceil(maxValue / 1000) * 1000;
    } else {
      return Math.ceil(maxValue / 10) * 10;
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    yAxisMax = getMaxYAxisValue(seriesData)
  }, [])

  const formatYAxisLabel = (value) => {
    if (value >= 1000000) {
      return Math.round(value / 1000000) + 'M';
    }
    if (value >= 1000) {
      return Math.round(value / 1000) + 'K';
    }
    return value;
  };

  const pendingData = chartPending?.length > 0 ? chartPending?.filter((item) => item?.field !== "Measure Name" && item?.field !== "Scenario" && item?.field !== "Cycle ID") : []
  const findPeriodIndex = pendingData?.findIndex((item) => item?.field === timePeriodsFormatted)

  const categoriesData = [];
  const productData = state?.name1
  const customerData = state?.name2
  const monthData = categories
  for (let product of categories) {
    categoriesData.push(product);
    for (let month of productData) {
      categoriesData.push(month)
    }
  }
  // for (let product of productData) {
  //   categoriesData.push(product); // Add the BU (e.g., BU_02)
  //   for (let month of monthData) {
  //     categoriesData.push(month); // Add all months for the current BU
  //   }
  // }


  const totalCategories = productData?.length * monthData?.length;
  const periodIndex = findPeriodIndex !== -1 ? findPeriodIndex : 1;

  const labelGrid = [];
  if (customerData?.length > 0) {
    for (let k = 0; k < customerData?.length; k++) {
      for (let i = 0; i < productData?.length; i++) {
        for (let j = 0; j < monthData?.length; j++) {
          labelGrid.push({
            month: monthData[j],
            product: productData[i],
            customer: customerData[k],
          });
        }
      }
    }
  } else {
    for (let i = 0; i < productData?.length; i++) {
      for (let j = 0; j < monthData?.length; j++) {
        labelGrid.push({
          month: monthData[j],
          product: j === 0 ? productData[i] : "",
        });
      }
    }
  }

  const heatMapMonths = Array.from(labelGrid.map(item => item.month));
  const heatMapProducts = heatMapMonths?.map((month, i) => {
    const product = labelGrid?.find((item, index) => index === i)?.product || "";
    return product; // Default to "N/A" for missing products
  });

  const labelMap = {
    product: finalData?.widgetUID?.chart?.attributes[0]?.attributeName,
    customer: finalData?.widgetUID?.chart?.attributes[1]?.attributeName,
  };

  const attributeIndexes = {};
  pendingData?.length > 0 && pendingData?.forEach((item, index) => {
    attributeIndexes[item.headerName] = index;
  });


  const upperBound = chartType === "scatter" && seriesData?.length > 0 && (seriesData[0]?.data?.length > 0 ? seriesData?.[0]?.data : []);
  const lowerBound = chartType === "scatter" && seriesData?.length > 0 && (seriesData[1]?.data?.length > 0 ? seriesData?.[1]?.data : []);

  const scatterDataUpper = chartType === "scatter" && upperBound?.length > 0 && upperBound?.map((value, index) => [value, lowerBound[index]]);

  const scatterDataLower = chartType === "scatter" && lowerBound?.length > 0 && lowerBound?.map((value, index) => [value, upperBound[index]]);

  const Options = {
    chart: {
      // ...(chartType === "spider" && ({
      //   polar: true,
      // })),
      type: chartType === "combination"
        ? (finalData?.widgetUID.chart.chartSettings?.orientation === "vertical" ? "column" : "bar")
        : chartType === "bar"
          ? (finalData?.widgetUID.chart.chartSettings?.orientation === "vertical" ? "column" : "bar")
          : chartType === "arearange" ? "arearange" : chartType === "tornado" ? "bar" : chartType === "spider" ? "line" :
            (chartType || "line"),
      plotBorderWidth: 1,
      zoomType: 'xy',
      ...((chartType === "combination" || chartType === "bar") && {
        inverted: finalData?.widgetUID.chart.chartSettings?.orientation === "vertical" ? false : true,
      }
      )
    },
    title: {
      // text: null,
      align: 'center',
    },
    pane: {
      size: "80%",
    },
    xAxis: {
      ...(chartType !== "scatter" ? ({
        categories: Array(labelGrid.length).fill(''),
        labels: {
          formatter: function () {
            const pos = this.pos;

            const productLabels = [];
            const monthLabels = [];


            for (let i = 0; i < productData.length * monthData.length; i++) {
              const monthIdx = Math.floor(i / productData.length);
              const productIdx = i % productData.length;
              productLabels.push(productData[productIdx]);
              if (productIdx === 0) {
                monthLabels.push(monthData[monthIdx]);
              } else {
                monthLabels.push('');
              }
            }
            if (periodIndex === 1) {
              if (pos < labelGrid.length) {
                const label = labelGrid[pos];

                if ((findPeriodIndex === 1 || periodIndex === 1) && attributeIndexes[labelMap?.product] === 0) {
                  return `<div><b>${label?.customer ? label?.customer : ''}</b><br/><span style="text-align: center;">${label.month}</span><br/><b>${label.product}</b></div>`;
                } else {
                  return `<div><b>${label?.customer ? label?.customer : ''}</b><br/><span style="text-align: center;">${label.month}</span><br/><b>${label.product}</b></div>`;
                }
              }
            } else if (periodIndex === 2) {
              if (pos < labelGrid.length) {
                const label = labelGrid[pos];

                if ((findPeriodIndex === 2 || periodIndex === 2) && attributeIndexes[labelMap?.product] === 0) {
                  return `<div><b>${label.month}</b><br/><span style="text-align: center;">${label?.customer ? label?.customer : ''}</span><br/><b>${label.product}</b></div>`;
                } else if ((findPeriodIndex === 2 || periodIndex === 2) && attributeIndexes[labelMap?.product] === 1) {
                  return `<div><b>${label.month}</b><br/><span style="text-align: center;">${label.product}</span><br/><b>${label?.customer ? label?.customer : ''}</b></div>`;
                }
              }
            } else if (periodIndex === 0) {
              if (pos < labelGrid.length) {
                const label = labelGrid[pos];

                if (findPeriodIndex === 0 || periodIndex === 0) {
                  return `<div><b>${label.product}</b><br/><b>${label?.customer ? label?.customer : ''}</b><br/><span style="text-align: center;">${label.month}</span></div>`;
                }
              }
            }
            return null;
          },
          useHTML: true,
          step: 1,
          style: {
            whiteSpace: 'nowrap',
            textAlign: 'center',
            fontSize: "8px"
          }
        },
      })
        : chartType !== "spider" ? ({
          tickmarkPlacement: "on",
          lineWidth: 0,
        })
          : (
            {
              className: 'highcharts-color-0',
              labels: {
                formatter: function () {
                  return isPercentageData ? formatYAxisLabel(this.value) + '%' : formatYAxisLabel(this.value);
                },
                style: {
                  fontSize: '12px',
                  fontWeight: 'bold',
                  color: '#333'
                }
              },
              ...(chartType === "scatter" && ({
                title: {
                  text: seriesData[1]?.name
                }
              })),
              max: yAxisMax,
              plotBands: [{
                from: 0,
                to: yAxisMax / 2,
                color: 'rgba(68, 170, 213, 0.1)',
                label: {
                  style: {
                    color: '#606060'
                  }
                }
              }]
            }
          )),
      ...(chartType === "scatter" && ({
        title: {
          text: seriesData[0]?.name
        }
      })),
    },
    yAxis: [
      {
        className: 'highcharts-color-0',
        // ...(chartType === "tornado" ? ({
        //   labels: {
        //     formatter: function () {
        //       return Math.abs(this.value);
        //     },
        //   },
        // }) : ({
        labels: {
          formatter: function () {
            return isPercentageData ? formatYAxisLabel(this.value) + '%' : formatYAxisLabel(this.value);
          },
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
            color: '#333'
          }
        },
        // })),
        ...(chartType === "scatter" && ({
          title: {
            text: seriesData[1]?.name
          }
        })),
        // max: yAxisMax,
        plotBands: [{
          from: 0,
          to: yAxisMax / 2,
          color: 'rgba(68, 170, 213, 0.1)',
          label: {
            style: {
              color: '#606060'
            }
          }
        }]
      },
      {
        className: 'highcharts-color-1',
        title: { text: '' },
        opposite: true,
      },
    ],
    ...(chartType === "scatter" && ({
      legend: {
        enabled: false
      },
    })),
    ...(chartType === "spider" && ({
      legend: {
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
      },
    })),
    series: chartType === "bubble" ? [{
      data: bubbleData || [],
      colorByPoint: true
    }] : chartType === "combination" ? CombineDataChart || [] : chartType === "heatmap" ?
      [{
        name: 'Heatmap',
        data: heatmapDatas || [],
        type: 'heatmap'
      }]
      || [] : chartType === "treemap" ? treeMapData || [] : chartType === "areaspline" ? areaSplineChart || [] : chartType === "arearange" ?
        [
          ...areaRangeData || []
        ]
        : chartType === "scatter" ?
          [
            {
              name: seriesData?.[0]?.name,
              data: scatterDataUpper,
              color: finalData?.widgetUID.chart.chartSettings[seriesData?.[0]?.name]
            },
            {
              name: seriesData?.[1]?.name,
              data: scatterDataLower,
              color: finalData?.widgetUID.chart.chartSettings[seriesData?.[1]?.name]
            }
          ]
          || [] : chartType === "spline" ? splineData :
            chartType === "bar" ? barChartData :
              chartType === "area" ? areaChartData : chartType === "spider" ? spiderData :
                seriesData || [],
    tooltip: chartType === "bubble" ? {
      useHTML: true,
      headerFormat: '<table>',
      pointFormat: '<tr><td>{point.name}</td></tr>' +
        '<tr><th>X:</th><td>{point.x}</td></tr>' +
        '<tr><th>Y:</th><td>{point.y}</td></tr>' +
        '<tr><th>Z:</th><td>{point.z}</td></tr>',
      footerFormat: '</table>',
      followPointer: true,
      style: {
        padding: 0 // Set padding to 0 to remove extra space
      },
    } :
      // chartType === 'heatmap' ? {
      //   formatter: function () {
      //     return `Value: ${this.point.value}`;
      //   },
      // } : 
      chartType === 'treemap' ? {

        formatter: function () {
          const formattedValue = Highcharts.numberFormat(this.point.value, 0, '', '').replace(/\s/g, '');
          return `<b>${this.point.name}</b>: ${formattedValue}`;
        },
        style: {
          padding: 0 // Set padding to 0 to remove extra space
        },
      } : chartType === "scatter" ? {
        formatter: function () {
          // Format the numbers and remove any spaces
          const formattedX = Highcharts?.numberFormat(this.point.x, 2).replace(/\s/g, '');
          const formattedY = Highcharts?.numberFormat(this.point.y, 2).replace(/\s/g, '');

          return `
          <b>${this.series.name}</b><br>
          ${seriesData?.[0]?.name ? seriesData[0].name : ""}: ${formattedX}${isPercentageData ? '%' : ""}<br>
          ${seriesData?.[1]?.name ? seriesData[1].name : ""}: ${formattedY}${isPercentageData ? '%' : ""}
        `;
        },
        style: {
          padding: 0
        },
      } : {
        formatter: function () {
          // Format the number and remove spaces
          const formattedValue = Highcharts.numberFormat(this.y, 0, '', '').replace(/\s/g, '');

          return `<b>${this.series.name}</b>: ${formattedValue}`;
        },
        style: {
          padding: 0
        },
      },
    colorAxis: chartType === "heatmap" ? {
      // min: -100,
      // minColor: '#FFFFFF',
      // maxColor: Highcharts.getOptions().colors[0]
      // min: 0,
      minColor: "#FFFFFF",
      maxColor: "#0000FF",
    } : chartType === "treemap" ? {
      minColor: '#FFFFFF',
      maxColor: Highcharts.getOptions().colors[0]
    } : undefined,
    plotOptions: {
      column: { borderRadius: '25%' },
      line: { step: 'center' },
      pie: {
        innerSize: '50%',
        dataLabels: { format: '{point.name}: {point.y:.1f}%' },
      },
      areaspline: {
        fillOpacity: 0.5
      },
      heatmap: chartType === "heatmap" ? {
        borderWidth: 1,
        borderColor: '#dcdcdc',
        dataLabels: {
          enabled: true,
          color: '#000000',
          style: {
            fontSize: '10px',
            fontWeight: 'bold'
          }
        }
      } : undefined,
    },
    credits: {
      enabled: false
    },
  };

  const chartHeatOptions = {
    chart: {
      type: 'heatmap'
    },
    title: {
      text: 'Heatmap Example'
    },
    xAxis: {
      categories: heatMapMonths,
      labels: {
        formatter: function () {
          const index = this.pos;
          return `<span>${heatMapMonths[index]}<br>${heatMapProducts[index]}</span>`;
        },
        useHTML: true,
        align: "center"
      },
    },
    yAxis: {
      title: null
    },
    colorAxis: {
      min: 0,
      stops: [
        [0, '#3060cf'],
        [0.5, '#fffbbc'],
        [1, '#c4463a']
      ]
    },
    series: [{
      name: 'Heatmap Data',
      borderWidth: 1,
      data: heatMapData, // Ensure this is in [x, y, value] format
      dataLabels: {
        enabled: true,
        color: '#000000'
      }
    }],
    tooltip: {
      formatter: function () {
        if (this.point) {
          return `
            <b>Category:</b> ${heatMapMonths[this.point.x]}<br>
            <b>Product:</b> ${heatMapProducts[this.point.x]}<br>
            <b>Value:</b> ${this.point.value}
          `;
        }
        return '';
      }
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false // Remove this for heatmaps
        }
      }
    }
  };

  const options = chartType === "heatmap" ? chartHeatOptions : Options
  console.log("🚀 ~ options:", options)


  return (
    <>
      {loader ? (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Loader />
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </Box>
      )}
    </>
  );
};

export default ChartMain;