import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip } from '@mui/material';
import React, { useContext, useState } from 'react'
import MiniDrawer from '../common/drawer/DrawerToggle';
import DeleteProcess from './DeleteProcess';
import ProcessMaterialList from './ProcessMaterialList';
import CreateProcess from './CreateProcess';
import TerminateProcess from './TerminateProcess';
import RunProcess from './RunProcess';
import ProgressStatus from './ProgressStatus';
import { ThemeContext } from '../../ThemeContext';
import { createProcess, getProcessList } from '../../store/ApiSlice/processSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const ProcessList = () => {
    const { isDarkTheme } = useContext(ThemeContext);
    const [isCreate, setIsCreate] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isView, setIsView] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [updatedId, setUpdatedId] = useState(null)
    const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
    const [isModalDeleteOpenTerminate, setModalDeleteOpenTerminate] = useState(false);
    const [isExcuteProcess, setExcuteProcess] = useState()
    const [isRunProcess, setIsRunProcess] = useState(false)
    const [isProgressStatus, setIsProgressStatus] = useState(false);
    const [copyProcessModel, setCopyProcessModel] = useState(false)
    const [copyPorcessData, setCopyProcessData] = useState({})
    const dispatch = useDispatch()

    const handleCreateUpdate = (edit, id) => {
        setUpdatedId(id);
        setIsCreate(true)
        setIsEdit(edit);
    }

    const handleTableRowSelect = (event, row) => {
        let id = event.target.value;
        let updatedIds = selectedRows.includes(row?.original?._id)
            ? selectedRows.filter((n) => n !== row?.original?._id)
            : [...selectedRows, row?.original?._id];
        setSelectedRows(updatedIds);
    };

    const handleSubmit = async () => {
        const payload = {
            name: copyPorcessData?.name,
            notes: copyPorcessData?.processNotes || "",
            enterpriseUID: copyPorcessData?.enterpriseUID._id,
            steps: copyPorcessData?.steps?.length > 0 ? copyPorcessData?.steps?.map((item, index) => ({
                stepType: item?.stepType,
                stepName: item?.stepName,
                stepOrder: item?.stepOrder,
                operatorType: item?.operatorType,
                ...(item?.profile && ({
                    profile: item?.profile?._id ? item?.profile?._id : "",
                })),
                modelUID: item?.modelUID?._id,
                ...(item?.filter && ({
                    filter: item?.filter?._id ? item?.filter?._id : "",
                })),
                ...(item?.measureProfile && item?.operatorType === "Measure Data" && ({
                    measureProfile: item?.measureProfile || "",
                })),
                scenario: item?.scenario,
            })) : []
        }
        const result = await dispatch(createProcess({ payload, isEdit: false, selectedRows }))
        if (result?.meta?.requestStatus == 'fulfilled') {
            // toast.success(isEdit ? "Process updated successfully" : "Process created successfully")
            dispatch(getProcessList())
            setCopyProcessData({});
            setCopyProcessModel(false);
        } else if (result?.meta?.requestStatus === 'rejected') {
            toast.error(result?.error?.message)
            setCopyProcessModel(true)
        }
    }
    return (
        <>
            {!isCreate && !isView && (
                <Box sx={{ display: "flex" }}>
                    <MiniDrawer />
                    <Box component="main" sx={{
                        flexGrow: 1, p: 3, overflow: "auto", height: "100vh",
                        background: isDarkTheme ? "whitesmoke" : "white",
                    }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "60px",
                            paddingBottom: "20px",
                            position: "relative"
                        }}>
                            <h3 className="heading">Process</h3>
                            <div>
                                <Tooltip title="Add Process">
                                    <Button
                                        onClick={() => {
                                            handleCreateUpdate(false)
                                        }}
                                        className="awesome-button"
                                        sx={{ color: "white", margin: "0 4px", fontSize: "1.875rem !important" }}
                                    >
                                        +
                                    </Button>
                                </Tooltip>
                                <DeleteProcess
                                    selectedRows={selectedRows}
                                    setSelectedRows={setSelectedRows}
                                    setModalDeleteOpen={setModalDeleteOpen}
                                    isModalDeleteOpen={isModalDeleteOpen}
                                    updatedId={updatedId}
                                />
                                <TerminateProcess
                                    selectedRows={selectedRows}
                                    setSelectedRows={setSelectedRows}
                                    setModalDeleteOpenTerminate={setModalDeleteOpenTerminate}
                                    isModalDeleteOpenTerminate={isModalDeleteOpenTerminate}
                                    updatedId={updatedId}
                                />
                                <RunProcess
                                    selectedRows={selectedRows}
                                    setSelectedRows={setSelectedRows}
                                    setIsRunProcess={setIsRunProcess}
                                    isRunProcess={isRunProcess}
                                    updatedId={updatedId}
                                    isExcuteProcess={isExcuteProcess}
                                />
                                <ProgressStatus
                                    setIsProgressStatus={setIsProgressStatus}
                                    isProgressStatus={isProgressStatus}
                                />
                            </div>
                        </div>
                        <ProcessMaterialList
                            handleTableRowSelect={handleTableRowSelect}
                            setUpdatedId={setUpdatedId}
                            handleCreateUpdate={handleCreateUpdate}
                            setModalDeleteOpen={setModalDeleteOpen}
                            setModalDeleteOpenTerminate={setModalDeleteOpenTerminate}
                            setExcuteProcess={setExcuteProcess}
                            setIsRunProcess={setIsRunProcess}
                            setIsProgressStatus={setIsProgressStatus}
                            setCopyProcessModel={setCopyProcessModel}
                            setCopyProcessData={setCopyProcessData}
                        />
                    </Box>
                </Box>
            )}
            {(isCreate || isView) && (
                <CreateProcess
                    isCreate={isCreate}
                    setIsCreate={setIsCreate}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    isView={isView}
                    setIsView={setIsView}
                    selectedRows={updatedId}
                />
            )}

            {copyProcessModel && (
                <Dialog open={copyProcessModel} onClose={() => setCopyProcessModel(false)}>
                    <DialogTitle>Copy Process</DialogTitle>
                    <DialogContent style={{ width: "500px", margin: "2px" }}>
                        <TextField
                            id="name"
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Process Name"
                            value={copyPorcessData?.name}
                            onChange={(e) => {
                                setCopyProcessData((prev) => ({ ...prev, name: e.target.value }))
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder="Enter process name"
                            style={{ marginTop: "10px" }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setCopyProcessModel(false)}>Cancel</Button>
                        <Button color="primary" onClick={handleSubmit}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}

export default ProcessList
