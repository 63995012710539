import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import '../page/pageLayout.css';
import { useDispatch } from 'react-redux';
import Loader from '../loader/Loader';
import { getMeasureListWithEnterprise } from '../../store/ApiSlice/measureSlice';
import { AgGridReact } from "@ag-grid-community/react";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { getPrimaryTimeDimension, getSchemaData, updateViewAggregated } from '../../store/ApiSlice/canvasSlice';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';
ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ClipboardModule,
    RangeSelectionModule,
]);

const MyGridComponent = ({
    loader,
    setLoader,
    finalData,
    aggregatedViewData,
    gridRef,
    processPageData,
    canvasFindData
}) => {
    const { dimensionFilteredData, filterDetail, timeDimensionData, schemaData, layout } = useSelector((state) => state.canvas)
    const { measureData, measureList } = useSelector((state) => state.measure)
    const pendingLayout = JSON.parse(localStorage.getItem("pending"));
    const findGroupName = pendingLayout?.[finalData?.widgetUID?.widgetName] || [];
    const droppingLayout = JSON.parse(localStorage.getItem("dropped"));
    const findDroppedGroupName = droppingLayout?.[finalData?.widgetUID?.widgetName] || [];
    const viewData = finalData?.widgetUID?.widgetSubType === "Measure Data" ? aggregatedViewData : dimensionFilteredData?.data
    const dispatch = useDispatch();
    const [rowData, setRowData] = useState();
    const [newRowData, setNewRowData] = useState([])
    const attributeNames = finalData?.widgetUID?.worksheet?.attributes?.length > 0
        ? finalData.widgetUID.worksheet.attributes.map(item => item.attributeName)
        : [];

    const periodValues =
        finalData?.widgetUID?.worksheet?.timePeriods?.periodValues?.length > 0
            ? finalData.widgetUID.worksheet.timePeriods.periodValues
            : [];

    const mergeMeasureData = [...attributeNames, ...periodValues];

    const formatTimePeriods = (timePeriods) => {
        return finalData?.widgetUID?.widgetSubType === "Measure Data" && `Time Periods (${timePeriods})`;
    };
    const timePeriodsFormatted = formatTimePeriods(finalData?.widgetUID?.worksheet?.timePeriods?.periodType);
    const formValues = {
        enterpriseUID: canvasFindData?.enterpriseUID?._id,
        modelUID: canvasFindData?.modelUID?._id
    }

    useEffect(() => {
        dispatch(getMeasureListWithEnterprise({ formValues }))
        dispatch(getPrimaryTimeDimension({ canvasFindData }))
        dispatch(getSchemaData({ timeDimensionData }))
    }, [rowData]);

    useEffect(() => {
        const dataWidget = findGroupName?.length > 0 && findGroupName.filter(item => item.field === timePeriodsFormatted);
        const dataMeasureWidget = findDroppedGroupName?.length > 0 && findDroppedGroupName.filter(item => item.field === "Measure Name");

        const dataWithIds = (viewData?.length > 0 ? viewData : []).map(item => ({ ...item }));

        const reorderData = [
            ...(finalData?.widgetUID?.cycleID?.length > 0 ? ["Cycle ID"] : []),
            ...attributeNames,
            ...(finalData?.widgetUID?.widgetSubType === 'Measure Data' ? ["Measure Name"] : []),
            "Scenario",
            ...periodValues
        ];

        function reorderObjectKeys(obj, order) {
            const sorted = {};
            order.forEach(key => {
                if (obj.hasOwnProperty(key)) {
                    sorted[key] = obj[key];
                }
            });
            Object.keys(obj).forEach(key => {
                if (!sorted.hasOwnProperty(key)) {
                    sorted[key] = obj[key];
                }
            });
            return sorted;
        }

        const data = dataWithIds.map(item => reorderObjectKeys(item, reorderData));

        const columnData = dataWidget?.length > 0 && data?.reduce((acc, item) => {

            if (item["Measure Name"] && dataMeasureWidget?.length > 0) {
                const scenario = item["Measure Name"];
                const dynamicAttributes = attributeNames?.reduce((accum, attr) => {
                    accum[attr] = item[attr];
                    return accum;
                }, {});

                for (const key in item) {
                    if (key !== "Scenario" && key !== "Measure Name" && !attributeNames?.includes(key)) {
                        const newObj = {
                            ...dynamicAttributes, // Spread the dynamic attributes here
                            "Scenario": "Baseline",
                            "Time Period": key,
                            [scenario]: item[key]
                        };

                        // Check if the key already exists in the accumulator
                        const existingIndex = acc.findIndex(obj => obj["Time Period"] === key);
                        if (existingIndex > -1) {
                            acc[existingIndex][scenario] = item[key]; // Update existing entry
                        } else {
                            acc.push(newObj); // Add new entry
                        }
                    }
                }
            }
            // if (dataWidget?.length > 0) {
            //     const itemKeys = Object.keys(item);

            //     // Ensure that timePeriods is an array before proceeding
            //     const timePeriods = finalData?.widgetUID?.worksheet?.timePeriods?.periodValues || [];

            //     // Ensure that filteredKeys only contains keys that are not in timePeriods
            //     const filteredKeys = itemKeys.filter(key => !timePeriods.includes(key));

            //     if (Array.isArray(timePeriods) && timePeriods.length > 0) {
            //         // Iterate over each time period
            //         timePeriods.forEach(row => {
            //             // Build newObj using filtered keys and their corresponding values
            //             const newObj = {
            //                 ...filteredKeys.reduce((acc, key) => {
            //                     if (item[key] !== undefined) { // Ensure item[key] exists
            //                         acc[key] = item[key];
            //                     }
            //                     return acc;
            //                 }, {}),
            //                 "Time Period": row
            //             };

            //             // Push the newObj into acc (accumulator)
            //             acc.push(newObj);
            //         });
            //     }
            // }

            return acc;
        }, []);

        let tableData = dataWidget?.length > 0 ? columnData?.map((item, index) => ({ id: index, ...item })) : data?.map((item, index) => ({ id: index, ...item }));

        if (JSON.stringify(tableData) !== JSON.stringify(rowData)) {
            setRowData(tableData);
        }

    }, [viewData, aggregatedViewData, dimensionFilteredData?.data]);

    const uniqueFields =
        rowData?.length > 0 &&
        rowData?.reduce((fields, obj) => {
            Object.keys(obj)?.forEach((key) => {
                if (!fields?.includes(key)) {
                    fields?.push(key);
                }
            });
            return fields;
        }, []);


    const measureNameColumn = {
        headerName: 'Measure Name',
        field: 'Measure Name',
    };

    const combinedArray = [
        ...(finalData?.widgetUID?.widgetSubType === 'Measure Data'
            ? [measureNameColumn]
            : []),
        {
            headerName: 'Scenario',
            field: 'Scenario',
        },
        ...(Array.isArray(mergeMeasureData) && mergeMeasureData.length > 0
            ? mergeMeasureData.map((item) => ({
                headerName: item,
                field: item,
            }))
            : []),
    ];

    const otherColumns = uniqueFields?.length > 0 &&
        uniqueFields?.filter((key) => key !== 'Aggregation Mode')
            ?.filter((key) => key !== '_id')?.map((key) => ({
                headerName: key,
                field: key,
            }));


    const columnDefs = [];

    if (otherColumns?.length > 0) {

        columnDefs.push(otherColumns[0]);

        columnDefs.push(...otherColumns.slice(1));
    }

    const mergeArrays = (findGroupName, findDroppedGroupName) => {
        const droppedFields = findGroupName?.length > 0 ? findGroupName.map(col => col.field) : [];
        const droppedPeriodFields = findGroupName?.length > 0 ? findGroupName.filter(col => col.field === timePeriodsFormatted).map(col => col.field) : [];
        let newColumnDefs = (columnDefs?.length > 0 ? columnDefs : combinedArray)?.filter(col => !droppedFields.includes(col.field));

        const droppedMeasureFields = findDroppedGroupName?.length > 0 ? findDroppedGroupName.map(col => col.field) : [];
        const droppedMeasurePeriodFields = findDroppedGroupName?.length > 0 ? findDroppedGroupName.filter(col => col.field == measureNameColumn?.headerName).map(col => col.field) : [];
        let newMeasureColumnDefs = (columnDefs?.length > 0 ? columnDefs : combinedArray)?.filter(col => !droppedMeasureFields?.includes(col.field));

        if (finalData?.widgetUID?.widgetSubType === "Measure Data" && (droppedPeriodFields?.length > 0 || droppedMeasurePeriodFields?.length > 0)) {
            let data = []
            let newMergePeriodData = []
            let newMergeMeasureData = []
            if (findGroupName?.length > 0 && droppedPeriodFields?.length > 0) {
                let newData = newColumnDefs?.filter((item) => item?.headerName !== "Time Period")
                data = findGroupName.map((item) => ({ ...item }));
                let timePeriodIndex = data?.findIndex((item) => item.field == timePeriodsFormatted)
                if (timePeriodIndex !== -1) {
                    data[timePeriodIndex] = {
                        ...data[timePeriodIndex],
                        headerName: finalData?.widgetUID?.worksheet?.timePeriods?.periodType,
                        field: "Time Period",
                        minWidth: 60
                    };
                }
                newColumnDefs = newData
                newMergePeriodData = [...data, ...newColumnDefs]
            }
            if (findDroppedGroupName?.length > 0 && droppedMeasurePeriodFields?.length > 0) {
                let newData = (droppedPeriodFields?.length > 0 ? newMergePeriodData : newMeasureColumnDefs)?.filter((item) => item?.headerName !== "Measure Name")
                data = findDroppedGroupName?.filter((item) => item?.field !== "Measure Name")?.filter((item) => item?.field !== timePeriodsFormatted)?.map((item) => ({ ...item }));
                const measures = finalData?.widgetUID?.worksheet?.measures;

                if (measures && measures.length > 0) {
                    measures.forEach((measure, index) => {
                        data.push({
                            headerName: measure.measureName,
                            field: measure.measureName,
                            minWidth: 60
                        });
                    });
                }
                newMeasureColumnDefs = newData
                newMergeMeasureData = [...newMeasureColumnDefs, ...data]
            }
            return [...newMergeMeasureData, ...data];
        } else {
            return [...findGroupName, ...newColumnDefs]
        }
    }

    const mergedArray = mergeArrays(findGroupName, findDroppedGroupName);

    const mergedData = [...mergedArray];

    const uniqueArray =
        mergedData?.length > 0 &&
        mergedData?.filter(
            (item, index, self) =>
                index ===
                self.findIndex(
                    (t) => t.field === item.field && t.headerName === item.headerName
                )
        );

    const defaultColDef = {
        flex: 1,
        minWidth: 70,
        editable: true,
        resizable: true,
    };

    const apiRef = useRef({
        grid: undefined,
        column: undefined,
    });

    const onGridReady = (params) => {
        apiRef.current.grid = params.api;
        apiRef.current.column = params.columnApi;
    };

    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', color: "black" }), []);
    const selection = useMemo(() => {
        return { mode: "cell" };
    }, []);

    const onCellValueChanged = (params) => {
        const { data, newValue, oldValue, colDef, node } = params;
        if (newValue !== oldValue) {
            const updatedRowData = rowData?.map((row) => {
                if (row.id === data.id) {
                    return { ...row, [colDef.field]: newValue };
                }
                return row;
            });
            const updatedNewRowData = [...newRowData];
            const existingIndex = updatedNewRowData.findIndex(item => item.id === data.id);

            if (existingIndex > -1) {
                updatedNewRowData[existingIndex] = {
                    ...updatedNewRowData[existingIndex],
                    [colDef.field]: newValue,
                };
            } else {
                updatedNewRowData.push({
                    id: data.id,
                    [colDef.field]: newValue,
                });
            }

            setRowData(updatedRowData);
            setNewRowData(updatedNewRowData)
        }
    };

    const handleClick = async () => {
        setLoader(true);
        gridRef.current.api.stopEditing();
        const findFilterId = filterDetail?.find(filter => filter?._id === finalData?.widgetUID?.filter);
        const storeRowData = rowData?.map((item) => {
            const newItem = { ...item };
            periodValues.forEach((period) => {
                delete newItem[period];
            });
            return newItem;
        });

        const mergedRowData = newRowData?.map((storeItem) => {
            const newRowItem = storeRowData.find((item) => item.id === storeItem.id);

            if (newRowItem) {
                return {
                    ...storeItem,  // Keep original storeData item
                    ...newRowItem   // Overwrite or add new fields from newRowData
                };
            }

            return storeItem;
        });

        const documentArray = mergedRowData?.length > 0
            ? mergedRowData?.map(({ id, ...rest }) => rest)
            : [];

        const payload = {
            measures: finalData?.widgetUID?.worksheet?.measures,
            timeFields: {
                [finalData?.widgetUID?.worksheet?.timePeriods?.periodType]: finalData?.widgetUID?.worksheet?.timePeriods?.periodValues
            },
            timeFieldType: finalData?.widgetUID?.worksheet?.timePeriods?.periodType,
            aggregationFields: finalData?.widgetUID?.worksheet?.attributes?.map(
                (item) => item?.attributeName
            ),
            scenario: ['Baseline'],
            worksheetUID: finalData?.widgetUID?.worksheet?._id,
            ...(findFilterId?.attributes?.length > 0 && {
                additionalFilters: findFilterId?.attributes?.map((item) => ({
                    attribute: item?.attributeUID?.name,
                    operator: item?.operator,
                    values: item?.values,
                })),
            }),
            ...(finalData?.widgetUID?.cycleID?.length > 0 && {
                cycleID: finalData?.widgetUID?.cycleID,
            }),
            ...(finalData?.widgetUID?.lagID?.length > 0 && {
                lagID: finalData?.widgetUID?.lagID,
            }),
            documentArray: documentArray?.length > 0 ? documentArray : [],
        };

        const result = await dispatch(
            updateViewAggregated({ payload })
        );
        if (result?.meta?.requestStatus == 'fulfilled') {
            if (result?.payload.data.storedData?.length > 0) {
                const updatedRowData = result?.payload.data.storedData?.length > 0 ? rowData?.map(row => {
                    const update = newRowData?.find(newRow => newRow.id === row.id);
                    if (update) {
                        return { ...row, ...update };
                    }

                    return row;
                }) : rowData;
                setRowData(updatedRowData)
            } else {
                processPageData();
            }

            setLoader(false);
            // setEditViewData(false);
            setNewRowData([])
            toast.success('Updated successfully.');
        } else if (result?.meta?.requestStatus === 'rejected') {
            toast.error(result?.error?.message);
            setLoader(false);
        }
    };

    const isEditableBasedOnPeriod = (findTime, periodType) => {
        const currentDate = new Date();
        const periodStart = new Date(findTime?.["Period Start"]);

        switch (periodType) {
            case "Month":
                return (
                    periodStart.getFullYear() < currentDate.getFullYear() ||
                    (periodStart.getFullYear() === currentDate.getFullYear() &&
                        periodStart.getMonth() < currentDate.getMonth())
                );
            case "Day":
                return periodStart < currentDate;
            case "Year":
                return periodStart.getFullYear() < currentDate.getFullYear();
            case "Week":
            case "SYSWeek":
                const startOfWeek = new Date(currentDate);
                const dayOfWeek = currentDate.getDay();
                const diffToMonday = (dayOfWeek === 0 ? 6 : dayOfWeek - 1);
                startOfWeek.setDate(currentDate.getDate() - diffToMonday);

                return periodStart < startOfWeek;
            case "Quarter":
                const currentQuarterStartMonth = Math.floor(currentDate.getMonth() / 3) * 3;
                const startOfCurrentQuarter = new Date(currentDate.getFullYear(), currentQuarterStartMonth, 1);
                return periodStart < startOfCurrentQuarter;
            default:
                return false;
        }
    };


    const columnDefsss = (uniqueArray?.length > 0 ? uniqueArray : combinedArray)
        .filter((col) => col.field !== "id")
        .map((col) => ({
            ...col,
            editable: (params) => {
                const findMeasure = measureList?.find((item) => item?.measureName === params.data?.["Measure Name"]);
                const basePrimaryKey = findMeasure?.baselevelUID?.primaryKey?.map((item) => item?.name)
                const dataFields = ["Time Priod Name", "Scenario Name"];
                for (let key of finalData?.widgetUID?.worksheet?.attributes) {
                    dataFields.push(key.attributeName)
                }
                const periodType = finalData?.widgetUID?.worksheet?.timePeriods?.periodType;
                const baseLevelTimePriod = findMeasure?.baselevelUID?.["Period Type"]

                const findTime = schemaData?.find((item) => item["Time Period Name"] === params?.colDef.field);
                const isSameLevel = dataFields?.every(key => basePrimaryKey.includes(key)) && periodType === baseLevelTimePriod

                if (findMeasure?.measureName === params.data?.["Measure Name"] && !["SUM", "AVERAGE"].includes(findMeasure?.aggregationMode) && !isSameLevel) {
                    toast.error(`${params.data?.["Measure Name"]} is not editable because aggregation mode is not Sum or Average.`);
                    return false;
                }

                // if (params.data?.["Measure Name"] === "Sales History") {
                //     toast.error("Sales History is not editable.");
                //     return false;
                // }

                if (findMeasure?.measureName === params.data?.["Measure Name"]) {
                    if (findMeasure?.editable === "All Editable") {
                        return true;
                    }

                    if (findMeasure?.aggregationMode === "COUNT") {
                        if (!isSameLevel && findMeasure?.editable === "All Editable") {
                            return true;
                        } else if (findMeasure?.editable === "Editable in the Past" && !isSameLevel) {
                            if (isEditableBasedOnPeriod(findTime, periodType)) {
                                return true;
                            } else {
                                toast.error(`${params.data?.["Measure Name"]} is not editable for current or future time periods.`);
                                return false;
                            }
                        } else if (findMeasure?.editable === "Editable in the Current or Future Period" && !isSameLevel) {
                            if (!isEditableBasedOnPeriod(findTime, periodType)) {
                                return true;
                            } else {
                                toast.error(`${params.data?.["Measure Name"]} is not editable for past periods.`);
                                return false;
                            }
                        }
                        return false;
                    }

                    if (findMeasure?.editable === "Not Editable" || findMeasure?.editable === "System Editable") {
                        toast.error(`${params.data?.["Measure Name"]} is not editable.`);
                        return false;
                    }

                    if (findMeasure?.editable === "Editable in the Past") {
                        if (isEditableBasedOnPeriod(findTime, periodType)) {
                            return true;
                        } else {
                            toast.error(`${params.data?.["Measure Name"]} is not editable for current or future time periods.`);
                            return false;
                        }
                    }

                    if (findMeasure?.editable === "Editable in the Current or Future Period") {
                        if (!isEditableBasedOnPeriod(findTime, periodType)) {
                            return true;
                        } else {
                            toast.error(`${params.data?.["Measure Name"]} is not editable for past periods.`);
                            return false;
                        }
                    }
                }

                return true;
            }
        }));

    function transformColumns(data, columnDefsss) {
        const hasLagID = data?.some(item => item.headerName === "Lag ID");
        const hasTimePeriods = data?.some(item => item.headerName === "Time Periods (Month)");

        let filteredColumns = [];
        let newColumns = [];

        if (hasLagID && hasTimePeriods) {

            filteredColumns = columnDefsss?.filter(col =>
                col.field !== "Lag ID" && !periodValues?.includes(col?.field)
            );
            const findFirstRecord = data?.length > 0 && data?.[0]
            if (hasLagID && findFirstRecord?.headerName === "Lag ID") {
                finalData?.widgetUID?.lagID?.forEach((row) => {
                    const timePeriodsFormattedData = periodValues?.map((item) => ({
                        field: item
                    }))

                    newColumns.push({
                        headerName: `Lag ID ${row}`,
                        children: timePeriodsFormattedData
                    });
                })
            } else if (hasTimePeriods && findFirstRecord?.headerName === timePeriodsFormatted) {
                finalData?.widgetUID?.worksheet?.timePeriods?.periodValues?.forEach((row) => {
                    const timePeriodsFormattedData = finalData?.widgetUID.lagID?.map((item) => ({
                        field: row,
                        headerName: `Lag ID ${item}`,
                        "Lag ID": item
                    }))

                    newColumns.push({
                        headerName: row,
                        children: timePeriodsFormattedData
                    });
                })
            }
            return [...filteredColumns, ...newColumns];
        } else {
            return columnDefsss
        }
    }

    const transformedColumns = transformColumns(findDroppedGroupName, columnDefsss);

    return (
        <>
            <div style={containerStyle}>
                <div
                    className={'ag-theme-quartz'}
                    style={{ height: '400px', borderCollapse: 'collapse', zIndex: '9999999999999', fontSize: "12px" }}
                >
                    <div style={{ display: "flex", justifyContent: "end", alignItems: "center", paddingBottom: "15px" }}>
                        <Button variant='contained' onClick={handleClick}>Save</Button>
                    </div>
                    {loader ? (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Loader />
                        </div>
                    ) : (
                        <AgGridReact
                            ref={gridRef}
                            rowData={rowData || []}
                            columnDefs={transformedColumns}
                            onGridReady={onGridReady}
                            defaultColDef={defaultColDef}
                            selection={selection}
                            copyHeadersToClipboard={true}
                            onCellValueChanged={onCellValueChanged}
                            rowSelection="multiple"
                            stopEditingWhenCellsLoseFocus={true}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default MyGridComponent;
