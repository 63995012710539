import {
    AppBar,
    Box,
    Button,
    Tab,
    Toolbar,
    Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API_BASE_URL } from "../../helper/constants";
import { useDispatch } from "react-redux";
import {
    createProfile,
    getProfileData,
    getProfileMeasure,
    getProfilePrimaryTimeDimension,
} from "../../store/ApiSlice/profileSlice";
import { toast } from "react-toastify";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import OverviewTab from "./OverviewTab";
import ExtrapolativeTab from "./ExtrapolativeTab";
import PredictiveTab from "./PredictiveTab";
import PrescriptiveTab from "./PrescriptiveTab";
import AdministrativeTab from "./AdministrativeTab";
import AiEnsembleTab from "./AiEnsembleTab";

const CreateProfile = ({
    setIsCreate,
    isEdit,
    isView,
    selectedRows,
}) => {
    const { profileData, profileMeasure, timeData } = useSelector(
        (state) => state.profile
    );
    const [value, setValue] = useState('1');
    const { enterpriseData } = useSelector((state) => state?.enterprise);
    const [formValues, setFormValues] = useState({
        initialSeedType: "Random",
        initialSeedTypeOverview: "Random",
        hierarchicalForecastingAttributes: [],
        timeBucketAggregationPeriods: [],
        probabilisticMetrics: [],
        probabilisticMetricsOverview: [],
        noOfIterations: 1000,
        noOfIterationsOverview: 1000,
        noOfBins: 200,
        noOfBinsOverview: 200,
    });
    const [modalName, setModalName] = useState([]);
    const [forcastData, setForcastData] = useState([
        {
            humanforecasts: "",
        },
    ]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorMeasureEl, setAnchorMeasureEl] = useState(null);
    const [anchorOptimizationMeasureEl, setAnchorOptimizationMeasureEl] = useState(null);
    const [anchorBasicKPIl, setAnchorBasicKPIEl] = useState(null);
    const [anchorPredictiveBasicKPIEl, setAnchorPredictiveBasicEl] = useState(null);
    const [anchorPredictiveAdditionalKPIEl, setAnchorPredictiveAdditionalKPIEl] = useState(null);
    const [anchorConstraintsMeasureEl, setAnchorConstraintsMeasureEl] = useState(null);
    const [anchorAiEnsembleEl, setAnchorAiEnsembleEl] = useState(null);
    const [anchorCausalForecastingEl, setAnchorCausalForecastingEl] = useState(null);
    const [anchorCausalForecastModelsEl, setAnchorCausalForecastingModelsEl] = useState(null);
    const [dataIndex, setDataIndex] = useState();
    const [driverBasedMeasure, setDriverBasedMeasure] = useState(() => {
        const categoricalDriver = false; // Default value or retrieve from props/context
        const exploreSensitivity = false;
        const uncertaintyDistribution = null // Default value or retrieve from props/context

        return [{
            measure: "",
            categoricalDriver,
            driverType: "Continuous",
            importanceMeasure: "",
            contributionMeasure: "",
            offset: 0,
            uncertaintyDistribution: "",
            lowerBoundMeasure: "",
            upperBoundMeasure: "",
            stepSizeMeasure: "",
            ...((exploreSensitivity === false || uncertaintyDistribution == null) ? { baseValueType: "Mean" } : { baseValueType: "" })
        }];
    });
    const [optimizationMeasure, setOptimizationMeasure] = useState([])
    const [basicKPI, setBasicKPI] = useState([{
        StatisticalForecastModels: "",
    }])
    const [causalForecastModels, setCausalForecastModels] = useState([{
        causalForecastEngineForecastModels: "",
    }])
    const [causalOutcomesForecasting, setCausalOutcomesForecasting] = useState([{
        bestCausalForecastMeasureUID: "",
        causalForecastBaselineMeasureUID: "",
        uncertaintyDistribution: "",
        exploreSensitivity: "",
        probabilisticMetric: ""
        // probabilisticMetric: [],
    }])

    const [predictiveBasicKPI, setPredictiveBasicKPI] = useState([{
        predictiveKPI: "",
        bestPredictiveKPI: "",
        bestPredictiveforecastMovingAverageKPI: "",
        bestPredictiveforecastRelativeKPI: "",
        bestPredictiveforecastKPIRank: "",
        bestPredictiveforecastMirrorKPIRank: "",
        bestPredictiveforecastKPIFVA: "",
    }])

    const [aiEnsembleBasicKPI, setAiEnsembleBasicKPI] = useState([{
        aiEnsembleKPI: "",
        bestAiEnsembleKPI: "",
        bestAiEnsembleMovingAverageKPI: "",
        bestAiEnsembleRelativeKPI: "",
        bestAiEnsembleKPIRank: "",
        bestAiEnsembleMirrorKPIRank: "",
        bestAiEnsembleKPIFVA: "",
        weightParameterMeasure: ""
    }])

    const [predictiveAdditionalKPI, setPredictiveAdditionalKPI] = useState([])
    const [prescriptiveconstraintsMeasure, setPrescriptiveConstraintsMeasure] = useState([{
        constraintsMeasure: "",
    }])
    const [prescriptiveOptimal, setPrescriptiveOptimal] = useState([
        {
            prescriptiveOptimalForecastDecisions: ""
        }
    ])
    const [anchorPrescriptiveEnsembleEl, setAnchorPrescriptiveEnsembleEl] = useState(null);
    const deleteForCast = Boolean(anchorEl);
    const deleteDemandDriven = Boolean(anchorMeasureEl);
    const deleteOptimization = Boolean(anchorOptimizationMeasureEl);
    const deletebasicKPI = Boolean(anchorBasicKPIl);
    const deleteCausalForecasting = Boolean(anchorCausalForecastingEl);
    const deletePredictiveBasicKPI = Boolean(anchorPredictiveBasicKPIEl);
    const deletePredictiveAdditionalKPI = Boolean(anchorPredictiveAdditionalKPIEl);
    const deleteConstraintsMeasure = Boolean(anchorConstraintsMeasureEl);
    const deleteAiEnsemble = Boolean(anchorAiEnsembleEl);
    const deletePrescriptiveEnsemble = Boolean(anchorPrescriptiveEnsembleEl);
    const deleteCausalForecastModelsEnsemble = Boolean(anchorCausalForecastModelsEl);
    const dispatch = useDispatch();

    const selectedProfile = profileData?.find(
        (item) => item?._id === selectedRows
    );

    useEffect(() => {
        if (selectedProfile && isEdit) {
            setFormValues({
                aggregatedLevels: selectedProfile?.aggregatedLevels,
                ProbabilisticStatisticalForecastLevels: selectedProfile?.ProbabilisticStatisticalForecastLevels,
                probabilisticCausalForecastLevels: selectedProfile?.probabilisticCausalForecastLevels,
                name: selectedProfile?.name,
                notes: selectedProfile?.notes,
                enterpriseName: selectedProfile?.enterpriseUID?.enterpriseName,
                enterpriseUID: selectedProfile?.enterpriseUID?._id,
                modelName: selectedProfile?.modelUID?.modelName,
                modelUID: selectedProfile?.modelUID?.modalUID,
                statisticalForecastingEngine: selectedProfile?.statisticalForecastingEngine,
                hierarchicyAggregationEngine: selectedProfile?.hierarchicyAggregationEngine,
                timeAggregationEngine: selectedProfile?.timeAggregationEngine,
                causalForecastingEngine: selectedProfile?.causalForecastingEngine,
                aiFusionEngine: selectedProfile?.aiFusionEngine,
                valueOptimizationEngine: selectedProfile?.valueOptimizationEngine,
                forecastabilityEngine: selectedProfile?.forecastabilityEngine,
                outlierCorrectionEngine: selectedProfile?.outlierCorrectionEngine,
                humanForecast: selectedProfile?.humanForecast,
                timePeriod: selectedProfile?.periodicity,
                frequency: selectedProfile?.frequency,
                historyHorizon: selectedProfile?.historyHorizon,
                validationHorizon: selectedProfile?.validationHorizon,
                naiveForecastCalculationHorizon: selectedProfile?.naiveForecastCalculationHorizon,
                defaultValue: selectedProfile?.defaultValue !== null && parseFloat(selectedProfile?.defaultValue?.replace('%', '')),
                movingAverageHorizon: selectedProfile?.movingAverageHorizon,
                historyBaseLevel: selectedProfile?.historyBaseLevel?._id,
                forecastBaseLevel: selectedProfile?.forecastBaseLevel?._id,
                naiveForecastMeasureUID: selectedProfile?.naiveForecastMeasureUID?._id,
                forecastHorizon: selectedProfile?.forecastHorizon,
                ...(selectedProfile?.hierarchyAggregationEngineAttributes?.length > 0 && ({
                    hierarchicalForecastingAttributes: selectedProfile?.hierarchyAggregationEngineAttributes?.map((item) => item?._id)
                })),
                ...(selectedProfile?.timeAggregationEnginePeriods?.length > 0 && ({
                    timeBucketAggregationPeriods: selectedProfile?.timeAggregationEnginePeriods
                })),
                baseHistoryMeasureUID: selectedProfile?.baseHistoryMeasureUID?._id,
                ...(selectedProfile?.outlierCorrectionEngine && ({
                    correctedHistoryMeasureUID:
                        selectedProfile?.correctedHistoryMeasureUID?._id,
                })),
                errorKPI: selectedProfile?.errorKPI,
                biasKPI: selectedProfile?.biasKPI,
                // basicKPIs
                isCheckedbasicKPIError: selectedProfile?.basicKPIs?.error?.isActive,
                isCheckedbasicKPIBias: selectedProfile?.basicKPIs?.bias?.isActive,
                isCheckedbasicKPIChurn: selectedProfile?.basicKPIs?.churn?.isActive,
                ...((selectedProfile?.statisticalForecastEngineBasicSettings?.probabilisticStatisticalForecastingEngine || selectedProfile?.causalForecastBasicSettings?.probabilisticForecstingEngine) && ({
                    initialSeedTypeOverview: selectedProfile?.probabilisticForecastSettings?.initialSeedType,
                    noOfIterationsOverview: selectedProfile?.probabilisticForecastSettings?.noOfIterations || 1000,
                    noOfBinsOverview: selectedProfile?.probabilisticForecastSettings?.noOfBins || 200,
                    probabilisticMetricsOverview: selectedProfile?.probabilisticForecastSettings?.probabilisticMetrics,
                    ...(selectedProfile?.probabilisticForecastSettings?.initialSeedType === "Fixed" && ({
                        initialSeedValueOverview: Number(selectedProfile?.probabilisticForecastSettings?.initialSeedValue)
                    }))
                })),
                ...(selectedProfile?.basicKPIs?.error?.isActive && ({
                    errorMethod: selectedProfile?.basicKPIs?.error?.errorMethod,
                    ...(selectedProfile?.basicKPIs?.error?.errorMethod === "wMAPE" && ({
                        weightErrorMeasureUID: selectedProfile?.basicKPIs?.error?.weightMeasureUID,
                    })),
                    defaultErrorValue: Number(selectedProfile?.basicKPIs?.error?.defaultValue),
                    movingAverageErrorHorizon: Number(selectedProfile?.basicKPIs?.error?.movingAverageHorizon),
                    naiveForecastErrorMeasureUID: selectedProfile?.basicKPIs?.error?.naiveForecastErrorMeasureUID,
                    naiveForecastMovingAverageErrorMeasureUID: selectedProfile?.basicKPIs.error.naiveForecastMovingAverageErrorMeasureUID,
                    ...(selectedProfile?.forecastabilityEngine && ({
                        ...(selectedProfile?.basicKPIs?.error?.upperBoundMeasureUID && ({
                            forecastabilityBandErrorUpperBoundMeasureUID: selectedProfile?.basicKPIs?.error?.upperBoundMeasureUID,
                        })),
                        ...(selectedProfile?.basicKPIs?.error?.lowerBoundMeasureUID && ({
                            forecastabilityBandErrorLowerBoundMeasureUID: selectedProfile?.basicKPIs?.error?.lowerBoundMeasureUID,
                        }))
                    })),
                })),
                ...(selectedProfile?.basicKPIs?.bias?.isActive && ({
                    biasMethod: selectedProfile?.basicKPIs?.bias?.biasMethod,
                    ...(selectedProfile?.basicKPIs?.bias?.biasMethod === "wMPE" && ({
                        weightBiasMeasureUID: selectedProfile?.basicKPIs?.bias?.weightMeasureUID,
                    })),
                    defaultBiasValue: Number(selectedProfile?.basicKPIs?.bias?.defaultValue),
                    movingAverageBiasHorizon: Number(selectedProfile?.basicKPIs?.bias?.movingAverageHorizon),
                    naiveForecastBiasMeasureUID: selectedProfile?.basicKPIs?.bias?.naiveForecastBiasMeasureUID,
                    naiveForecastMovingAverageBiasMeasureUID: selectedProfile?.basicKPIs?.bias?.naiveForecastMovingAverageBiasMeasureUID,
                    ...(selectedProfile?.forecastabilityEngine && ({
                        ...(selectedProfile?.basicKPIs?.bias?.upperBoundMeasureUID && ({
                            forecastabilityBandBiasUpperBoundMeasureUID: selectedProfile?.basicKPIs?.bias?.upperBoundMeasureUID,

                        })),
                        ...(selectedProfile?.basicKPIs?.bias?.lowerBoundMeasureUID && ({
                            forecastabilityBandBiasLowerBoundMeasureUID: selectedProfile?.basicKPIs?.bias?.lowerBoundMeasureUID,
                        }))
                    })),
                })),
                ...(selectedProfile?.basicKPIs?.churn?.isActive && ({
                    churnMethod: selectedProfile?.basicKPIs?.churn?.churnMethod,
                    ...(selectedProfile?.basicKPIs?.churn?.churnMethod === "wMAPC" && ({
                        weightChurnMeasureUID: selectedProfile?.basicKPIs?.churn?.weightMeasureUID,
                    })),
                    defaultChurnValue: Number(selectedProfile?.basicKPIs?.churn?.defaultValue),
                    movingAverageChurnHorizon: Number(selectedProfile?.basicKPIs?.churn?.movingAverageHorizon),
                    naiveForecastChurnMeasureUID: selectedProfile?.basicKPIs?.churn?.naiveForecastChurnMeasureUID,
                    naiveForecastMovingAverageChurnMeasureUID: selectedProfile?.basicKPIs?.churn?.naiveForecastMovingAverageChurnMeasureUID,
                    ...(selectedProfile?.forecastabilityEngine && ({
                        ...(selectedProfile?.basicKPIs?.churn?.upperBoundMeasureUID && ({
                            forecastabilityBandChurnUpperBoundMeasureUID: selectedProfile?.basicKPIs?.churn?.upperBoundMeasureUID,

                        })),
                        ...(selectedProfile?.basicKPIs?.churn?.lowerBoundMeasureUID && ({
                            forecastabilityBandChurnLowerBoundMeasureUID: selectedProfile?.basicKPIs?.churn?.lowerBoundMeasureUID,
                        }))
                    })),
                })),
                // statisticalForecasting
                ...(selectedProfile?.statisticalForecastingEngine && ({
                    ...(selectedProfile?.statisticalForecastEngineKPIs.error.isActive && ({
                        statisticalForecastErrorMeasureUID: selectedProfile?.statisticalForecastEngineKPIs?.error?.bestStatisticalForecastErrorMeasureUID,
                        statisticalForecastMovingAverageErrorMeasureUID: selectedProfile?.statisticalForecastEngineKPIs.error?.bestStatisticalForecastMovingAverageErrorMeasureUID,
                        statisticalForecastRelativeErrorMeasureUID: selectedProfile?.statisticalForecastEngineKPIs?.error?.bestStatisticalForecastRelativeErrorMeasureUID,
                        statisticalForecastErrorRankMeasureUID: selectedProfile?.statisticalForecastEngineKPIs?.error?.bestStatisticalForecastErrorRankMeasureUID,
                        statisticalForecastAccuracyRankMeasureUID: selectedProfile?.statisticalForecastEngineKPIs?.error?.bestStatisticalForecastAccuracyRankMeasureUID,
                        statisticalForecastErrorFVAMeasureUID: selectedProfile?.statisticalForecastEngineKPIs?.error?.bestStatisticalForecastErrorFVAMeasureUID

                    })),
                    ...(selectedProfile?.statisticalForecastEngineKPIs?.bias?.isActive && ({
                        statisticalForecastBiasMeasureUID: selectedProfile?.statisticalForecastEngineKPIs?.bias?.bestStatisticalForecastBiasMeasureUID,
                        statisticalForecastMovingAverageBiasMeasureUID: selectedProfile?.statisticalForecastEngineKPIs?.bias?.bestStatisticalForecastMovingAverageBiasMeasureUID,
                        statisticalForecastRelativeBiasMeasureUID: selectedProfile?.statisticalForecastEngineKPIs?.bias?.bestStatisticalForecastRelativeBiasMeasureUID,
                        statisticalForecastBiasRankMeasureUID: selectedProfile?.statisticalForecastEngineKPIs?.bias?.bestStatisticalForecastBiasRankMeasureUID,
                        statisticalForecastNeutralityRankMeasureUID: selectedProfile?.statisticalForecastEngineKPIs?.bias?.bestStatisticalForecastNeutralityRankMeasureUID,
                        statisticalForecastBiasFVAMeasureUID: selectedProfile?.statisticalForecastEngineKPIs?.bias?.bestStatisticalForecastBiasFVAMeasureUID
                    })),
                    ...(selectedProfile?.statisticalForecastEngineKPIs?.churn?.isActive && ({
                        statisticalForecastChurnMeasureUID: selectedProfile?.statisticalForecastEngineKPIs?.churn?.bestStatisticalForecastChurnMeasureUID,
                        statisticalForecastMovingAverageChurnMeasureUID: selectedProfile?.statisticalForecastEngineKPIs?.churn?.bestStatisticalForecastMovingAverageChurnMeasureUID,
                        statisticalForecastRelativeChurnMeasureUID: selectedProfile?.statisticalForecastEngineKPIs?.churn?.bestStatisticalForecastRelativeChurnMeasureUID,
                        statisticalForecastChurnRankMeasureUID: selectedProfile?.statisticalForecastEngineKPIs?.churn?.bestStatisticalForecastChurnRankMeasureUID,
                        statisticalForecastStabilityRankMeasureUID: selectedProfile?.statisticalForecastEngineKPIs?.churn?.bestStatisticalForecastStabilityRankMeasureUID,
                        statisticalForecastChurnFVAMeasureUID: selectedProfile?.statisticalForecastEngineKPIs?.churn?.bestStatisticalForecastChurnFVAMeasureUID
                    })),
                })),
                probabilisticStatisticalForecastingEngine: selectedProfile?.statisticalForecastEngineBasicSettings?.probabilisticStatisticalForecastingEngine,
                bestStatisticalForecastMeasureUID: selectedProfile?.statisticalForecastEngineBasicSettings?.bestStatisticalForecastMeasureUID,
                bestStatisticalForecastDiscrepancyRankMeasureUID: selectedProfile?.statisticalForecastEngineBasicSettings?.bestStatisticalForecastDiscrepancyRankMeasureUID,
                bestStatisticalForecastQualityRankMeasureUID: selectedProfile?.statisticalForecastEngineBasicSettings?.bestStatisticalForecastQualityRankMeasureUID,

                //driver based 
                ...(selectedProfile?.causalForecastBasicSettings && ({
                    causalForecastBaselineMeasureUID: selectedProfile?.causalForecastBasicSettings?.causalForecastBaselineMeasureUID,
                    causalForecastDiscrepancyRankMeasureUID: selectedProfile?.causalForecastBasicSettings?.causalForecastDiscrepancyRankMeasureUID,
                    causalForecastQualityRankMeasureUID: selectedProfile?.causalForecastBasicSettings?.causalForecastQualityRankMeasureUID,
                    probabilisticForecstingEngine: selectedProfile?.causalForecastBasicSettings?.probabilisticForecstingEngine,
                    sensitivityExplorerEngine: selectedProfile?.causalForecastBasicSettings?.sensitivityExplorerEngine,
                })),

                ...(selectedProfile?.causalForecastBasicKPIs && ({
                    ...(selectedProfile?.causalForecastBasicKPIs?.error?.isActive && ({
                        bestCausalForecastErrorMeasureUID: selectedProfile?.causalForecastBasicKPIs?.error?.bestCausalForecastErrorMeasureUID,
                        bestCausalForecastMovingAverageErrorMeasureUID: selectedProfile?.causalForecastBasicKPIs.error?.bestCausalForecastMovingAverageErrorMeasureUID,
                        bestCausalForecastRelativeErrorMeasureUID: selectedProfile?.causalForecastBasicKPIs?.error?.bestCausalForecastRelativeErrorMeasureUID,
                        bestCausalForecastErrorRankMeasureUID: selectedProfile?.causalForecastBasicKPIs?.error?.bestCausalForecastErrorRankMeasureUID,
                        bestCausalForecastAccuracyRankMeasureUID: selectedProfile?.causalForecastBasicKPIs?.error?.bestCausalForecastAccuracyRankMeasureUID,
                        bestCausalForecastErrorFVAMeasureUID: selectedProfile?.causalForecastBasicKPIs?.error?.bestCausalForecastErrorFVAMeasureUID

                    })),
                    ...(selectedProfile?.causalForecastBasicKPIs?.bias?.isActive && ({
                        bestCausalForecastBiasMeasureUID: selectedProfile?.causalForecastBasicKPIs?.bias?.bestCausalForecastBiasMeasureUID,
                        bestCausalForecastMovingAverageBiasMeasureUID: selectedProfile?.causalForecastBasicKPIs?.bias?.bestCausalForecastMovingAverageBiasMeasureUID,
                        bestCausalForecastRelativeBiasMeasureUID: selectedProfile?.causalForecastBasicKPIs?.bias?.bestCausalForecastRelativeBiasMeasureUID,
                        bestCausalForecastBiasRankMeasureUID: selectedProfile?.causalForecastBasicKPIs?.bias?.bestCausalForecastBiasRankMeasureUID,
                        bestCausalForecastNeutralityRankMeasureUID: selectedProfile?.causalForecastBasicKPIs?.bias?.bestCausalForecastNeutralityRankMeasureUID,
                        bestCausalForecastBiasFVAMeasureUID: selectedProfile?.causalForecastBasicKPIs?.bias?.bestCausalForecastBiasFVAMeasureUID
                    })),
                    ...(selectedProfile?.causalForecastBasicKPIs?.churn?.isActive && ({
                        bestCausalForecastChurnMeasureUID: selectedProfile?.causalForecastBasicKPIs?.churn?.bestCausalForecastChurnMeasureUID,
                        bestCausalForecastMovingAverageChurnMeasureUID: selectedProfile?.causalForecastBasicKPIs?.churn?.bestCausalForecastMovingAverageChurnMeasureUID,
                        bestCausalForecastRelativeChurnMeasureUID: selectedProfile?.causalForecastBasicKPIs?.churn?.bestCausalForecastRelativeChurnMeasureUID,
                        bestCausalForecastChurnRankMeasureUID: selectedProfile?.causalForecastBasicKPIs?.churn?.bestCausalForecastChurnRankMeasureUID,
                        bestCausalForecastStabilityRankMeasureUID: selectedProfile?.causalForecastBasicKPIs?.churn?.bestCausalForecastStabilityRankMeasureUID,
                        bestCausalForecastChurnFVAMeasureUID: selectedProfile?.causalForecastBasicKPIs?.churn?.bestCausalForecastChurnFVAMeasureUID
                    })),
                })),
                ...(selectedProfile?.statisticalForecastEngineBasicSettings?.probabilisticStatisticalForecastingEngine && ({
                    initialSeedType: selectedProfile?.statisticalForecastEngineProbabilisticSettings?.initialSeedType,
                    noOfIterations: selectedProfile?.statisticalForecastEngineProbabilisticSettings?.noOfIterations || 1000,
                    noOfBins: selectedProfile?.statisticalForecastEngineProbabilisticSettings?.noOfBins || 200,
                    probabilisticMetrics: selectedProfile?.statisticalForecastEngineProbabilisticSettings?.probabilisticMetrics,
                    ...(selectedProfile?.statisticalForecastEngineProbabilisticSettings?.initialSeedType === "Fixed" && ({
                        initialSeedValue: Number(selectedProfile?.statisticalForecastEngineProbabilisticSettings?.initialSeedValue)
                    }))
                })),
                //Prescriptive 
                ...(selectedProfile?.valueOptimizationEngine && ({
                    optimalForecastMeasureUID: selectedProfile?.valueOptimizationEngineBasicSettings?.optimalForecastMeasureUID,
                    optimalForecastOverallDiscrepancyRankMeasureUID: selectedProfile?.valueOptimizationEngineBasicSettings?.optimalForecastOverallDiscrepancyRankMeasureUID,
                    optimalForecastOverallQualityRankMeasureUID: selectedProfile?.valueOptimizationEngineBasicSettings?.optimalForecastOverallQualityRankMeasureUID,
                    maxRuntime: Number(selectedProfile?.valueOptimizationEngineBasicSettings?.maxRuntime),
                    explainResults: selectedProfile?.valueOptimizationEngineBasicSettings.explainResults,
                })),
                ...(selectedProfile?.valueOptimizationEngine && ({
                    ...(selectedProfile?.valueOptimizationEngineBasicKPIs.error.isActive && ({
                        optimalForecastErrorMeasureUID: selectedProfile?.valueOptimizationEngineBasicKPIs?.error?.optimalForecastErrorMeasureUID,
                        optimalForecastMovingAverageErrorMeasureUID: selectedProfile?.valueOptimizationEngineBasicKPIs.error?.optimalForecastMovingAverageErrorMeasureUID,
                        optimalForecastRelativeErrorMeasureUID: selectedProfile?.valueOptimizationEngineBasicKPIs?.error?.optimalForecastRelativeErrorMeasureUID,
                        optimalForecastErrorRankMeasureUID: selectedProfile?.valueOptimizationEngineBasicKPIs?.error?.optimalForecastErrorRankMeasureUID,
                        optimalForecastAccuracyRankMeasureUID: selectedProfile?.valueOptimizationEngineBasicKPIs?.error?.optimalForecastAccuracyRankMeasureUID,
                        optimalForecastErrorFVAMeasureUID: selectedProfile?.valueOptimizationEngineBasicKPIs?.error?.optimalForecastErrorFVAMeasureUID

                    })),
                    ...(selectedProfile?.valueOptimizationEngineBasicKPIs?.bias?.isActive && ({
                        optimalForecastBiasMeasureUID: selectedProfile?.valueOptimizationEngineBasicKPIs?.bias?.optimalForecastBiasMeasureUID,
                        optimalForecastMovingAverageBiasMeasureUID: selectedProfile?.valueOptimizationEngineBasicKPIs?.bias?.optimalForecastMovingAverageBiasMeasureUID,
                        optimalForecastRelativeBiasMeasureUID: selectedProfile?.valueOptimizationEngineBasicKPIs?.bias?.optimalForecastRelativeBiasMeasureUID,
                        optimalForecastBiasRankMeasureUID: selectedProfile?.valueOptimizationEngineBasicKPIs?.bias?.optimalForecastBiasRankMeasureUID,
                        optimalForecastNeutralityRankMeasureUID: selectedProfile?.valueOptimizationEngineBasicKPIs?.bias?.optimalForecastNeutralityRankMeasureUID,
                        optimalForecastBiasFVAMeasureUID: selectedProfile?.valueOptimizationEngineBasicKPIs?.bias?.optimalForecastBiasFVAMeasureUID
                    })),
                    ...(selectedProfile?.valueOptimizationEngineBasicKPIs?.churn?.isActive && ({
                        optimalForecastChurnMeasureUID: selectedProfile?.valueOptimizationEngineBasicKPIs?.churn?.optimalForecastChurnMeasureUID,
                        optimalForecastMovingAverageChurnMeasureUID: selectedProfile?.valueOptimizationEngineBasicKPIs?.churn?.optimalForecastMovingAverageChurnMeasureUID,
                        optimalForecastRelativeChurnMeasureUID: selectedProfile?.valueOptimizationEngineBasicKPIs?.churn?.optimalForecastRelativeChurnMeasureUID,
                        optimalForecastChurnRankMeasureUID: selectedProfile?.valueOptimizationEngineBasicKPIs?.churn?.optimalForecastChurnRankMeasureUID,
                        optimalForecastStabilityRankMeasureUID: selectedProfile?.valueOptimizationEngineBasicKPIs?.churn?.optimalForecastStabilityRankMeasureUID,
                        optimalForecastChurnFVAMeasureUID: selectedProfile?.valueOptimizationEngineBasicKPIs?.churn?.optimalForecastChurnFVAMeasureUID
                    })),
                })),

                //aiBlendForecasting
                ...(selectedProfile?.aiFusionEngine && ({
                    aiFusionForecastMeasureUID: selectedProfile?.aiFusionEngineBasicSettings?.aiFusionForecastMeasureUID,
                    aiFusionForecastOverallDiscrepancyRankMeasureUID: selectedProfile?.aiFusionEngineBasicSettings?.aiFusionForecastOverallDiscrepancyRankMeasureUID,
                    aiFusionForecastOverallQualityRankMeasureUID: selectedProfile?.aiFusionEngineBasicSettings?.aiFusionForecastOverallQualityRankMeasureUID,
                    humanForecast: selectedProfile?.aiFusionEngineBasicSettings?.humanForecast,
                })),
                ...(selectedProfile?.aiFusionEngine && ({
                    ...(selectedProfile?.aiFusionEngineBasicKPIs.error.isActive && ({
                        aiFusionForecastErrorMeasureUID: selectedProfile?.aiFusionEngineBasicKPIs?.error?.aiFusionForecastErrorMeasureUID,
                        aiFusionForecastMovingAverageErrorMeasureUID: selectedProfile?.aiFusionEngineBasicKPIs.error?.aiFusionForecastMovingAverageErrorMeasureUID,
                        aiFusionForecastRelativeErrorMeasureUID: selectedProfile?.aiFusionEngineBasicKPIs?.error?.aiFusionForecastRelativeErrorMeasureUID,
                        aiFusionForecastErrorRankMeasureUID: selectedProfile?.aiFusionEngineBasicKPIs?.error?.aiFusionForecastErrorRankMeasureUID,
                        aiFusionForecastAccuracyRankMeasureUID: selectedProfile?.aiFusionEngineBasicKPIs?.error?.aiFusionForecastAccuracyRankMeasureUID,
                        aiFusionForecastErrorFVAMeasureUID: selectedProfile?.aiFusionEngineBasicKPIs?.error?.aiFusionForecastErrorFVAMeasureUID,
                        weightParameterMeasureUID: selectedProfile?.aiFusionEngineBasicKPIs?.error?.weightParameterMeasureUID
                    })),
                    ...(selectedProfile?.aiFusionEngineBasicKPIs?.bias?.isActive && ({
                        aiFusionForecastBiasMeasureUID: selectedProfile?.aiFusionEngineBasicKPIs?.bias?.aiFusionForecastBiasMeasureUID,
                        aiFusionForecastMovingAverageBiasMeasureUID: selectedProfile?.aiFusionEngineBasicKPIs?.bias?.aiFusionForecastMovingAverageBiasMeasureUID,
                        aiFusionForecastRelativeBiasMeasureUID: selectedProfile?.aiFusionEngineBasicKPIs?.bias?.aiFusionForecastRelativeBiasMeasureUID,
                        aiFusionForecastBiasRankMeasureUID: selectedProfile?.aiFusionEngineBasicKPIs?.bias?.aiFusionForecastBiasRankMeasureUID,
                        aiFusionForecastNeutralityRankMeasureUID: selectedProfile?.aiFusionEngineBasicKPIs?.bias?.aiFusionForecastNeutralityRankMeasureUID,
                        aiFusionForecastBiasFVAMeasureUID: selectedProfile?.aiFusionEngineBasicKPIs?.bias?.aiFusionForecastBiasFVAMeasureUID,
                        weightParameterBiasMeasure: selectedProfile?.aiFusionEngineBasicKPIs?.bias?.weightParameterMeasureUID
                    })),
                    ...(selectedProfile?.aiFusionEngineBasicKPIs?.churn?.isActive && ({
                        aiFusionForecastChurnMeasureUID: selectedProfile?.aiFusionEngineBasicKPIs?.churn?.aiFusionForecastChurnMeasureUID,
                        aiFusionForecastMovingAverageChurnMeasureUID: selectedProfile?.aiFusionEngineBasicKPIs?.churn?.aiFusionForecastMovingAverageChurnMeasureUID,
                        aiFusionForecastRelativeChurnMeasureUID: selectedProfile?.aiFusionEngineBasicKPIs?.churn?.aiFusionForecastRelativeChurnMeasureUID,
                        aiFusionForecastChurnRankMeasureUID: selectedProfile?.aiFusionEngineBasicKPIs?.churn?.aiFusionForecastChurnRankMeasureUID,
                        aiFusionForecastStabilityRankMeasureUID: selectedProfile?.aiFusionEngineBasicKPIs?.churn?.aiFusionForecastStabilityRankMeasureUID,
                        aiFusionForecastChurnFVAMeasureUID: selectedProfile?.aiFusionEngineBasicKPIs?.churn?.aiFusionForecastChurnFVAMeasureUID,
                        weightParameterChurnMeasure: selectedProfile?.aiFusionEngineBasicKPIs?.churn?.weightParameterMeasureUID
                    })),
                })),
            });

            if (selectedProfile?.valueOptimizationEngineConstraints?.length > 0) {
                setPrescriptiveConstraintsMeasure(
                    selectedProfile?.valueOptimizationEngineConstraints?.map((item) => ({
                        constraintsMeasure: item,
                    }))
                );
            }
            if (selectedProfile?.valueOptimizationEngineDecisions?.length > 0) {
                setPrescriptiveOptimal(
                    selectedProfile?.valueOptimizationEngineDecisions?.map((item) => ({
                        prescriptiveOptimalForecastDecisions: item,
                    }))
                );
            }
            if (selectedProfile?.valueOptimizationEngineObjectives?.length > 0) {
                setOptimizationMeasure(
                    selectedProfile?.valueOptimizationEngineObjectives?.map((item) => ({
                        optimizationObjective: item?.objectiveType,
                        objectiveMeasure: item?.objectiveMeasureUID,
                        weight: item?.objectiveWeight,
                    }))
                );
            }
            if (selectedProfile?.causalForecastAdditionalKPIs?.length > 0) {
                setPredictiveAdditionalKPI(
                    selectedProfile?.causalForecastAdditionalKPIs?.map((item) => ({
                        predictiveAdditionalKPI: item?.predictiveKPIName,
                        KPIMeasure: item?.kpiMeasureUID,
                    }))
                );
            }
            if (selectedProfile?.causalForecastOutcomes?.length > 0) {
                setCausalOutcomesForecasting(
                    selectedProfile?.causalForecastOutcomes?.map((item) => ({
                        bestCausalForecastMeasureUID: item?.bestCausalForecastMeasureUID,
                        causalForecastBaselineMeasureUID: item?.causalForecastBaselineMeasureUID,
                        exploreSensitivity: item?.exploreSensitivity,
                        ...(item?.exploreSensitivity && ({
                            probabilisticMetric: item?.probabilisticMetric
                        })),
                        uncertaintyDistribution: item?.uncertaintyDistribution,
                        ...item
                    }))
                )
            }
            if (selectedProfile?.aiFusionEngineHumanForecasts.length > 0) {
                setForcastData(
                    selectedProfile?.aiFusionEngineHumanForecasts?.map((item) => ({
                        humanforecasts: item,
                    }))
                );
            }
            if (selectedProfile?.causalForecastDrivers?.length > 0) {
                setDriverBasedMeasure(
                    selectedProfile?.causalForecastDrivers?.map((item) => ({
                        measure: item?.driverMeasureUID,
                        driverType: item?.driverType,
                        importanceMeasure: item?.driverImportanceMeasureUID,
                        contributionMeasure: item?.driverContributionMeasureUID,
                        exploreSensitivity: item?.exploreSensitivity,
                        offset: Number(item?.offset),
                        ...item,
                        ...item?.simulationFields,
                    }))
                );
            }
            if (selectedProfile?.statisticalForecastEngineModels?.length > 0) {
                setBasicKPI(selectedProfile?.statisticalForecastEngineModels?.map((item) => ({
                    StatisticalForecastModels: item
                })))
            }
            if (selectedProfile?.causalForecastEngineForecastModels?.length > 0) {
                setCausalForecastModels(selectedProfile?.causalForecastEngineForecastModels?.map((item) => ({
                    causalForecastEngineForecastModels: item
                })))
            }
        }
    }, [profileData, isEdit, selectedRows, selectedProfile, enterpriseData]);

    const handleChangeProfile = (newValue, fieldName, e) => {
        if (fieldName === "enterpriseName") {
            const selectedData = enterpriseData?.length > 0 && enterpriseData?.filter(
                (data) => data?._id === newValue?._id
            );

            setFormValues({
                ...formValues,
                [fieldName]: selectedData[0]?.enterpriseName,
                [`enterpriseUID`]: newValue?._id,
            });
        } else if (fieldName === "modelName") {
            const selectedData = modalName?.length > 0 && modalName?.filter(
                (data) => data?.modalUID === newValue?.modalUID
            );

            setFormValues({
                ...formValues,
                [fieldName]: selectedData[0]?.modelName,
                [`modelUID`]: newValue?.modalUID,
            });
        } else if (
            fieldName === "baseHistoryMeasureUID" ||
            fieldName === "forecastBaseLevel" ||
            fieldName === "historyBaseLevel" ||
            fieldName === "correctedHistoryMeasureUID" ||
            fieldName === "weightErrorMeasureUID" ||
            fieldName === "weightBiasMeasureUID" ||
            fieldName === "weightChurnMeasureUID" ||
            fieldName === "bestStatisticalForecastMeasureUID" ||
            fieldName === "bestStatisticalForecastDiscrepancyRankMeasureUID" ||
            fieldName === "bestStatisticalForecastQualityRankMeasureUID" ||
            //naive forecast measures
            fieldName === "naiveForecastMeasureUID" ||
            fieldName === "naiveForecastErrorMeasureUID" ||
            fieldName === "naiveForecastBiasMeasureUID" ||
            fieldName === "naiveForecastChurnMeasureUID" ||
            fieldName === "naiveForecastMovingAverageErrorMeasureUID" ||
            fieldName === "naiveForecastMovingAverageBiasMeasureUID" ||
            fieldName === "naiveForecastMovingAverageChurnMeasureUID" ||
            fieldName === "naiveForecastMovingAverageChurnUID" ||
            fieldName === "forecastabilityBandErrorUpperBoundMeasureUID" ||
            fieldName === "forecastabilityBandBiasUpperBoundMeasureUID" ||
            fieldName === "forecastabilityBandChurnUpperBoundMeasureUID" ||
            fieldName === "forecastabilityBandErrorLowerBoundMeasureUID" ||
            fieldName === "forecastabilityBandBiasLowerBoundMeasureUID" ||
            fieldName === "forecastabilityBandChurnLowerBoundMeasureUID" ||
            //optimal forecast measures
            fieldName === "optimalForecastMeasureUID" ||
            fieldName === "optimalForecastOverallDiscrepancyRankMeasureUID" ||
            fieldName === "optimalForecastOverallQualityRankMeasureUID" ||
            fieldName === "optimalForecastMeasureUID" ||
            fieldName === "optimalForecastErrorMeasureUID" ||
            fieldName === "optimalForecastMovingAverageErrorMeasureUID" ||
            fieldName === "optimalForecastRelativeErrorMeasureUID" ||
            fieldName === "optimalForecastErrorRankMeasureUID" ||
            fieldName === "optimalForecastAccuracyRankMeasureUID" ||
            fieldName === "optimalForecastErrorFVAMeasureUID" ||
            fieldName === "optimalForecastBiasMeasureUID" ||
            fieldName === "optimalForecastMovingAverageBiasMeasureUID" ||
            fieldName === "optimalForecastRelativeBiasMeasureUID" ||
            fieldName === "optimalForecastBiasRankMeasureUID" ||
            fieldName === "optimalForecastNeutralityRankMeasureUID" ||
            fieldName === "optimalForecastBiasFVAMeasureUID" ||
            fieldName === "optimalForecastChurnMeasureUID" ||
            fieldName === "optimalForecastMovingAverageChurnMeasureUID" ||
            fieldName === "optimalForecastRelativeChurnMeasureUID" ||
            fieldName === "optimalForecastChurnRankMeasureUID" ||
            fieldName === "optimalForecastStabilityRankMeasureUID" ||
            fieldName === "optimalForecastChurnFVAMeasureUID" ||
            fieldName === "optimalForecastQualityRankMeasureUID" ||
            fieldName === "optimalForecastWeightMeasureUID" ||
            fieldName === "optimalLagForecastWeightMeasureUID" ||
            // driver based forecast input measures
            fieldName === "bestCausalForecastMeasureUID" ||
            fieldName === "causalForecastBaselineMeasureUID" ||
            fieldName === "causalForecastDiscrepancyRankMeasureUID" ||
            fieldName === "causalForecastQualityRankMeasureUID" ||
            fieldName === "bestCausalForecastErrorMeasureUID" ||
            fieldName === "bestCausalForecastMovingAverageErrorMeasureUID" ||
            fieldName === "bestCausalForecastRelativeErrorMeasureUID" ||
            fieldName === "bestCausalForecastErrorFVAMeasureUID" ||
            fieldName === "bestCausalForecastBiasMeasureUID" ||
            fieldName === "bestCausalForecastRelativeBiasMeasureUID" ||
            fieldName === "bestCausalForecastBiasFVAMeasureUID" ||
            fieldName === "bestCausalForecastChurnMeasureUID" ||
            fieldName === "bestCausalForecastMovingAverageChurnMeasureUID" ||
            fieldName === "bestCausalForecastRelativeChurnMeasureUID" ||
            fieldName === "bestCausalForecastChurnFVAMeasureUID" ||
            //  driverBasedForecasting forecast error measures
            fieldName === "hazardRateMeasureUID" ||
            fieldName === "slopeMeasureUID" ||
            fieldName === "elasticityMeasureUID" ||
            fieldName === "crossElasticityMeasureUID" ||
            //forecastablity bands
            fieldName === "movingAverageErrorUpperBoundMeasureUID" ||
            fieldName === "movingAverageErrorLowerBoundMeasureUID" ||
            fieldName === "movingAverageBiasUpperBoundMeasureUID" ||
            fieldName === "movingAverageBiasLowerBoundMeasureUID" ||
            fieldName === "movingAverageChurnUpperBoundMeasureUID" ||
            fieldName === "movingAverageChurnLowerBoundMeasureUID" ||
            //Statistical
            //ERROR
            fieldName === "statisticalForecastErrorMeasureUID" ||
            fieldName === "statisticalForecastMovingAverageErrorMeasureUID" ||
            fieldName === "statisticalForecastRelativeErrorMeasureUID" ||
            fieldName === "statisticalForecastErrorRankMeasureUID" ||
            fieldName === "statisticalForecastAccuracyRankMeasureUID" ||
            fieldName === "statisticalForecastErrorFVAMeasureUID" ||
            //BIAS
            fieldName === "statisticalForecastBiasMeasureUID" ||
            fieldName === "statisticalForecastMovingAverageBiasMeasureUID" ||
            fieldName === "statisticalForecastRelativeBiasMeasureUID" ||
            fieldName === "statisticalForecastBiasRankMeasureUID" ||
            fieldName === "statisticalForecastNeutralityRankMeasureUID" ||
            fieldName === "statisticalForecastBiasFVAMeasureUID" ||
            //CHURN
            fieldName === "statisticalForecastChurnMeasureUID" ||
            fieldName === "statisticalForecastMovingAverageChurnMeasureUID" ||
            fieldName === "statisticalForecastRelativeChurnMeasureUID" ||
            fieldName === "statisticalForecastChurnRankMeasureUID" ||
            fieldName === "statisticalForecastStabilityRankMeasureUID" ||
            fieldName === "statisticalForecastChurnFVAMeasureUID" ||
            //Driver based
            //Error
            fieldName === "bestCausalForecastErrorMeasureUID" ||
            fieldName === "bestCausalForecastErrorRankMeasureUID" ||
            fieldName === "bestCausalForecastAccuracyRankMeasureUID" ||
            //Bias
            fieldName === "bestCausalForecastMovingAverageBiasMeasureUID" ||
            fieldName === "bestCausalForecastBiasRankMeasureUID" ||
            fieldName === "bestCausalForecastNeutralityRankMeasureUID" ||
            //Churn
            fieldName === "bestCausalForecastChurnRankMeasureUID" ||
            fieldName === "bestCausalForecastStabilityRankMeasureUID" ||
            fieldName === "driverBasedForecastChurnFVAMeasureUID" ||
            //AI Blend
            fieldName === "aiFusionForecastMeasureUID" ||
            fieldName === "aiFusionForecastOverallDiscrepancyRankMeasureUID" ||
            fieldName === "aiFusionForecastOverallQualityRankMeasureUID" ||
            //Error
            fieldName === "aiFusionForecastErrorMeasureUID" ||
            fieldName === "aiFusionForecastMovingAverageErrorMeasureUID" ||
            fieldName === "aiFusionForecastRelativeErrorMeasureUID" ||
            fieldName === "aiFusionForecastErrorRankMeasureUID" ||
            fieldName === "aiFusionForecastAccuracyRankMeasureUID" ||
            fieldName === "aiFusionForecastErrorFVAMeasureUID" ||
            fieldName === "weightParameterMeasureUID" ||
            //Bias
            fieldName === "aiFusionForecastBiasMeasureUID" ||
            fieldName === "aiFusionForecastMovingAverageBiasMeasureUID" ||
            fieldName === "aiFusionForecastRelativeBiasMeasureUID" ||
            fieldName === "aiFusionForecastBiasRankMeasureUID" ||
            fieldName === "aiFusionForecastNeutralityRankMeasureUID" ||
            fieldName === "aiFusionForecastBiasFVAMeasureUID" ||
            fieldName === "weightParameterBiasMeasure" ||
            //Churn
            fieldName === "aiFusionForecastChurnMeasureUID" ||
            fieldName === "aiFusionForecastMovingAverageChurnMeasureUID" ||
            fieldName === "aiFusionForecastRelativeChurnMeasureUID" ||
            fieldName === "aiFusionForecastChurnRankMeasureUID" ||
            fieldName === "aiFusionForecastStabilityRankMeasureUID" ||
            fieldName === "aiFusionForecastChurnFVAMeasureUID" ||
            fieldName === "weightParameterChurnMeasure"
        ) {
            setFormValues({
                ...formValues,
                [fieldName]: newValue?._id,
            });

        } else if (fieldName === "timePeriod") {
            const selectedData = timeData?.timeSchemaFields?.find(
                (data) => data?._id === newValue?._id
            );

            setFormValues({
                ...formValues,
                [fieldName]: selectedData && selectedData["Period Type"],
                timePeriodId: newValue && newValue?._id,
            });
        } else if (fieldName === "frequency") {
            const selectedData = timeData?.timeSchemaFields?.find(
                (data) => data?._id === newValue?._id
            );

            setFormValues({
                ...formValues,
                [fieldName]: selectedData && selectedData["Period Type"],
                frequencyId: newValue && newValue?._id,
            });
        } else if (fieldName === "notes") {
            const { name, value } = e.target;
            const words = value?.split(" ");

            if (words.length < 10) {
                e.target.value = words?.slice(0, 100)?.join(" ");
            }
            setFormValues((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
        } else if (
            fieldName === "statisticalForecastingEngine" ||
            fieldName === "hierarchicyAggregationEngine" ||
            fieldName === "timeAggregationEngine" ||
            fieldName === "causalForecastingEngine" ||
            fieldName === "valueOptimizationEngine" ||
            fieldName === "prescriptiveOptimalForecasting" ||
            fieldName === "driverBasedForecasting" ||
            fieldName === "aiFusionEngine" ||
            fieldName === "forecastabilityEngine" ||
            fieldName === "outlierCorrectionEngine" ||
            fieldName === "humanForecast" ||
            fieldName === "probabilisticForecstingEngine" ||
            fieldName === "sensitivityExplorerEngine" ||
            fieldName === "isCheckedbasicKPIError" ||
            fieldName === "isCheckedbasicKPIBias" ||
            fieldName === "isCheckedbasicKPIChurn" ||
            fieldName === "forecastabilityEngine" ||
            fieldName === "probabilisticStatisticalForecastingEngine" ||
            fieldName === "explainResults"
        ) {
            const { checked } = e.target;
            setFormValues((prevValues) => ({
                ...prevValues,
                [fieldName]: checked,
            }));
        } else if (fieldName === "probabilisticMetrics" || fieldName === "probabilisticMetricsOverview") {
            setFormValues((prevData) => ({
                ...prevData,
                [fieldName]: e.target.value,
            }));
        } else if (e.target.name === "naiveForecastCalculationHorizon") {
            const validatedValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
            const cappedValue = validatedValue > 999 ? 999 : validatedValue; // Cap value at 999

            // Update the form state
            setFormValues((prev) => ({
                ...prev,
                [e.target.name]: cappedValue ? parseInt(cappedValue, 10) : "",
            }));
        }
        else {
            const { name, value } = e.target;
            setFormValues((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
        }

    };


    const handleChangehierarchicalForecasting = (id, event, type) => {
        if (type === "hierachicalForecasting") {
            const isChecked = event.target.checked;

            setFormValues((prev) => {
                const currentValues = prev.hierarchicalForecastingAttributes || []; // Ensure it's an array
                const updatedValues = isChecked
                    ? [...currentValues, id] // Add the new value if checked
                    : currentValues.filter((value) => value !== id); // Remove the value if unchecked

                return {
                    ...prev,
                    hierarchicalForecastingAttributes: updatedValues, // Update the state
                };
            });
        } else {
            const isChecked = event.target.checked;
            setFormValues((prev) => {
                const currentValues = prev.timeBucketAggregationPeriods || []; // Ensure it's an array
                const updatedValues = isChecked
                    ? [...currentValues, id] // Add the new value if checked
                    : currentValues.filter((value) => value !== id); // Remove the value if unchecked

                return {
                    ...prev,
                    timeBucketAggregationPeriods: updatedValues, // Update the state
                };
            });
        }

    };

    const handleAdd = () => {
        setForcastData([...forcastData, { humanforecasts: "" }]);
    };
    const handleAddPrescriptive = () => {
        setPrescriptiveOptimal([...prescriptiveOptimal, { prescriptiveOptimalForecastDecisions: "" }]);
    };

    const handleAddCausalForecasting = () => {
        setCausalOutcomesForecasting([...causalOutcomesForecasting, {
            bestCausalForecastMeasureUID: "",
            causalForecastBaselineMeasureUID: "",
            uncertaintyDistribution: "",
            exploreSensitivity: "",
            probabilisticMetric: ""
        }]);
    };

    const handleAddOptimizationMeasure = () => {
        setOptimizationMeasure([...optimizationMeasure, { optimizationObjective: "", objectiveMeasure: "", weight: "" }]);
    };

    const handleAddbasicKPI = () => {
        setBasicKPI([
            ...basicKPI, {
                StatisticalForecastModels: "",
                causalForecastEngineForecastModels: "",
            }]);
    };

    const handleAddCausalForecast = () => {
        setCausalForecastModels([
            ...causalForecastModels, {
                causalForecastEngineForecastModels: "",
            }]);
    };

    const handleAddPredictivebasicKPI = () => {
        setPredictiveBasicKPI([
            ...predictiveBasicKPI, {
                predictiveKPI: "",
                bestPredictiveKPI: "",
                bestPredictiveforecastMovingAverageKPI: "",
                bestPredictiveforecastRelativeKPI: "",
                bestPredictiveforecastKPIRank: "",
                bestPredictiveforecastMirrorKPIRank: "",
                bestPredictiveforecastKPIFVA: "",
            }]);
    };

    const handleAddPredictiveAdditionalKPI = () => {
        setPredictiveAdditionalKPI([
            ...predictiveAdditionalKPI, {
                predictiveAdditionalKPI: "",
                KPIMeasure: ""
            }]);
    };

    const handleAddAiEnsembleKPI = () => {
        setAiEnsembleBasicKPI([
            ...aiEnsembleBasicKPI, {
                aiEnsembleKPI: "",
                bestAiEnsembleKPI: "",
                bestAiEnsembleMovingAverageKPI: "",
                bestAiEnsembleRelativeKPI: "",
                bestAiEnsembleKPIRank: "",
                bestAiEnsembleMirrorKPIRank: "",
                bestAiEnsembleKPIFVA: "",
                weightParameterMeasure: ""
            }]);
    };

    const handleAddConstraintsMeasure = () => {
        setPrescriptiveConstraintsMeasure([
            ...prescriptiveconstraintsMeasure, {
                constraintsMeasure: ""
            }]);
    };

    const handleAddDriverMeasure = () => {
        setDriverBasedMeasure([...driverBasedMeasure, {
            measure: "",
            driverType: "Continuous",
            importanceMeasure: "",
            contributionMeasure: "",
            offset: 0,
            uncertaintyDistribution: "",
            lowerBoundMeasure: "",
            upperBoundMeasure: "",
            stepSizeMeasure: ""
        }])
    }

    const handleChangeDriverBasedMeasure = (index, newValue, type, event) => {
        const updatedFormValues = [...driverBasedMeasure];

        if (type === "exploreSensitivity" || type === "staticValue") {
            const { checked } = event.target;
            updatedFormValues[index][type] = checked;
            setDriverBasedMeasure(updatedFormValues);
        } else if (type === "driverType" || type === "offset" ||
            type === "uncertaintyDistribution" ||
            type === "variationMethod" ||
            type === "noOfSimulation" ||
            type === "randomValue" ||
            type === "baseValueNumber" ||
            type === "baseValueType") {
            const { value } = event.target;
            updatedFormValues[index][type] = value;
            setDriverBasedMeasure(updatedFormValues);
        } else if (type === "percentiles" || type === "fixedValues") {
            const { value } = event.target;
            const tags = value.split(",");
            updatedFormValues[index][type] = tags;
            setDriverBasedMeasure(updatedFormValues);
        } else if (type === "minChangePercentage" || type === "maxChangePercentage" || type === "stepSizePercentage") {
            const { value } = event.target;
            updatedFormValues[index][type] = value;

            const minChange = parseFloat(updatedFormValues[index]["minChangePercentage"]);
            const maxChange = parseFloat(updatedFormValues[index]["maxChangePercentage"]);
            const stepSize = parseFloat(updatedFormValues[index]["stepSizePercentage"]);

            if (!isNaN(minChange) && !isNaN(maxChange) && !isNaN(stepSize) && stepSize > 0) {
                const calculation = Math.floor((maxChange - minChange) / stepSize) + 1;
                updatedFormValues[index]["noOfSimulation"] = calculation;
            }
            setDriverBasedMeasure(updatedFormValues);
        } else {
            updatedFormValues[index][type] = newValue?._id;
            setDriverBasedMeasure(updatedFormValues);
        }
    };


    const handleChangeCausalForecasting = (index, newValue, type, event, forceToggle = false) => {
        const updatedFormValues = [...causalOutcomesForecasting];
        if (type === "exploreSensitivity") {
            const { checked } = event.target;

            if (forceToggle) {
                updatedFormValues[index][type] = false;
            } else {
                updatedFormValues[index][type] = checked;
            }

            setCausalOutcomesForecasting(updatedFormValues);
        } else if (type === "uncertaintyDistribution" || type === "probabilisticMetric") {
            const { value } = event.target
            updatedFormValues[index][type] = value;
            setCausalOutcomesForecasting(updatedFormValues);
        } else {
            updatedFormValues[index][type] = newValue?._id;
            setCausalOutcomesForecasting(updatedFormValues);
        }
    };

    const handleChangeForeCastProfile = (index, newValue, type, event) => {
        const updatedFormValues = [...forcastData];
        updatedFormValues[index][type] = newValue?._id;
        setForcastData(updatedFormValues);
    };

    const handleChangePrescriptive = (index, newValue, type, event) => {
        const updatedFormValues = [...prescriptiveOptimal];
        updatedFormValues[index][type] = newValue?._id;
        setPrescriptiveOptimal(updatedFormValues);
    };

    const handleChangeOptimizationMeasure = (index, newValue, type, e) => {
        const updatedFormValues = [...optimizationMeasure];
        if (type === "objectiveMeasure") {
            updatedFormValues[index][type] = newValue?._id;
            setOptimizationMeasure(updatedFormValues);
        }
        const { name, value } = e.target
        updatedFormValues[index][name] = value;
        setOptimizationMeasure(updatedFormValues);
    };

    const handleChangeBasicKPI = (index, newValue, type, e) => {
        const updatedFormValues = [...basicKPI];
        updatedFormValues[index][type] = newValue;
        setBasicKPI(updatedFormValues);
    };
    const handleChangeCausalForecastModels = (index, newValue, type, e) => {
        const updatedFormValues = [...causalForecastModels];
        updatedFormValues[index][type] = newValue;
        setCausalForecastModels(updatedFormValues);
    };

    const handleChangePredictiveBasicKPI = (index, newValue, type, e) => {
        const updatedFormValues = [...predictiveBasicKPI];
        if (type === "predictiveKPI") {
            const { name, value } = e.target
            updatedFormValues[index][name] = value;
            setPredictiveBasicKPI(updatedFormValues);
        } else {
            updatedFormValues[index][type] = newValue?._id;
            setPredictiveBasicKPI(updatedFormValues);
        }
    };

    const handleChangePredictiveAdditionalBasicKPI = (index, newValue, type, e) => {
        const updatedFormValues = [...predictiveAdditionalKPI];
        if (type === "predictiveAdditionalKPI") {
            const { name, value } = e.target
            updatedFormValues[index][name] = value;
            setPredictiveAdditionalKPI(updatedFormValues);
        } else {
            updatedFormValues[index][type] = newValue?._id;
            setPredictiveAdditionalKPI(updatedFormValues);
        }
    };

    const handleChangeAiEnsembleBasicKPI = (index, newValue, type, e) => {
        const updatedFormValues = [...aiEnsembleBasicKPI];
        if (type === "aiEnsembleKPI") {
            const { name, value } = e.target
            updatedFormValues[index][name] = value;
            setPredictiveAdditionalKPI(updatedFormValues);
        } else {
            updatedFormValues[index][type] = newValue?._id;
            setPredictiveAdditionalKPI(updatedFormValues);
        }
    };

    const handleClick = (event, index, type) => {
        if (type === "humanforecasts") {
            setAnchorEl(event.currentTarget);
            setDataIndex(index);
        } else if (type === "demandDriven") {
            setAnchorMeasureEl(event.currentTarget)
            setDataIndex(index);
        } else if (type === "optimizationObjective") {
            setAnchorOptimizationMeasureEl(event.currentTarget)
            setDataIndex(index);
        } else if (type === "basicKPI") {
            setAnchorBasicKPIEl(event.currentTarget)
            setDataIndex(index);
        } else if (type === "predictiveBasicKPI") {
            setAnchorPredictiveBasicEl(event.currentTarget)
            setDataIndex(index);
        } else if (type === "predictiveAdditionalKPIMeasure") {
            setAnchorPredictiveAdditionalKPIEl(event.currentTarget)
            setDataIndex(index);
        } else if (type === "prescriptiveConstraints") {
            setAnchorConstraintsMeasureEl(event.currentTarget)
            setDataIndex(index);
        } else if (type === "aiEnsembleKPIMeasure") {
            setAnchorAiEnsembleEl(event.currentTarget)
            setDataIndex(index);
        } else if (type === "prescriptiveOptimal") {
            setAnchorPrescriptiveEnsembleEl(event.currentTarget)
            setDataIndex(index);
        } else if (type === "causalForecastProfile") {
            setAnchorCausalForecastingEl(event.currentTarget)
            setDataIndex(index);
        } else if (type === "causalForecastModels") {
            setAnchorCausalForecastingModelsEl(event.currentTarget)
            setDataIndex(index);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleremove = (type) => {
        if (type === "humanforecasts") {
            const updatedField = [...forcastData];
            updatedField.splice(dataIndex, 1);
            setForcastData(updatedField);
            setAnchorEl(null);
        } else if (type === "demandDriven") {
            const updatedField = [...driverBasedMeasure];
            updatedField.splice(dataIndex, 1);
            setDriverBasedMeasure(updatedField);
            setAnchorMeasureEl(null);
        } else if (type === "optimizationObjective") {
            const updatedField = [...optimizationMeasure];
            updatedField.splice(dataIndex, 1);
            setOptimizationMeasure(updatedField);
            setAnchorOptimizationMeasureEl(null);
        } else if (type === "basicKPI") {
            const updatedField = [...basicKPI];
            updatedField.splice(dataIndex, 1);
            setBasicKPI(updatedField);
            setAnchorBasicKPIEl(null);
        } else if (type === "predictiveBasicKPI") {
            const updatedField = [...predictiveBasicKPI];
            updatedField.splice(dataIndex, 1);
            setPredictiveBasicKPI(updatedField);
            setAnchorPredictiveBasicEl(null);
        } else if (type === "predictiveAdditionalKPIMeasure") {
            const updatedField = [...predictiveAdditionalKPI];
            updatedField.splice(dataIndex, 1);
            setPredictiveAdditionalKPI(updatedField);
            setAnchorPredictiveAdditionalKPIEl(null);
        } else if (type === "prescriptiveConstraints") {
            const updatedField = [...prescriptiveconstraintsMeasure];
            updatedField.splice(dataIndex, 1);
            setPrescriptiveConstraintsMeasure(updatedField);
            setAnchorConstraintsMeasureEl(null);
        } else if (type === "aiEnsembleKPIMeasure") {
            const updatedField = [...aiEnsembleBasicKPI];
            updatedField.splice(dataIndex, 1);
            setAiEnsembleBasicKPI(updatedField);
            setAnchorAiEnsembleEl(null);
        } else if (type === "prescriptiveOptimal") {
            const updatedField = [...prescriptiveOptimal];
            updatedField.splice(dataIndex, 1);
            setPrescriptiveOptimal(updatedField);
            setAnchorPrescriptiveEnsembleEl(null);
        } else if (type === "causalForecastProfile") {
            const updatedField = [...causalOutcomesForecasting];
            updatedField.splice(dataIndex, 1);
            setCausalOutcomesForecasting(updatedField);
            setAnchorCausalForecastingEl(null);
        } else if (type === "causalForecastModels") {
            const updatedField = [...causalForecastModels];
            updatedField.splice(dataIndex, 1);
            setCausalForecastModels(updatedField);
            setAnchorCausalForecastingModelsEl(null);
        }
    };

    const getModelList = async () => {
        try {
            const userId = localStorage.getItem("accessToken");
            const config = {
                headers: {
                    Authorization: `Bearer ${JSON.parse(userId)?.token}`,
                },
            };

            let response = await axios.get(`${API_BASE_URL}/all/getAllModel`, config);
            let filteredData = isEdit
                ? response.data?.enterpriseList?.length > 0 && response.data?.enterpriseList?.filter(
                    (item) => item.enterpriseUID?._id === formValues.enterpriseUID
                )
                : response.data?.enterpriseList;

            if (formValues.enterpriseName) {
                filteredData = filteredData?.length > 0 && filteredData?.filter(
                    (dime) => dime.enterpriseUID?._id === formValues.enterpriseUID
                );
            }
            setModalName(filteredData);
        } catch (error) {
            console.error("Error fetching model list:", error);
        }
    };

    useEffect(() => {
        if (formValues.enterpriseName) {
            getModelList();
        }
    }, [formValues.enterpriseName]);

    useEffect(() => {
        if (formValues?.enterpriseUID && formValues?.modelUID) {
            dispatch(getProfilePrimaryTimeDimension({ formValues }));
            dispatch(getProfileMeasure({ formValues }));
        }
    }, [formValues?.enterpriseUID && formValues?.modelUID]);

    const handleCancel = () => {
        setIsCreate(false);
    };

    const validation = () => {
        if (formValues?.name === "" || formValues?.name === undefined) {
            toast.error("Please enter profile name");
            return true;
        } else if (
            formValues?.enterpriseUID === "" ||
            formValues?.enterpriseUID === undefined
        ) {
            toast.error("Please select enterprise");
            return true;
        } else if (
            formValues?.modelUID === "" ||
            formValues?.modelUID === undefined
        ) {
            toast.error("Please select model");
            return true;
        }
        return false;
    };
    const handleSubmit = async () => {
        if (validation()) {
            return;
        } else {
            const payload = {
                name: formValues?.name,
                enterpriseUID: formValues?.enterpriseUID,
                modelUID: formValues?.modelUID,
                outlierCorrectionEngine: formValues?.outlierCorrectionEngine || false,
                statisticalForecastingEngine: formValues?.statisticalForecastingEngine || false,
                hierarchicyAggregationEngine: formValues?.hierarchicyAggregationEngine || false,
                timeAggregationEngine: formValues?.timeAggregationEngine || false,
                causalForecastingEngine: formValues?.causalForecastingEngine || false,
                valueOptimizationEngine: formValues?.valueOptimizationEngine || false,
                aiFusionEngine: formValues?.aiFusionEngine || false,
                forecastabilityEngine: formValues?.forecastabilityEngine || false,
                periodicity: formValues?.timePeriod,
                frequency: formValues?.frequency,
                historyHorizon: Number(formValues?.historyHorizon),
                forecastHorizon: Number(formValues?.forecastHorizon),
                validationHorizon: Number(formValues?.validationHorizon),
                naiveForecastCalculationHorizon: Number(formValues?.naiveForecastCalculationHorizon),
                forecastBaseLevel: formValues?.forecastBaseLevel,
                historyBaseLevel: formValues?.historyBaseLevel,
                baseHistoryMeasureUID: formValues?.baseHistoryMeasureUID,
                correctedHistoryMeasureUID: formValues?.correctedHistoryMeasureUID,
                naiveForecastMeasureUID: formValues?.naiveForecastMeasureUID,
                ...(formValues?.hierarchicyAggregationEngine && formValues?.hierarchicalForecastingAttributes?.length > 0 && ({
                    hierarchyAggregationEngineAttributes: formValues?.hierarchicalForecastingAttributes
                })),
                ...(formValues?.timeAggregationEngine && formValues?.timeBucketAggregationPeriods?.length > 0 && ({
                    timeAggregationEnginePeriods: formValues?.timeBucketAggregationPeriods
                })),
                basicKPIs: {
                    ...(formValues?.isCheckedbasicKPIError && ({
                        error: {
                            isActive: formValues?.isCheckedbasicKPIError,
                            errorMethod: formValues?.errorMethod,
                            ...(formValues?.errorMethod === "wMAPE" && ({
                                weightMeasureUID: formValues?.weightErrorMeasureUID
                            })),
                            defaultValue: Number(formValues?.defaultErrorValue),
                            movingAverageHorizon: Number(formValues?.movingAverageErrorHorizon),
                            naiveForecastErrorMeasureUID: formValues?.naiveForecastErrorMeasureUID,
                            naiveForecastMovingAverageErrorMeasureUID: formValues?.naiveForecastMovingAverageErrorMeasureUID,
                            ...(formValues?.forecastabilityEngine && ({
                                ...(formValues?.forecastabilityBandErrorUpperBoundMeasureUID && ({
                                    upperBoundMeasureUID: formValues?.forecastabilityBandErrorUpperBoundMeasureUID,
                                })),
                                ...(formValues?.forecastabilityBandErrorLowerBoundMeasureUID && ({
                                    lowerBoundMeasureUID: formValues?.forecastabilityBandErrorLowerBoundMeasureUID
                                }))
                            }))
                        }
                    })),
                    ...(formValues?.isCheckedbasicKPIBias && ({
                        bias: {
                            isActive: formValues?.isCheckedbasicKPIBias,
                            biasMethod: formValues?.biasMethod,
                            ...(formValues?.biasMethod === "wMPE" && ({
                                weightMeasureUID: formValues?.weightBiasMeasureUID
                            })),
                            defaultValue: Number(formValues?.defaultBiasValue),
                            movingAverageHorizon: Number(formValues?.movingAverageBiasHorizon),
                            naiveForecastBiasMeasureUID: formValues?.naiveForecastBiasMeasureUID,
                            naiveForecastMovingAverageBiasMeasureUID: formValues?.naiveForecastMovingAverageBiasMeasureUID,
                            ...(formValues?.forecastabilityEngine && ({
                                ...(formValues?.forecastabilityBandBiasUpperBoundMeasureUID && ({
                                    upperBoundMeasureUID: formValues?.forecastabilityBandBiasUpperBoundMeasureUID,
                                })),
                                ...(formValues?.forecastabilityBandBiasLowerBoundMeasureUID && ({
                                    lowerBoundMeasureUID: formValues?.forecastabilityBandBiasLowerBoundMeasureUID
                                }))
                            }))
                        }
                    })),
                    ...(formValues?.isCheckedbasicKPIChurn && ({
                        churn: {
                            isActive: formValues?.isCheckedbasicKPIChurn,
                            churnMethod: formValues?.churnMethod,
                            ...(formValues?.churnMethod === "wMAPC" && ({
                                weightMeasureUID: formValues?.weightChurnMeasureUID
                            })),
                            defaultValue: Number(formValues?.defaultChurnValue),
                            movingAverageHorizon: Number(formValues?.movingAverageChurnHorizon),
                            naiveForecastChurnMeasureUID: formValues?.naiveForecastChurnMeasureUID,
                            naiveForecastMovingAverageChurnMeasureUID: formValues?.naiveForecastMovingAverageChurnMeasureUID,
                            ...(formValues?.forecastabilityEngine && ({
                                ...(formValues?.forecastabilityBandChurnUpperBoundMeasureUID && ({
                                    upperBoundMeasureUID: formValues?.forecastabilityBandChurnUpperBoundMeasureUID,
                                })),
                                ...(formValues?.forecastabilityBandChurnLowerBoundMeasureUID && ({
                                    lowerBoundMeasureUID: formValues?.forecastabilityBandChurnLowerBoundMeasureUID
                                }))
                            }))
                        }
                    })),
                },
                ...((formValues?.probabilisticStatisticalForecastingEngine || formValues?.probabilisticForecstingEngine) && ({
                    probabilisticForecastSettings: {
                        probabilisticMetrics: formValues?.probabilisticMetricsOverview,
                        noOfIterations: Number(formValues?.noOfIterationsOverview),
                        noOfBins: Number(formValues?.noOfBinsOverview),
                        initialSeedType: formValues?.initialSeedTypeOverview,
                        ...(formValues?.initialSeedTypeOverview === "Fixed" && ({
                            initialSeedValue: Number(formValues?.initialSeedValueOverview)
                        }))
                    }
                })),
                statisticalForecastEngineBasicSettings: {
                    probabilisticStatisticalForecastingEngine: formValues?.probabilisticStatisticalForecastingEngine,
                    bestStatisticalForecastMeasureUID: formValues?.bestStatisticalForecastMeasureUID,
                    bestStatisticalForecastDiscrepancyRankMeasureUID: formValues?.bestStatisticalForecastDiscrepancyRankMeasureUID,
                    bestStatisticalForecastQualityRankMeasureUID: formValues?.bestStatisticalForecastQualityRankMeasureUID,
                },
                ...(formValues?.probabilisticStatisticalForecastingEngine && ({
                    statisticalForecastEngineProbabilisticSettings: {
                        probabilisticMetrics: formValues?.probabilisticMetrics,
                        noOfIterations: Number(formValues?.noOfIterations),
                        noOfBins: Number(formValues?.noOfBins),
                        initialSeedType: formValues?.initialSeedType,
                        ...(formValues?.initialSeedType === "Fixed" && ({
                            initialSeedValue: Number(formValues?.initialSeedValue)
                        }))
                    },
                })),
                ...(basicKPI?.length > 0 && ({
                    statisticalForecastEngineModels: basicKPI?.map((item) => item.StatisticalForecastModels)
                })),
                ...(formValues?.statisticalForecastingEngine && ({
                    statisticalForecastEngineKPIs: {
                        ...(formValues?.isCheckedbasicKPIError && ({
                            error: {
                                isActive: formValues?.isCheckedbasicKPIError,
                                bestStatisticalForecastErrorMeasureUID: formValues?.statisticalForecastErrorMeasureUID,
                                bestStatisticalForecastMovingAverageErrorMeasureUID: formValues?.statisticalForecastMovingAverageErrorMeasureUID,
                                bestStatisticalForecastRelativeErrorMeasureUID: formValues?.statisticalForecastRelativeErrorMeasureUID,
                                bestStatisticalForecastErrorRankMeasureUID: formValues?.statisticalForecastErrorRankMeasureUID,
                                bestStatisticalForecastAccuracyRankMeasureUID: formValues?.statisticalForecastAccuracyRankMeasureUID,
                                bestStatisticalForecastErrorFVAMeasureUID: formValues?.statisticalForecastErrorFVAMeasureUID
                            }
                        })),
                        ...(formValues?.isCheckedbasicKPIBias && ({
                            bias: {
                                isActive: formValues?.isCheckedbasicKPIBias,
                                bestStatisticalForecastBiasMeasureUID: formValues?.statisticalForecastBiasMeasureUID,
                                bestStatisticalForecastMovingAverageBiasMeasureUID: formValues?.statisticalForecastMovingAverageBiasMeasureUID,
                                bestStatisticalForecastRelativeBiasMeasureUID: formValues?.statisticalForecastRelativeBiasMeasureUID,
                                bestStatisticalForecastBiasRankMeasureUID: formValues?.statisticalForecastBiasRankMeasureUID,
                                bestStatisticalForecastNeutralityRankMeasureUID: formValues?.statisticalForecastNeutralityRankMeasureUID,
                                bestStatisticalForecastBiasFVAMeasureUID: formValues?.statisticalForecastBiasFVAMeasureUID
                            }
                        })),
                        ...(formValues?.isCheckedbasicKPIChurn && ({
                            churn: {
                                isActive: formValues?.isCheckedbasicKPIChurn,
                                bestStatisticalForecastChurnMeasureUID: formValues?.statisticalForecastChurnMeasureUID,
                                bestStatisticalForecastMovingAverageChurnMeasureUID: formValues?.statisticalForecastMovingAverageChurnMeasureUID,
                                bestStatisticalForecastRelativeChurnMeasureUID: formValues?.statisticalForecastRelativeChurnMeasureUID,
                                bestStatisticalForecastChurnRankMeasureUID: formValues?.statisticalForecastChurnRankMeasureUID,
                                bestStatisticalForecastStabilityRankMeasureUID: formValues?.statisticalForecastStabilityRankMeasureUID,
                                bestStatisticalForecastChurnFVAMeasureUID: formValues?.statisticalForecastChurnFVAMeasureUID
                            }
                        })),
                    }
                })),
                ...(formValues?.causalForecastingEngine && ({
                    causalForecastBasicSettings: {
                        bestCausalForecastMeasureUID: formValues?.bestCausalForecastMeasureUID,
                        causalForecastBaselineMeasureUID: formValues?.causalForecastBaselineMeasureUID,
                        causalForecastDiscrepancyRankMeasureUID: formValues?.causalForecastDiscrepancyRankMeasureUID,
                        causalForecastQualityRankMeasureUID: formValues?.causalForecastQualityRankMeasureUID,
                        probabilisticForecstingEngine: formValues?.probabilisticForecstingEngine,
                        sensitivityExplorerEngine: formValues?.sensitivityExplorerEngine,
                    },

                    ...(causalOutcomesForecasting?.length > 0 && ({
                        causalForecastOutcomes: causalOutcomesForecasting?.map((item) => ({
                            bestCausalForecastMeasureUID: item?.bestCausalForecastMeasureUID,
                            causalForecastBaselineMeasureUID: item?.causalForecastBaselineMeasureUID,
                            exploreSensitivity: item?.exploreSensitivity,
                            ...(item?.exploreSensitivity && ({
                                probabilisticMetric: item?.probabilisticMetric
                            })),
                            ...item
                        }))
                    })),
                    ...(driverBasedMeasure?.length > 0 && ({
                        causalForecastDrivers: driverBasedMeasure?.map((item) => ({
                            ...(item?.measure && ({
                                driverMeasureUID: item?.measure,
                            })),
                            driverType: item?.driverType,
                            ...(item?.offset && ({
                                offset: Number(item?.offset),
                            })),
                            ...(item?.importanceMeasure && ({
                                driverImportanceMeasureUID: item?.importanceMeasure,
                            })),
                            ...(item?.contributionMeasure && ({
                                driverContributionMeasureUID: item?.contributionMeasure,
                            })),
                            uncertaintyDistribution: item?.uncertaintyDistribution,
                            ...((item?.uncertaintyDistribution === "Normal" && ({
                                meanMeasureUID: item?.meanMeasureUID,
                                standardDeviationMeasureUID: item?.standardDeviationMeasureUID
                            }))),
                            ...((item?.uncertaintyDistribution === "Beta" && ({
                                alpha1MeasureUID: item?.alpha1MeasureUID,
                                alpha2MeasureUID: item?.alpha2MeasureUID
                            }))),
                            ...((item?.uncertaintyDistribution === "Exponential" && ({
                                betaMeasureUID: item?.betaMeasureUID
                            }))),
                            ...((item?.uncertaintyDistribution === "PERT" && ({
                                maximumMeasureUID: item?.maximumMeasureUID,
                                minimumMeasureUID: item?.minimumMeasureUID,
                                mostLikelyMeasureUID: item?.mostLikelyMeasureUID
                            }))),
                            ...((item?.uncertaintyDistribution === "Trangular" && ({
                                bottomPercentageMeasureUID: item?.bottomPercentageMeasureUID,
                                topPercentageMeasureUID: item?.topPercentageMeasureUID
                            }))),
                            ...((item?.uncertaintyDistribution === "Lognormal" && ({
                                meanMeasureUID: item?.meanMeasureUID,
                                standardDeviationMeasureUID: item?.standardDeviationMeasureUID
                            }))),
                            ...((item?.uncertaintyDistribution === "Weibull" && ({
                                alphaMeasureUID: item?.alphaMeasureUID,
                                betaMeasureUID: item?.betaMeasureUID
                            }))),
                            ...((item?.uncertaintyDistribution === "Uniform" && ({
                                minimumMeasureUID: item?.minimumMeasureUID,
                                maximumMeasureUID: item?.maximumMeasureUID
                            }))),
                            ...((item?.uncertaintyDistribution === "Gamma" && ({
                                alphaMeasureUID: item?.alphaMeasureUID,
                                betaMeasureUID: item?.betaMeasureUID,
                            }))),
                            ...((item?.uncertaintyDistribution === "General" && ({
                                minimumMeasureUID: item?.minimumMeasureUID,
                                maximumMeasureUID: item?.maximumMeasureUID,
                            }))),
                            ...((item?.uncertaintyDistribution === "Bernoulli" && ({
                                probabilityMeasureUID: item?.probabilityMeasureUID,
                            }))),
                            ...((item?.uncertaintyDistribution === "Bionomial" && ({
                                probabilityMeasureUID: item?.probabilityMeasureUID,
                                countMeasureUID: item?.countMeasureUID,
                            }))),
                            ...((item?.uncertaintyDistribution === "Histogram" && ({
                                probabilityMeasureUID: item?.probabilityMeasureUID,
                            }))),
                            ...((item?.uncertaintyDistribution === "Pareto" && ({
                                thetaMeasureUID: item?.thetaMeasureUID,
                                alphaMeasureUID: item?.alphaMeasureUID,
                            }))),
                            ...((item?.uncertaintyDistribution === "Student's" && ({
                                degreesOfFreedomMeasureUID: item?.degreesOfFreedomMeasureUID,
                            }))),
                            ...((item?.uncertaintyDistribution === "Poisson" && ({
                                lambdaMeasureUID: item?.lambdaMeasureUID,
                            }))),
                            ...((item?.uncertaintyDistribution === "Geometric" && ({
                                probabilityMeasureUID: item?.probabilityMeasureUID,
                            }))),
                            ...((item?.uncertaintyDistribution === "Hypergeometric" && ({
                                sampleSizeMeasureUID: item?.sampleSizeMeasureUID,
                                populationMeasureUID: item?.populationMeasureUID,
                                populationSizeMeasureUID: item?.populationSizeMeasureUID,
                            }))),
                            ...((item?.uncertaintyDistribution === "Negative Bionomial" && ({
                                probabilityOfASingleSuccessMeasureUID: item?.probabilityOfASingleSuccessMeasureUID,
                                noOfSuccessesMeasureUID: item?.noOfSuccessesMeasureUID,
                            }))),
                            ...((item?.uncertaintyDistribution === "Discrete" && ({
                                possibleValuesMeasureUID: item?.possibleValuesMeasureUID,
                                probabilityWeightMeasureUID: item?.probabilityWeightMeasureUID,
                            }))),
                            ...((item?.uncertaintyDistribution === "Discrete Uniform" && ({
                                possibleValuesMeasureUID: item?.possibleValuesMeasureUID,
                            }))),
                            exploreSensitivity: item?.exploreSensitivity,
                            ...((!item?.exploreSensitivity || (!item?.exploreSensitivity && item?.uncertaintyDistribution == null))
                                && ({
                                    baseValueType: item?.baseValueType,
                                    ...(item?.baseValueType === "Percentile" && ({
                                        baseValueNumber: Number(item?.baseValueNumber),
                                    })),
                                })),
                            ...(item?.exploreSensitivity && ({
                                simulationFields: {
                                    ...(item?.exploreSensitivity && ({
                                        variationMethod: item?.variationMethod,
                                        noOfSimulation: Number(item?.noOfSimulation),
                                    })),
                                    ...(item?.variationMethod === "Ranges" && item?.exploreSensitivity && ({
                                        minChangePercentage: Number(item?.minChangePercentage),
                                        maxChangePercentage: Number(item?.maxChangePercentage),
                                        stepSizePercentage: Number(item?.stepSizePercentage),
                                    })),
                                    ...(item?.baseValueType === "Percentile" && ({
                                        baseValueNumber: Number(item?.baseValueNumber),
                                    })),
                                    ...(item?.variationMethod === "Fixed" && item?.exploreSensitivity && ({
                                        fixedValues: item?.fixedValues?.map((item) => Number(item))
                                    })),
                                    ...(item?.variationMethod === "Percentiles" && item?.exploreSensitivity && ({
                                        percentiles: item?.percentiles?.map((item) => Number(item))
                                    })),
                                    ...(item?.variationMethod === "Random" && item?.exploreSensitivity && ({
                                        randomValue: item?.randomValue
                                    }))
                                }
                            }))
                        })),
                    })),
                    causalForecastBasicKPIs: {
                        ...(formValues?.isCheckedbasicKPIError && ({
                            error: {
                                isActive: formValues?.isCheckedbasicKPIError,
                                bestCausalForecastErrorMeasureUID: formValues?.bestCausalForecastErrorMeasureUID,
                                bestCausalForecastMovingAverageErrorMeasureUID: formValues?.bestCausalForecastMovingAverageErrorMeasureUID,
                                bestCausalForecastRelativeErrorMeasureUID: formValues?.bestCausalForecastRelativeErrorMeasureUID,
                                bestCausalForecastErrorRankMeasureUID: formValues?.bestCausalForecastErrorRankMeasureUID,
                                bestCausalForecastAccuracyRankMeasureUID: formValues?.bestCausalForecastAccuracyRankMeasureUID,
                                bestCausalForecastErrorFVAMeasureUID: formValues?.bestCausalForecastErrorFVAMeasureUID
                            }
                        })),
                        ...(formValues?.isCheckedbasicKPIBias && ({
                            bias: {
                                isActive: formValues?.isCheckedbasicKPIBias,
                                bestCausalForecastBiasMeasureUID: formValues?.bestCausalForecastBiasMeasureUID,
                                bestCausalForecastMovingAverageBiasMeasureUID: formValues?.bestCausalForecastMovingAverageBiasMeasureUID,
                                bestCausalForecastRelativeBiasMeasureUID: formValues?.bestCausalForecastRelativeBiasMeasureUID,
                                bestCausalForecastBiasRankMeasureUID: formValues?.bestCausalForecastBiasRankMeasureUID,
                                bestCausalForecastNeutralityRankMeasureUID: formValues?.bestCausalForecastNeutralityRankMeasureUID,
                                bestCausalForecastBiasFVAMeasureUID: formValues?.bestCausalForecastBiasFVAMeasureUID
                            }
                        })),
                        ...(formValues?.isCheckedbasicKPIChurn && ({
                            churn: {
                                isActive: formValues?.isCheckedbasicKPIChurn,
                                bestCausalForecastChurnMeasureUID: formValues?.bestCausalForecastChurnMeasureUID,
                                bestCausalForecastMovingAverageChurnMeasureUID: formValues?.bestCausalForecastMovingAverageChurnMeasureUID,
                                bestCausalForecastRelativeChurnMeasureUID: formValues?.bestCausalForecastRelativeChurnMeasureUID,
                                bestCausalForecastChurnRankMeasureUID: formValues?.bestCausalForecastChurnRankMeasureUID,
                                bestCausalForecastStabilityRankMeasureUID: formValues?.bestCausalForecastStabilityRankMeasureUID,
                                bestCausalForecastChurnFVAMeasureUID: formValues?.bestCausalForecastChurnFVAMeasureUID
                            }
                        })),
                    },
                    ...(predictiveAdditionalKPI.length > 0 && ({
                        causalForecastAdditionalKPIs: predictiveAdditionalKPI?.map((item) => ({
                            predictiveKPIName: item.predictiveAdditionalKPI,
                            kpiMeasureUID: item.KPIMeasure,
                        }))
                    })),
                    ...(causalForecastModels?.length > 0 && ({
                        causalForecastEngineForecastModels: causalForecastModels?.map((item) => item.causalForecastEngineForecastModels)
                    })),
                })),
                ...(formValues?.valueOptimizationEngine && ({
                    valueOptimizationEngineBasicSettings: {
                        optimalForecastMeasureUID: formValues?.optimalForecastMeasureUID,
                        optimalForecastOverallDiscrepancyRankMeasureUID: formValues?.optimalForecastOverallDiscrepancyRankMeasureUID,
                        optimalForecastOverallQualityRankMeasureUID: formValues?.optimalForecastOverallQualityRankMeasureUID,
                        maxRuntime: Number(formValues?.maxRuntime),
                        explainResults: formValues?.explainResults,
                    },
                    ...(optimizationMeasure.length > 0 &&
                        ({
                            valueOptimizationEngineObjectives: optimizationMeasure?.map((item) => ({
                                objectiveType: item?.optimizationObjective,
                                objectiveMeasureUID: item?.objectiveMeasure,
                                objectiveWeight: Number(item?.weight)
                            }))
                        })
                    ),
                    ...(prescriptiveconstraintsMeasure?.length > 0 && ({
                        valueOptimizationEngineConstraints: prescriptiveconstraintsMeasure?.map((item) => item?.constraintsMeasure)
                    })),
                    ...(prescriptiveOptimal?.length > 0 && ({
                        valueOptimizationEngineDecisions: prescriptiveOptimal?.map((item) => item?.prescriptiveOptimalForecastDecisions)
                    })),
                    valueOptimizationEngineBasicKPIs: {
                        ...(formValues?.isCheckedbasicKPIError && ({
                            error: {
                                isActive: formValues?.isCheckedbasicKPIError,
                                optimalForecastErrorMeasureUID: formValues?.optimalForecastErrorMeasureUID,
                                optimalForecastMovingAverageErrorMeasureUID: formValues?.optimalForecastMovingAverageErrorMeasureUID,
                                optimalForecastRelativeErrorMeasureUID: formValues?.optimalForecastRelativeErrorMeasureUID,
                                optimalForecastErrorRankMeasureUID: formValues?.optimalForecastErrorRankMeasureUID,
                                optimalForecastAccuracyRankMeasureUID: formValues?.optimalForecastAccuracyRankMeasureUID,
                                optimalForecastErrorFVAMeasureUID: formValues?.optimalForecastErrorFVAMeasureUID
                            }
                        })),
                        ...(formValues?.isCheckedbasicKPIBias && ({
                            bias: {
                                isActive: formValues?.isCheckedbasicKPIBias,
                                optimalForecastBiasMeasureUID: formValues?.optimalForecastBiasMeasureUID,
                                optimalForecastMovingAverageBiasMeasureUID: formValues?.optimalForecastMovingAverageBiasMeasureUID,
                                optimalForecastRelativeBiasMeasureUID: formValues?.optimalForecastRelativeBiasMeasureUID,
                                optimalForecastBiasRankMeasureUID: formValues?.optimalForecastBiasRankMeasureUID,
                                optimalForecastNeutralityRankMeasureUID: formValues?.optimalForecastNeutralityRankMeasureUID,
                                optimalForecastBiasFVAMeasureUID: formValues?.optimalForecastBiasFVAMeasureUID
                            }
                        })),
                        ...(formValues?.isCheckedbasicKPIChurn && ({
                            churn: {
                                isActive: formValues?.isCheckedbasicKPIChurn,
                                optimalForecastChurnMeasureUID: formValues?.optimalForecastChurnMeasureUID,
                                optimalForecastMovingAverageChurnMeasureUID: formValues?.optimalForecastMovingAverageChurnMeasureUID,
                                optimalForecastRelativeChurnMeasureUID: formValues?.optimalForecastRelativeChurnMeasureUID,
                                optimalForecastChurnRankMeasureUID: formValues?.optimalForecastChurnRankMeasureUID,
                                optimalForecastStabilityRankMeasureUID: formValues?.optimalForecastStabilityRankMeasureUID,
                                optimalForecastChurnFVAMeasureUID: formValues?.optimalForecastChurnFVAMeasureUID
                            }
                        })),
                    }
                })),
                ...(formValues?.aiFusionEngine && ({
                    aiFusionEngineBasicSettings: {
                        aiFusionForecastMeasureUID: formValues?.aiFusionForecastMeasureUID,
                        aiFusionForecastOverallDiscrepancyRankMeasureUID: formValues?.aiFusionForecastOverallDiscrepancyRankMeasureUID,
                        aiFusionForecastOverallQualityRankMeasureUID: formValues?.aiFusionForecastOverallQualityRankMeasureUID,
                        humanForecast: formValues?.humanForecast || false,
                    },
                    aiFusionEngineBasicKPIs: {
                        ...(formValues?.isCheckedbasicKPIError && ({
                            error: {
                                isActive: formValues?.isCheckedbasicKPIError,
                                aiFusionForecastErrorMeasureUID: formValues?.aiFusionForecastErrorMeasureUID,
                                aiFusionForecastMovingAverageErrorMeasureUID: formValues?.aiFusionForecastMovingAverageErrorMeasureUID,
                                aiFusionForecastRelativeErrorMeasureUID: formValues?.aiFusionForecastRelativeErrorMeasureUID,
                                aiFusionForecastErrorRankMeasureUID: formValues?.aiFusionForecastErrorRankMeasureUID,
                                aiFusionForecastAccuracyRankMeasureUID: formValues?.aiFusionForecastAccuracyRankMeasureUID,
                                aiFusionForecastErrorFVAMeasureUID: formValues?.aiFusionForecastErrorFVAMeasureUID,
                                weightParameterMeasureUID: formValues?.weightParameterMeasureUID
                            }
                        })),
                        ...(formValues?.isCheckedbasicKPIBias && ({
                            bias: {
                                isActive: formValues?.isCheckedbasicKPIBias,
                                aiFusionForecastBiasMeasureUID: formValues?.aiFusionForecastBiasMeasureUID,
                                aiFusionForecastMovingAverageBiasMeasureUID: formValues?.aiFusionForecastMovingAverageBiasMeasureUID,
                                aiFusionForecastRelativeBiasMeasureUID: formValues?.aiFusionForecastRelativeBiasMeasureUID,
                                aiFusionForecastBiasRankMeasureUID: formValues?.aiFusionForecastBiasRankMeasureUID,
                                aiFusionForecastNeutralityRankMeasureUID: formValues?.aiFusionForecastNeutralityRankMeasureUID,
                                aiFusionForecastBiasFVAMeasureUID: formValues?.aiFusionForecastBiasFVAMeasureUID,
                                weightParameterMeasureUID: formValues?.weightParameterBiasMeasure
                            }
                        })),
                        ...(formValues?.isCheckedbasicKPIChurn && ({
                            churn: {
                                isActive: formValues?.isCheckedbasicKPIChurn,
                                aiFusionForecastChurnMeasureUID: formValues?.aiFusionForecastChurnMeasureUID,
                                aiFusionForecastMovingAverageChurnMeasureUID: formValues?.aiFusionForecastMovingAverageChurnMeasureUID,
                                aiFusionForecastRelativeChurnMeasureUID: formValues?.aiFusionForecastRelativeChurnMeasureUID,
                                aiFusionForecastChurnRankMeasureUID: formValues?.aiFusionForecastChurnRankMeasureUID,
                                aiFusionForecastStabilityRankMeasureUID: formValues?.aiFusionForecastStabilityRankMeasureUID,
                                aiFusionForecastChurnFVAMeasureUID: formValues?.aiFusionForecastChurnFVAMeasureUID,
                                weightParameterMeasureUID: formValues?.weightParameterChurnMeasure
                            }
                        })),
                    },
                    ...(formValues?.humanForecast && forcastData?.length > 0 && ({
                        aiFusionEngineHumanForecasts: forcastData?.map((item) => item?.humanforecasts)
                    }))
                })),
                notes: formValues?.notes
            }

            const result = await dispatch(
                createProfile({ payload, isEdit, selectedRows })
            );
            if (result?.meta?.requestStatus === "fulfilled") {
                dispatch(getProfileData());
                setFormValues({});
                setForcastData([
                    {
                        humanforecasts: "",
                    },
                ]);
                setIsCreate(false);
            } else if (result?.meta?.requestStatus === "rejected") {
                toast.error(result?.error?.message);
                setIsCreate(true);
            }
        }
    };

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div style={{ overflow: "hidden" }}>
            <AppBar position="static" sx={{ backgroundColor: "white" }}>
                <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="h6" sx={{ color: "black", fontFamily: "'Poppins', sans-serif" }}>
                        {/* {getPageHeading()} */}
                        Forecast Profile
                    </Typography>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            marginRight: "30px",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <Button
                            onClick={handleCancel}
                            title="Cancel"
                            style={{
                                padding: "6px 15px",
                                borderRadius: "2px",
                                cursor: "pointer",
                                marginRight: "10px",
                                fontFamily: "'Poppins', sans-serif"
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            title="Create new dimension with selected attributes"
                            style={{
                                padding: "7px 15px",
                                borderRadius: "2px",
                                cursor: "pointer",
                                fontFamily: "'Poppins', sans-serif"
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                        <Tab label="Overview" value="1" sx={{ textTransform: "none" }} />
                        {formValues?.statisticalForecastingEngine && (
                            <Tab label="Statistical Forecasting Engine" value="2" sx={{ textTransform: "none" }} />
                        )}
                        {formValues?.causalForecastingEngine && (
                            <Tab label="Causal Forecasting Engine" value="3" sx={{ textTransform: "none" }} />)}
                        {formValues?.valueOptimizationEngine && (
                            <Tab label="Value Optimization Engine" value="4" sx={{ textTransform: "none" }} />)}
                        {formValues?.aiFusionEngine && (
                            <Tab label="AI Fusion Engine" value="5" sx={{ textTransform: "none" }} />)}
                        <Tab label="Administrative Info" value="6" sx={{ textTransform: "none" }} />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <OverviewTab
                        formValues={formValues}
                        selectedProfile={selectedProfile}
                        handleChangeProfile={handleChangeProfile}
                        enterpriseData={enterpriseData}
                        modalName={modalName}
                        timeData={timeData}
                        isView={isView}
                        profileMeasure={profileMeasure}
                        handleChangehierarchicalForecasting={handleChangehierarchicalForecasting}
                    />
                </TabPanel>
                <TabPanel value="2">
                    <ExtrapolativeTab
                        formValues={formValues}
                        handleChangeProfile={handleChangeProfile}
                        profileMeasure={profileMeasure}
                        handleClick={handleClick}
                        handleremove={handleremove}
                        basicKPI={basicKPI}
                        handleChangeBasicKPI={handleChangeBasicKPI}
                        handleAddbasicKPI={handleAddbasicKPI}
                        setBasicKPI={setBasicKPI}
                        deletebasicKPI={deletebasicKPI}
                        anchorBasicKPIl={anchorBasicKPIl}
                        setAnchorBasicKPIEl={setAnchorBasicKPIEl}
                    />
                </TabPanel>
                <TabPanel value="3">
                    <PredictiveTab
                        formValues={formValues}
                        selectedProfile={selectedProfile}
                        handleChangeProfile={handleChangeProfile}
                        isView={isView}
                        profileMeasure={profileMeasure}
                        driverBasedMeasure={driverBasedMeasure}
                        deleteDemandDriven={deleteDemandDriven}
                        anchorMeasureEl={anchorMeasureEl}
                        setAnchorMeasureEl={setAnchorMeasureEl}
                        handleClick={handleClick}
                        handleremove={handleremove}
                        handleChangeDriverBasedMeasure={handleChangeDriverBasedMeasure}
                        handleAddDriverMeasure={handleAddDriverMeasure}
                        handleAddPredictivebasicKPI={handleAddPredictivebasicKPI}
                        deletePredictiveBasicKPI={deletePredictiveBasicKPI}
                        predictiveBasicKPI={predictiveBasicKPI}
                        anchorPredictiveBasicKPIEl={anchorPredictiveBasicKPIEl}
                        setAnchorPredictiveBasicEl={setAnchorPredictiveBasicEl}
                        handleChangePredictiveBasicKPI={handleChangePredictiveBasicKPI}
                        predictiveAdditionalKPI={predictiveAdditionalKPI}
                        setPredictiveAdditionalKPI={setPredictiveAdditionalKPI}
                        handleChangePredictiveAdditionalBasicKPI={handleChangePredictiveAdditionalBasicKPI}
                        anchorPredictiveAdditionalKPIEl={anchorPredictiveAdditionalKPIEl}
                        setAnchorPredictiveAdditionalKPIEl={setAnchorPredictiveAdditionalKPIEl}
                        deletePredictiveAdditionalKPI={deletePredictiveAdditionalKPI}
                        handleAddPredictiveAdditionalKPI={handleAddPredictiveAdditionalKPI}
                        handleAddCausalForecasting={handleAddCausalForecasting}
                        causalOutcomesForecasting={causalOutcomesForecasting}
                        handleChangeCausalForecasting={handleChangeCausalForecasting}
                        anchorCausalForecastingEl={anchorCausalForecastingEl}
                        setAnchorCausalForecastingEl={setAnchorCausalForecastingEl}
                        deleteCausalForecasting={deleteCausalForecasting}
                        handleAddbasicKPI={handleAddbasicKPI}
                        basicKPI={basicKPI}
                        handleChangeBasicKPI={handleChangeBasicKPI}
                        deletebasicKPI={deletebasicKPI}
                        anchorBasicKPIl={anchorBasicKPIl}
                        setAnchorBasicKPIEl={setAnchorBasicKPIEl}
                        causalForecastModels={causalForecastModels}
                        handleAddCausalForecast={handleAddCausalForecast}
                        handleChangeCausalForecastModels={handleChangeCausalForecastModels}
                        anchorCausalForecastModelsEl={anchorCausalForecastModelsEl}
                        setAnchorCausalForecastingModelsEl={setAnchorCausalForecastingModelsEl}
                        deleteCausalForecastModelsEnsemble={deleteCausalForecastModelsEnsemble}
                    />
                </TabPanel>
                <TabPanel value="4">
                    <PrescriptiveTab
                        formValues={formValues}
                        selectedProfile={selectedProfile}
                        handleChangeProfile={handleChangeProfile}
                        isView={isView}
                        profileMeasure={profileMeasure}
                        handleAddOptimizationMeasure={handleAddOptimizationMeasure}
                        optimizationMeasure={optimizationMeasure}
                        handleChangeOptimizationMeasure={handleChangeOptimizationMeasure}
                        anchorOptimizationMeasureEl={anchorOptimizationMeasureEl}
                        setAnchorOptimizationMeasureEl={setAnchorOptimizationMeasureEl}
                        deleteOptimization={deleteOptimization}
                        handleClick={handleClick}
                        handleremove={handleremove}
                        deleteConstraintsMeasure={deleteConstraintsMeasure}
                        prescriptiveconstraintsMeasure={prescriptiveconstraintsMeasure}
                        anchorConstraintsMeasureEl={anchorConstraintsMeasureEl}
                        setAnchorConstraintsMeasureEl={setAnchorConstraintsMeasureEl}
                        handleAddConstraintsMeasure={handleAddConstraintsMeasure}
                        setPrescriptiveConstraintsMeasure={setPrescriptiveConstraintsMeasure}
                        prescriptiveOptimal={prescriptiveOptimal}
                        anchorPrescriptiveEnsembleEl={anchorPrescriptiveEnsembleEl}
                        setAnchorPrescriptiveEnsembleEl={setAnchorPrescriptiveEnsembleEl}
                        handleChangePrescriptive={handleChangePrescriptive}
                        deletePrescriptiveEnsemble={deletePrescriptiveEnsemble}
                        handleAddPrescriptive={handleAddPrescriptive}
                    />
                </TabPanel>
                <TabPanel value="5">
                    <AiEnsembleTab
                        formValues={formValues}
                        selectedProfile={selectedProfile}
                        handleChangeProfile={handleChangeProfile}
                        isView={isView}
                        profileMeasure={profileMeasure}
                        aiEnsembleBasicKPI={aiEnsembleBasicKPI}
                        anchorAiEnsembleEl={anchorAiEnsembleEl}
                        setAnchorAiEnsembleEl={setAnchorAiEnsembleEl}
                        deleteAiEnsemble={deleteAiEnsemble}
                        handleAddAiEnsembleKPI={handleAddAiEnsembleKPI}
                        handleChangeAiEnsembleBasicKPI={handleChangeAiEnsembleBasicKPI}
                        handleAdd={handleAdd}
                        handleClick={handleClick}
                        handleremove={handleremove}
                        forcastData={forcastData}
                        handleChangeForeCastProfile={handleChangeForeCastProfile}
                        deleteForCast={deleteForCast}
                        anchorEl={anchorEl}
                        handleClose={handleClose}
                    />
                </TabPanel>
                <TabPanel value="6">
                    <AdministrativeTab
                        formValues={formValues}
                        handleChangeProfile={handleChangeProfile}
                        isView={isView}
                        handleSubmit={handleSubmit}
                        handleCancel={handleCancel}
                    />
                </TabPanel>
            </TabContext>
        </div>
    );
};

export default CreateProfile;