import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Menu,
  MenuItem,
  Button,
  Box,
  Modal,
  TextField,
  FormControl,
  Select,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";
import TablePagination from '@mui/material/TablePagination';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import React, { useEffect, useMemo, useState } from 'react'
import { MdMoreVert } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux';
import { getEnterModelList } from '../../store/ApiSlice/modelSlice';
import { getEnterpriseList } from '../../store/ApiSlice/enterpriseSlice';
import { getMeasureList, getMeasureListWithEnterprise, getMeasurePaginationList } from '../../store/ApiSlice/measureSlice';
import axios from 'axios';
import { API_BASE_URL } from '../../helper/constants';
import { toast } from "react-toastify";
import moment from "moment";
import Paper from "@mui/material/Paper";
import CloseIcon from '@mui/icons-material/Close';
import Loader from "../loader/Loader";
import MeasureViewData from "./MeasureViewData";

const MeasureMaterialList = ({
  setUpdatedId,
  handleCreateUpdate,
  setModalDeleteOpen,
  handleDownload,
  handleOpenModal,
  handleCopyModel,
  modelEnterprise
}) => {
  const userData = JSON.parse(localStorage.getItem("accessToken"))
  const { measureData, measureList, totalCount, measurePaginationList } = useSelector((state) => state.measure)
  const [openedMenus, setOpenedMenus] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableHeader, setTableHeader] = useState([]);
  const [isMasterDataLoading, setIsMasterDataLoading] = useState(false);
  const [expandedRowData, setExpandedRowData] = useState(null);
  const [apiData, setApiData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPage, setTotalPage] = useState()
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(true)
  const [measureViewData, setMeasureViewData] = useState()
  const [viewpage, setViewPage] = useState({
    pageIndex: 0, // Current page index (0-based)
    pageSize: 10,
  })
  // const [viewPageSize, setViewPageSize] = useState(10)
  const [pagination, setPagination] = useState({
    pageIndex: 0, // Current page index (0-based)
    pageSize: 10, // Number of rows per page
  });
  const dispatch = useDispatch()
  const formValues = {
    enterpriseUID: modelEnterprise?.enterpriseUID,
    modelUID: modelEnterprise?.modelUID
  }
  // useEffect(() => {
  //   const storedPaginationData = localStorage.getItem("measurePaginationData");
  //   let measurePaginationData = storedPaginationData ? JSON.parse(storedPaginationData) : {};

  //   if (
  //     (measurePaginationData.page !== pagination?.pageIndex ||
  //       measurePaginationData.itemsPerPage !== pagination?.pageSize ||
  //       measurePaginationData.search !== search) &&
  //     search !== ""
  //   ) {
  //     measurePaginationData = {
  //       page: pagination?.pageIndex + 1,
  //       itemsPerPage: pagination?.pageSize,
  //       search: search,
  //       enterpriseUID: formValues?.enterpriseUID,
  //       modelUID: formValues?.modelUID,
  //     };
  //     localStorage.setItem("measurePaginationData", JSON.stringify(measurePaginationData));
  //   }
  // }, [pagination?.pageIndex, pagination?.pageSize, search, formValues]);

  // const searchData = JSON.parse(localStorage.getItem("measurePaginationData"))
  // useState(() => {
  //   setSearch(searchData?.search)
  // }, [searchData])

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      await dispatch(getMeasurePaginationList({
        page: pagination?.pageIndex + 1,
        itemsPerPage: pagination?.pageSize,
        search: search,
        enterpriseUID: formValues?.enterpriseUID,
        modelUID: formValues?.modelUID
      }));
      setLoader(false);
    };
    fetchData();
  }, [pagination, search, modelEnterprise?.enterpriseUID, modelEnterprise?.modelUID]);

  useEffect(() => {
    if ((modelEnterprise?.enterpriseUID && modelEnterprise?.enterpriseUID !== undefined) && (modelEnterprise?.modelUID && modelEnterprise?.modelUID !== undefined)) {

      const fetchData = async () => {
        setLoader(true);
        await dispatch(getMeasureListWithEnterprise({ formValues }))
        setLoader(false);
      };
      fetchData()
    }
  }, [modelEnterprise?.enterpriseUID, modelEnterprise?.modelUID])
  const findEnterpriseAttribute = ((!modelEnterprise?.enterpriseUID || modelEnterprise?.enterpriseUID === "") && (!modelEnterprise?.modelUID || modelEnterprise?.modelUID === "")) ? measureData :
    measurePaginationList?.filter((item) => item?.enterpriseUID === modelEnterprise?.enterpriseUID)
      ?.filter((item) => item?.modelUID === modelEnterprise?.modelUID)


  const sortedMeasureData = useMemo(() => {
    return [...(measurePaginationList || [])].sort((a, b) => {
      const measureA = a.measureName.toLowerCase();
      const measureB = b.measureName.toLowerCase();
      if (measureA < measureB) return -1;
      if (measureA > measureB) return 1;
      return 0;
    });
  }, [measurePaginationList, modelEnterprise?.enterpriseUID, modelEnterprise?.modelUID]);



  const handleClick = (rowId) => (event) => {
    event.stopPropagation();
    setOpenedMenus((prevMenus) => ({
      ...prevMenus,
      [rowId]: event.currentTarget,
    }));
  };

  const handleClose = (id) => {
    setOpenedMenus(prevState => ({
      ...prevState,
      [id]: null
    }));
  };

  useEffect(() => {
    // dispatch(getEnterModelList());
    dispatch(getEnterpriseList());
  }, [])

  const toggleRowExpansion = (row, type) => {
    const userId = localStorage.getItem("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(userId)?.token}`, // Replace yourToken with the actual token
      },
    };
    const payload = {
      measureName: row?.original?.measureName ? row?.original?.measureName : row?.measureName,
      measureUID: row?.original?._id ? row?.original?._id : row?.modelUID,
      baselevelUID: row?.original?.baselevelUID?._id ? row?.original?.baselevelUID?._id : row?.baselevelUID?._id,
      enterpriseUID: row?.original?.enterpriseUID ? row?.original?.enterpriseUID : row?.enterpriseUID,
      modelUID: row?.original?.modelUID ? row?.original?.modelUID : row?.modelUID,
      page: viewpage?.pageIndex + 1,
      limit: viewpage?.pageSize
    }
    setIsMasterDataLoading(true);
    axios
      .post(
        `${API_BASE_URL}/measure/dataload/view`,
        payload,
        config
      )
      .then((response) => {
        setTotalPage(response?.data?.pagination?.total)
        const data = response?.data?.data || [];
        const apiDataArray = Object.entries(response?.data?.schemaInfo)?.sort(([, a], [, b]) => a.order - b.order)?.map(
          ([name, type]) => ({ name, type })
        );
        setApiData(apiDataArray);
        setTableData(data);

        if (data.length > 0) {
          const keys = Object.keys(data[0]);
          const headers = keys?.filter((key) => key !== "_id" && key !== "__v");
          setTableHeader(headers);
        }
        setIsMasterDataLoading(false);
      })
      .catch((error) => {
        setIsMasterDataLoading(false);
      });
    setExpandedRowData(row?.original?._id);
  };

  const columns = useMemo(() => [
    {
      accessorKey: "measureName",
      enableColumnOrdering: false,
      header: "Measure name",
      enableSorting: false,
    },
    {
      accessorKey: "enterpriseName",
      enableColumnOrdering: false,
      header: "Enterprise Name",
      enableSorting: false,
    },
    {
      accessorKey: "modelName",
      enableColumnOrdering: false,
      header: "Model Name",
      enableSorting: false,
    },
    {
      accessorKey: "baselevel",
      enableColumnOrdering: false,
      header: "Base Level",
      enableSorting: false,
    },
    {
      accessorKey: "type",
      enableColumnOrdering: false,
      header: "Type",
      enableSorting: false,
    },

    !expandedRowData && {
      accessorKey: "createdOn",
      enableColumnOrdering: false,
      header: "Created On",
      enableSorting: false,
      Cell: ({ row }) => (
        <>
          <p>{moment(row?.original?.createdOn).format(userData?.data?.dateFormat ? userData?.data?.dateFormat : "DD-MM-YYYY")}</p>
        </>
      ),
    },
    !expandedRowData && {
      accessorKey: "changedOn",
      enableColumnOrdering: false,
      header: "Changed On",
      enableSorting: false,
      Cell: ({ row }) => (
        <>
          <p>{moment(row?.original?.changedOn).format(userData?.data?.dateFormat ? userData?.data?.dateFormat : "DD-MM-YYYY")}</p>
        </>
      ),
    },
    {
      accessorKey: "view",
      header: "",
      enableSorting: false,
      enableColumnOrdering: false,
      Cell: ({ row }) => {
        return (
          <>
            <IconButton onClick={handleClick(row.original._id)} size="small">
              <MdMoreVert />
            </IconButton>
            <Menu
              anchorEl={openedMenus[row.original._id]}
              open={Boolean(openedMenus[row.original._id])}
              onClose={() => { handleClose(row.original._id) }}
            >
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  toggleRowExpansion(row);
                  handleClose(row.original._id);
                  setMeasureViewData(row)
                }}
                size="small"
                sx={{ fontSize: "12px" }}
              >
                View Data
              </MenuItem>
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  handleClose(row.original._id);
                  handleOpenModal(row);
                }}
                sx={{ fontSize: "12px" }}
              >
                Upload
              </MenuItem>
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  handleClose(row.original._id);
                  handleDownload(row);
                }}
                sx={{ fontSize: "12px" }}
              >
                Download Template
              </MenuItem>
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  handleClose(row.original._id);
                  handleCreateUpdate(true, row?.original?._id);
                }}
                sx={{ fontSize: "12px" }}
              >
                Edit Measure
              </MenuItem>
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  handleClose(row.original._id);
                  handleCopyModel(row.original._id)
                }}
                sx={{ fontSize: "12px" }}
              >
                Copy Measure
              </MenuItem>
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  handleClose(row.original._id);
                  setUpdatedId(row.original._id);
                  setModalDeleteOpen(true);
                }}
                sx={{ fontSize: "12px" }}
              >
                Delete Measure
              </MenuItem>
            </Menu>
          </>
        )
      }
    }
  ], [
    openedMenus
  ])

  useEffect(() => {
    toggleRowExpansion(measureViewData)
  }, [viewpage])

  // const table = useMaterialReactTable({
  //   columns: columns.filter(Boolean),
  //   data: sortedMeasureData || [],
  //   enableColumnOrdering: true,
  //   enableSelectAll: false,
  //   enableRowSelection: false,
  //   muiSelectCheckboxProps: { color: "secondary" },
  //   positionToolbarAlertBanner: "head-overlay",
  //   enableFullScreenToggle: false,
  //   enableColumnPinning: true,
  //   // onGlobalFilterChange: setSearch,
  //   state: {
  //     search,
  //     isLoading: loader,
  //   },
  //   muiCircularProgressProps: {
  //     thickness: 5,
  //     size: 55,
  //     sx: {
  //       color: 'black',
  //     }
  //   },
  //   muiSkeletonProps: {
  //     animation: 'pulse',
  //     height: 28,
  //   },
  //   muiTableHeadCellProps: {
  //     sx: {
  //       fontSize: "12px"
  //     },
  //   },
  //   muiTableBodyCellProps: {
  //     sx: {
  //       fontSize: "12px"
  //     }
  //   },
  //   muiTableBodyRowProps: ({ row }) => ({
  //     onClick: () => {
  //       handleClose(row.original._id);
  //       handleCreateUpdate(true, row?.original?._id);
  //     }, // Row click event
  //     sx: {
  //       cursor: 'pointer', // Add a pointer cursor to indicate clickable rows
  //       fontSize: "12px",
  //       fontFamily: "'Poppins', sans-serif",
  //       '&:hover': {
  //         backgroundColor: '#f5f5f5', // Optional: Add hover effect for clarity
  //       },
  //     },
  //   }),
  // });

  const table = useMaterialReactTable({
    columns: columns.filter(Boolean),
    data: sortedMeasureData || [],
    enableColumnOrdering: true,
    enableSelectAll: false,
    enableRowSelection: false,
    muiSelectCheckboxProps: { color: 'secondary' },
    positionToolbarAlertBanner: 'head-overlay',
    enableFullScreenToggle: false,
    enableColumnPinning: true,
    onGlobalFilterChange: setSearch,
    state: {
      search,
      // isLoading: loader,
      pagination, // Pass current pagination state
    },
    muiCircularProgressProps: {
      thickness: 5,
      size: 55,
      sx: {
        color: 'black',
      },
    },
    muiSkeletonProps: {
      animation: 'pulse',
      height: 28,
    },
    muiTableHeadCellProps: {
      sx: {
        fontSize: '12px',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '12px',
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        handleClose(row.original._id);
        handleCreateUpdate(true, row?.original?._id);
      },
      sx: {
        cursor: 'pointer',
        fontSize: '12px',
        fontFamily: "'Poppins', sans-serif",
        '&:hover': {
          backgroundColor: '#f5f5f5',
        },
      },
    }),
    manualPagination: true, // Enable manual pagination
    onPaginationChange: setPagination, // Update pagination state
    rowCount: totalCount, // Total rows for pagination
  });

  const prepareColumnsData = (data) => {
    // Remove duplicates based on the 'name' field
    const uniqueData = data.reduce((acc, current) => {
      const x = acc.find(item => item.name === current.name);
      if (!x) {
        acc.push(current);
      }
      return acc;
    }, []);

    // Find index of "AAA RAGGR v2" field
    const measureFieldIndex = uniqueData.findIndex(field => field.name === measureViewData?.original?.measureName);

    if (measureFieldIndex !== -1) {
      // Separate fields before and after the measure field
      const beforeMeasureFields = uniqueData.slice(0, measureFieldIndex);
      const afterMeasureFields = uniqueData.slice(measureFieldIndex + 1);

      // Identify "Scenario Name" and "Time Period Name" fields
      const additionalFields = uniqueData.filter(field =>
        field.name === "Scenario Name" || field.name === "Time Period Name"
      );

      // Remove "Scenario Name" and "Time Period Name" from the original list before adding them
      const remainingFields = uniqueData.filter(field =>
        field.name !== "Scenario Name" && field.name !== "Time Period Name"
      );

      // Reconstruct the final ordered list
      return [
        ...beforeMeasureFields.filter(field => field.name !== "Scenario Name" && field.name !== "Time Period Name"),
        ...additionalFields,
        uniqueData[measureFieldIndex], // Place "AAA RAGGR v2" measure field here
        ...afterMeasureFields,
      ];
    }

    return uniqueData; // Return if measure field is not found
  };

  const reorderedFields = prepareColumnsData(apiData);

  return (
    <>
      <MaterialReactTable table={table} />
      {expandedRowData && (
        <ExpandedRow
          expandedRowData={expandedRowData}
          rowData={findEnterpriseAttribute?.find((row) => row?._id === expandedRowData)}
          tableHeader={tableHeader}
          tableData={tableData}
          toggleRowExpansion={toggleRowExpansion}
          apiData={apiData}
          setApiData={setApiData}
          setTableData={setTableData}
          setExpandedRowData={setExpandedRowData}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalPage={totalPage}
          setTotalPage={setTotalPage}
          setTableHeader={setTableHeader}
          setIsMasterDataLoading={setIsMasterDataLoading}
          reorderedFields={reorderedFields}
          viewpage={viewpage}
          setViewPage={setViewPage}
          
        />
      )}
    </>
  )
}

const ExpandedRow = ({
  expandedRowData,
  rowData,
  tableHeader,
  tableData,
  apiData,
  setApiData,
  setTableData,
  setExpandedRowData,
  toggleRowExpansion,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  totalPage,
  setTotalPage,
  setTableHeader,
  setIsMasterDataLoading,
  reorderedFields,
  viewpage,
  setViewPage
}) => {

  const [actionMenu, setActionMenu] = useState(false);
  const [openAddModel, setOpenAddModel] = useState(false);
  const [storeSingleData, setSingleData] = useState({});
  const [selectIndex, setSelectIndex] = useState(null);
  const [openEditModel, setOpenEditModel] = useState(false);
  const [sceneList, setSceneList] = useState([]);
  const [formData, setFormData] = useState({
    "Scenario Name": "",
  });

  useEffect(() => {
    const getManagedata = () => {
      const userId = localStorage.getItem("accessToken");
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(userId)?.token}`,
        },
      };
      const payload = {
        measureName: rowData?.measureName,
        measureUID: rowData?._id,
        baselevelUID: rowData?.baselevelUID?._id,
        enterpriseUID: rowData?.enterpriseUID,
        modelUID: rowData?.modelUID,
        page: page,
        limit: rowsPerPage
      }
      axios
        .post(
          `${API_BASE_URL}/measure/dataload/view`,
          payload,
          config
        )
        .then((response) => {
          setTotalPage(response?.data?.pagination?.total)
          const data = response?.data?.data || [];
          const apiDataArray = Object.entries(response?.data?.schemaInfo)?.sort(([, a], [, b]) => a.order - b.order)?.map(
            ([name, type]) => ({ name, type })
          );
          setApiData(apiDataArray);
          setTableData(data);

          if (data.length > 0) {
            const keys = Object.keys(data[0]);
            const headers = keys?.filter((key) => key !== "_id" && key !== "__v");
            setTableHeader(headers);
          }
          setIsMasterDataLoading(false);
        })
        .catch((error) => {
          setIsMasterDataLoading(false);
        });
    }
    getManagedata();
  }, [page, rowsPerPage])

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChange = (field, value) => {
    if (field === "Scenario Name") {
      setFormData({ ...formData, [field]: value });
    } else {
      setFormData({ ...formData, [field]: value });
    }
  };

  const handleDeleteMenu = async (deletedRowData) => {
    const baseURL = `${API_BASE_URL}/measure/dataload/delete`;
    const userId = localStorage.getItem("accessToken");
    const body = {
      enterpriseUID: rowData?.enterpriseUID,
      modelUID: rowData?.modelUID,
      baselevelUID: rowData?.baselevelUID?._id,
      measureUID: rowData?._id,
      measureName: rowData?.measureName,
      ids: [deletedRowData],
    };
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(userId)?.token}`,
      },
      data: body,
    };
    try {
      const response = await axios.delete(baseURL, config);
      const payload = {
        measureName: rowData?.measureName,
        measureUID: rowData?._id,
        baselevelUID: rowData?.baselevelUID?._id,
        enterpriseUID: rowData?.enterpriseUID,
        modelUID: rowData?.modelUID,
        page: page,
        limit: rowsPerPage
      }
      if (response.data.data) {
        // toast.success("Deleted successfully");
        axios
          .post(`${API_BASE_URL}/measure/dataload/view`, payload, config)
          .then((response) => {
            setTotalPage(response?.data?.pagination?.total)
            const data = response.data.data || [];
            const apiDataArray = Object.entries(response?.data?.schemaInfo).map(
              ([name, type]) => ({ name, type })
            );
            setApiData(apiDataArray);
            setTableData(data);
          });
        setOpenAddModel(false);
      }
    } catch (error) { }
    setActionMenu(false);
  };

  const handleEditRowMenu = async (editedRowdata, index) => {
    if (selectIndex === index) {

      setOpenEditModel(true);

      const baseURL = `${API_BASE_URL}/measure/dataload/view`;
      const userId = localStorage.getItem("accessToken");
      const body = {
        measureName: rowData?.measureName,
        measureUID: rowData?._id,
        baselevelUID: rowData?.baselevelUID?._id,
        enterpriseUID: rowData?.enterpriseUID,
        modelUID: rowData?.modelUID,
        ids: editedRowdata?._id,
        page: viewpage?.pageIndex + 1,
        limit: viewpage?.pageSize
        // documentsArray: [formData],
      };
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(userId)?.token}`,
        },
      };
      try {
        const response = await axios.post(`${baseURL}`, body, config);
        const data = response.data.data || [];
        const apiDataArray = Object.entries(response?.data?.schemaInfo).map(
          ([name, type]) => ({ name, type })
        );
        setSingleData(response.data?.data);
        setApiData(apiDataArray);
        setTableData(data);
        setTotalPage(response?.data?.pagination?.total)
      } catch (error) {
        console.log(error);
      }
    }

  };

  const handleAddData = async () => {
    const baseURL = `${API_BASE_URL}/measure/dataload/upload`;
    const userId = localStorage.getItem("accessToken");

    const body = {
      enterpriseUID: rowData?.enterpriseUID,
      modelUID: rowData?.modelUID,
      measureName: rowData?.measureName,
      measureUID: rowData?._id,
      baselevelUID: rowData?.baselevelUID?._id,
      clearAll: false,
      documentsArray: [{ ...formData, "Scenario Name": sceneList?.[0]?.scenarioName }],
    };
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(userId)?.token}`,
      },
    };
    try {
      const response = await axios.post(`${baseURL}`, body, config);
      setApiData({});

      const payload = {
        measureName: rowData?.measureName,
        measureUID: rowData?._id,
        baselevelUID: rowData?.baselevelUID?._id,
        enterpriseUID: rowData?.enterpriseUID,
        modelUID: rowData?.modelUID,
        page: page,
        limit: rowsPerPage
      }
      if (response.data.data?.erroredItems?.length > 0) {

        // toast.success("Created successfully");
        setOpenAddModel(false);
        axios
          .post(`${API_BASE_URL}/measure/dataload/view`, payload, config)
          .then((response) => {
            setTotalPage(response?.data?.pagination?.total)
            setTotalPage(response?.data?.pagination?.total)
            const data = response.data.data || [];
            const apiDataArray = Object.entries(response?.data?.schemaInfo).map(
              ([name, type]) => ({ name, type })
            );
            toggleRowExpansion(rowData)
            setApiData(...apiDataArray, apiDataArray);
            setTableData(...data, data);
          });
      } else {
        setOpenAddModel(false);
        axios
          .post(`${API_BASE_URL}/measure/dataload/view`, payload, config)
          .then((response) => {
            setTotalPage(response?.data?.pagination?.total)
            const data = response.data.data || [];
            const apiDataArray = Object.entries(response?.data?.schemaInfo).map(
              ([name, type]) => ({ name, type })
            );
            setApiData(...apiDataArray, apiDataArray);
            setTableData(...data, data);
          });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  };
  const handleEditModal = async () => {
    const baseURL = `${API_BASE_URL}/measure/dataload/update`;
    const userId = localStorage.getItem("accessToken");
    const body = {
      measureName: rowData?.measureName,
      measureUID: rowData?._id,
      baselevelUID: rowData?.baselevelUID?._id,
      enterpriseUID: rowData?.enterpriseUID,
      modelUID: rowData?.modelUID,
      documentsArray: storeSingleData,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(userId)?.token}`,
      },
    };
    const payload = {
      measureName: rowData?.measureName,
      measureUID: rowData?._id,
      baselevelUID: rowData?.baselevelUID?._id,
      enterpriseUID: rowData?.enterpriseUID,
      modelUID: rowData?.modelUID,
      page: page,
      limit: rowsPerPage
    }
    try {
      const response = await axios.put(`${baseURL}`, body, config);
      // toast.success("Updated Successfully!");
      setOpenEditModel(false);

      axios
        .post(`${API_BASE_URL}/measure/dataload/view`, payload, config)
        .then((response) => {
          setTotalPage(response?.data?.pagination?.total)
          const data = response.data.data || [];
          const apiDataArray = Object.entries(response?.data?.schemaInfo).map(
            ([name, type]) => ({ name, type })
          );
          setOpenEditModel(false);
          setApiData(apiDataArray);
          setTableData(data);
        });
    } catch (error) {
      toast?.error(error?.response?.data?.data)
    }
  };

  const getScenarioData = async () => {
    const baseURL = `${API_BASE_URL}/dataload/scenarioList`;
    const userId = localStorage.getItem("accessToken");
    const body = {
      enterpriseName: rowData?.enterpriseName,
      modelName: rowData?.modelName,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(userId)?.token}`,
      },
    };
    try {
      const response = await axios.post(`${baseURL}`, body, config);
      setSceneList(response.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getScenarioData();
  }, [
    rowData?.enterpriseName,
    rowData?.modelName,
  ]);

  const handleButtonAction = (event, index) => {
    setSelectIndex(index);
    setActionMenu(event.currentTarget);
  };
  if (!rowData) return null;




  return (
    <>
      <Dialog
        open={expandedRowData}
        onClose={() => {
          setExpandedRowData(null)
        }}
        maxWidth={"xl"}
      >
        <DialogTitle sx={{ fontSize: "12px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography>
            {rowData?.enterpriseName} &nbsp; &nbsp; &nbsp; &nbsp; {rowData?.modelName} &nbsp; &nbsp; &nbsp; &nbsp;  ({rowData?.type})
          </Typography>
          <Box>
            <CloseIcon
              onClick={() => {
                setExpandedRowData(null)
              }}
              sx={{
                cursor: "pointer",
                fontSize: "20px"
              }}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <MeasureViewData
            reorderedFields={reorderedFields}
            tableData={tableData}
            viewpage={viewpage}
            setViewPage={setViewPage}
            totalPage={totalPage}
            handleButtonAction={handleButtonAction}
            selectIndex={selectIndex}
            setSelectIndex={setSelectIndex}
            handleEditRowMenu={handleEditRowMenu}
            handleDeleteMenu={handleDeleteMenu}
            actionMenu={actionMenu}
          />
        </DialogContent>
      </Dialog>
      <Modal
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={openAddModel || openEditModel}
        onClose={() => {
          setOpenAddModel(false);
          setOpenEditModel(false);
          setSingleData({});
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            width: "400px",
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          {reorderedFields?.length > 0 &&
            reorderedFields?.map((field, index) => (

              <Box key={index} marginBottom={2}>
                {(field.name === "Scenario Name")
                  // || (field.name === "Parent Scenario") 
                  ? (
                    <FormControl fullWidth>
                      <InputLabel>{field.name}</InputLabel>
                      <Select
                        id={field.name}
                        label={field.name}
                        name={field.name}
                        value={formData[field.name] || (sceneList.length > 0 ? sceneList[0]?.scenarioName : "")}
                        onChange={(e) => {
                          if (openEditModel) {
                            setSingleData({
                              ...storeSingleData,
                              [field.name]: e.target.value,
                            });
                          } else {
                            handleChange(field.name, e.target.value);
                          }
                        }}
                        fullWidth
                        variant="outlined"
                      >
                        {sceneList.map((data, index) => (
                          <MenuItem key={index} value={data?.scenarioName} selected>
                            {data?.scenarioName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <>
                      {field.name !== "Time Period Name" && (
                        <TextField
                          fullWidth
                          id={field.name}
                          label={field.name}
                          name={field.name}
                          value={
                            openEditModel
                              ? storeSingleData[0]?.[field.name] || "" // Accessing data from storeSingleData
                              : formData?.[field.name] || ""
                          }
                          onChange={(e) => {
                            if (openEditModel) {
                              // setSingleData({
                              //   ...storeSingleData[0], // Accessing the first object in the array
                              //   [field.name]: e.target.value,
                              // });
                              const updatedData = { ...storeSingleData[0], [field.name]: e.target.value };
                              setSingleData([updatedData, ...storeSingleData.slice(1)]);
                            } else {
                              handleChange(field.name, e.target.value);
                            }
                          }}
                          variant="outlined"
                        />
                      )}
                      {field.name === "Time Period Name" && (
                        <>
                          <TextField
                            fullWidth
                            type="date"
                            id="measureDate"
                            label="Measure Date"
                            name="measureDate"
                            value={
                              openEditModel
                                ? storeSingleData[0]?.["Measure Date"] || "" // Accessing Measure Date
                                : formData?.["Measure Date"] || ""
                            }
                            onChange={(e) => {
                              if (openEditModel) {
                                const updatedData = { ...storeSingleData[0], "Measure Date": e.target.value };
                                setSingleData([updatedData, ...storeSingleData.slice(1)]);
                              } else {
                                handleChange("Measure Date", e.target.value);
                              }
                            }}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </>
                      )}
                    </>
                  )}
              </Box>

            ))}

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
            }}
          >
            <Button
              sx={{ marginRight: "10px" }}
              variant="contained"
              color="primary"
              onClick={openEditModel ? handleEditModal : handleAddData}
            >
              {openEditModel ? "Save" : "Add"}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setOpenAddModel(false);
                setOpenEditModel(false);
                setSingleData({});
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )

}

export default MeasureMaterialList
