// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Apply 12px font size to all form elements */
input,
textarea,
select,
label,
.MuiFormLabel-root,
.MuiAutocomplete-input,
.MuiAutocomplete-option,
.MuiInputBase-root,
.MuiTableCell-root,
.MuiTableHead-root,
.MuiMenuItem-root,
.MuiTypography-root,
.MuiDialogTitle-root,
.MuiTab-root {
  font-size: 10px !important;
  font-family: 'Poppins', sans-serif !important;
}

/* Apply 12px font size to field headings (TextField label) */
.MuiInputLabel-root {
  font-size: 12px !important;
  font-family: 'Poppins', sans-serif !important;
}

/* Apply 12px font size to the field text in TextField */
.MuiOutlinedInput-input {
  font-size: 10px !important;
  font-family: 'Poppins', sans-serif !important;
}

/* Apply 12px font size to button elements if any */
button {
  font-size: 12px !important;
  font-family: 'Poppins', sans-serif !important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA,8CAA8C;AAC9C;;;;;;;;;;;;;;EAcE,0BAA0B;EAC1B,6CAA6C;AAC/C;;AAEA,6DAA6D;AAC7D;EACE,0BAA0B;EAC1B,6CAA6C;AAC/C;;AAEA,wDAAwD;AACxD;EACE,0BAA0B;EAC1B,6CAA6C;AAC/C;;AAEA,mDAAmD;AACnD;EACE,0BAA0B;EAC1B,6CAA6C;AAC/C","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');\n\n* {\n  margin: 0;\n  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n/* Apply 12px font size to all form elements */\ninput,\ntextarea,\nselect,\nlabel,\n.MuiFormLabel-root,\n.MuiAutocomplete-input,\n.MuiAutocomplete-option,\n.MuiInputBase-root,\n.MuiTableCell-root,\n.MuiTableHead-root,\n.MuiMenuItem-root,\n.MuiTypography-root,\n.MuiDialogTitle-root,\n.MuiTab-root {\n  font-size: 10px !important;\n  font-family: 'Poppins', sans-serif !important;\n}\n\n/* Apply 12px font size to field headings (TextField label) */\n.MuiInputLabel-root {\n  font-size: 12px !important;\n  font-family: 'Poppins', sans-serif !important;\n}\n\n/* Apply 12px font size to the field text in TextField */\n.MuiOutlinedInput-input {\n  font-size: 10px !important;\n  font-family: 'Poppins', sans-serif !important;\n}\n\n/* Apply 12px font size to button elements if any */\nbutton {\n  font-size: 12px !important;\n  font-family: 'Poppins', sans-serif !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
