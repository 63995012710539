import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Autocomplete, Box, Container, FormControl, Grid, Tab, TextField, Typography } from '@mui/material';
import React, { useCallback, useContext, useEffect } from 'react'
import { useState } from 'react';
import EntermodelList from '../entermodel/entermodelList';
import AttributeList from '../attributes/AttributeList';
import { ThemeContext } from '../../ThemeContext';
import MiniDrawer from '../common/drawer/DrawerToggle';
import MeasureList from '../measure/measureList';
import ProfileDetail from '../profile/ProfileDetail';
import DimensionList from '../dimensions/DimensionList';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { API_BASE_URL } from '../../helper/constants';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const Modeltab = ({ setViewMore, updatedId }) => {
  const { isDarkTheme } = useContext(ThemeContext);
  const { modalData } = useSelector((state) => state?.modelList)
  const { enterpriseData } = useSelector((state) => state?.enterprise)
  const [modelTabValue, setModelTabValue] = useState({})
  const [modalName, setModalName] = useState([])
  const [value, setValue] = useState('3');
  let editRow = modalData?.find((item) => item.id === updatedId);

  useEffect(() => {
    if (editRow) {
      setModelTabValue({
        enterpriseName: editRow?.enterpriseUID?.enterpriseName,
        enterpriseUID: editRow?.enterpriseUID?._id,
        modelName: editRow?.modelName,
        modelUID: editRow?.modalUID
      })
    }
  }, [editRow])
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const getModelList = useCallback(async () => {
    try {
      const userId = localStorage.getItem("accessToken");
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(userId)?.token}`,
        },
      };

      const response = await axios.get(`${API_BASE_URL}/all/getAllModel`, config);
      const filteredData = response.data?.enterpriseList?.filter(
        (item) => item.enterpriseUID?._id === modelTabValue.enterpriseUID
      );
      setModalName(filteredData);
    } catch (error) {
      console.error("Error fetching model list:", error);
    }
  }, [modelTabValue.enterpriseUID]);

  useEffect(() => {
    if (modelTabValue.enterpriseName) {
      getModelList();
    }
  }, [modelTabValue.enterpriseName, getModelList]);

  const handleChange = useCallback((newValue, fieldName) => {
    if (fieldName === "enterpriseName") {
      const selectedData = enterpriseData?.find((data) => data?._id === newValue?._id);
      setModelTabValue((prev) => ({
        ...prev,
        [fieldName]: selectedData?.enterpriseName,
        enterpriseUID: newValue?._id,
      }));
    } else if (fieldName === 'modelName') {
      const selectedData = modalName?.find((data) => data?.modalUID === newValue?.modalUID);
      setModelTabValue((prev) => ({
        ...prev,
        [fieldName]: selectedData?.modelName,
        modelUID: newValue?.modalUID,
      }));
    }
  }, [enterpriseData, modalName]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <MiniDrawer />
        <Box component="main" sx={{
          flexGrow: 1, padding: "87px 24px", overflow: 'auto', height: "100vh",
          background: "white",
        }}>
          <div>
            <Box onClick={() => {
              setViewMore(false)
            }}
              sx={{ color: 'black', cursor: "pointer", paddingBottom: "15px", display: "flex", alignItems: "center", gap: "10px" }}
            >
              <ChevronLeftIcon />
              <Typography>Back To Model List</Typography>
            </Box>
          </div>
          <Box sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: "10px",
            paddingBottom: "20px"
          }}>
            <FormControl fullWidth>
              <Autocomplete
                id="demo-simple-select-readonly"
                labelId="Enterprise Name"
                name="enterpriseName"
                options={enterpriseData}
                getOptionLabel={(option) => option.enterpriseName || ""}
                value={modelTabValue?.enterpriseName ?
                  enterpriseData.find(option => option.enterpriseName === modelTabValue.enterpriseName)
                  || null : null}
                onChange={(e, newValue) => {
                  handleChange(newValue, "enterpriseName", e);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Enterprise Name"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth>
              <Autocomplete
                id="demo-simple-select-readonly"
                labelId="Model Name"
                name="modelName"
                options={modalName}
                getOptionLabel={(option) => option.modelName || ""}
                value={modelTabValue?.modelUID ?
                  modalName.find(option => option.modalUID === modelTabValue.modelUID)
                  || null : null}
                onChange={(e, newValue) => {
                  handleChange(newValue, "modelName", e);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Model Name"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Box>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                <Tab label="Attributes" value="1" sx={{ textTransform: "none" }} />
                <Tab label="Dimensions" value="2" sx={{ textTransform: "none" }} />
                <Tab label="Measures" value="3" sx={{ textTransform: "none" }} />
              </TabList>
            </Box>
            <TabPanel value="1">
              <AttributeList modelEnterprise={modelTabValue} />
            </TabPanel>
            <TabPanel value="2">
              <DimensionList modelEnterprise={modelTabValue} />
            </TabPanel>
            <TabPanel value="3">
              <MeasureList modelEnterprise={modelTabValue} />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </>
  )
}

export default Modeltab
