import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import MiniDrawer from '../common/drawer/DrawerToggle'
import CreateProfile from './CreateProfile'
import ProfileMaterialList from './ProfileMaterialList'
import DeleteProfile from './DeleteProfile'
import { ThemeContext } from '../../ThemeContext'
import { useSelector } from 'react-redux'
import { createProfile, getProfileData } from '../../store/ApiSlice/profileSlice'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

const ProfileDetail = () => {
    const { isDarkTheme } = useContext(ThemeContext);
    const [isCreate, setIsCreate] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isView, setIsView] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [updatedId, setUpdatedId] = useState(null)
    const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
    const [copyForecastProfile, setCopyForecastProfile] = useState({})
    const [openCopyProfileModel, setCopyProfileModel] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getProfileData())
    }, [])
    
    const handleCreateUpdate = (edit, id) => {
        setUpdatedId(id);
        setIsCreate(true)
        setIsEdit(edit);
    }
    const handleTableRowSelect = (event, row) => {
        let id = event.target.value;
        let updatedIds = selectedRows.includes(row?.original?._id)
            ? selectedRows.filter((n) => n !== row?.original?._id)
            : [...selectedRows, row?.original?._id];
        setSelectedRows(updatedIds);
    };

    const validation = () => {
        if (copyForecastProfile?.name === "" || copyForecastProfile?.name === undefined) {
            toast.error("Please enter profile name");
            return true;
        }
        return false;
    }

    const handleSubmit = async () => {
        if (validation()) {
            return;
        } else {
            const payload = {
                name: copyForecastProfile?.name,
                enterpriseUID: copyForecastProfile?.enterpriseUID?._id,
                modelUID: copyForecastProfile?.modelUID?._id,
                outlierCorrectionEngine: copyForecastProfile?.outlierCorrectionEngine || false,
                statisticalForecastingEngine: copyForecastProfile?.statisticalForecastingEngine || false,
                hierarchicyAggregationEngine: copyForecastProfile?.hierarchicyAggregationEngine || false,
                timeAggregationEngine: copyForecastProfile?.timeAggregationEngine || false,
                causalForecastingEngine: copyForecastProfile?.causalForecastingEngine || false,
                valueOptimizationEngine: copyForecastProfile?.valueOptimizationEngine || false,
                aiFusionEngine: copyForecastProfile?.aiFusionEngine || false,
                forecastabilityEngine: copyForecastProfile?.forecastabilityEngine || false,
                periodicity: copyForecastProfile?.periodicity,
                frequency: copyForecastProfile?.frequency,
                historyHorizon: copyForecastProfile?.historyHorizon,
                forecastHorizon: copyForecastProfile?.forecastHorizon,
                validationHorizon: copyForecastProfile?.validationHorizon,
                forecastBaseLevel: copyForecastProfile?.forecastBaseLevel?._id,
                historyBaseLevel: copyForecastProfile?.historyBaseLevel?._id,
                baseHistoryMeasureUID: copyForecastProfile?.baseHistoryMeasureUID?._id,
                correctedHistoryMeasureUID: copyForecastProfile?.correctedHistoryMeasureUID?._id,
                naiveForecastMeasureUID: copyForecastProfile?.naiveForecastMeasureUID?._id,
                ...(copyForecastProfile?.hierarchicyAggregationEngine && copyForecastProfile?.hierarchyAggregationEngineAttributes?.length > 0 && ({
                    hierarchyAggregationEngineAttributes: copyForecastProfile?.hierarchyAggregationEngineAttributes?.map((item) => item?._id)
                })),
                ...(copyForecastProfile?.timeAggregationEngine && copyForecastProfile?.timeAggregationEnginePeriods?.length > 0 && ({
                    timeAggregationEnginePeriods: copyForecastProfile?.timeAggregationEnginePeriods
                })),
                basicKPIs: {
                    ...copyForecastProfile?.basicKPIs,
                    error: {
                        ...copyForecastProfile?.basicKPIs?.error,
                        defaultValue: Number(copyForecastProfile?.basicKPIs?.error?.defaultValue)
                    },
                    bias: {
                        ...copyForecastProfile?.basicKPIs?.bias,
                        defaultValue: Number(copyForecastProfile?.basicKPIs?.bias?.defaultValue)
                    },
                    churn: {
                        ...copyForecastProfile?.basicKPIs?.churn,
                        defaultValue: Number(copyForecastProfile?.basicKPIs?.churn?.defaultValue)
                    }
                },
                ...((copyForecastProfile?.statisticalForecastEngineBasicSettings?.probabilisticStatisticalForecastingEngine || copyForecastProfile?.causalForecastBasicSettings?.probabilisticForecstingEngine) && ({
                    probabilisticForecastSettings: copyForecastProfile?.probabilisticForecastSettings
                })),
                statisticalForecastEngineBasicSettings: copyForecastProfile?.statisticalForecastEngineBasicSettings,
                ...(copyForecastProfile?.probabilisticStatisticalForecastingEngine && ({
                    statisticalForecastEngineProbabilisticSettings: copyForecastProfile?.statisticalForecastEngineProbabilisticSettings
                })),
                statisticalForecastEngineModels: copyForecastProfile?.statisticalForecastEngineModels,
                ...(copyForecastProfile?.statisticalForecastingEngine && ({
                    statisticalForecastEngineKPIs: copyForecastProfile?.statisticalForecastEngineKPIs
                })),
                ...(copyForecastProfile?.causalForecastingEngine && ({
                    causalForecastBasicSettings: copyForecastProfile?.causalForecastBasicSettings
                })),
                ...(copyForecastProfile?.causalForecastOutcomes?.length > 0 && ({
                    causalForecastOutcomes: copyForecastProfile?.causalForecastOutcomes
                })),
                ...(copyForecastProfile?.causalForecastDrivers?.length > 0 && ({
                    causalForecastDrivers: copyForecastProfile?.causalForecastDrivers
                })),
                causalForecastBasicKPIs: copyForecastProfile?.causalForecastBasicKPIs,
                ...(copyForecastProfile?.causalForecastEngineForecastModels?.length > 0 && ({
                    causalForecastEngineForecastModels: copyForecastProfile?.causalForecastEngineForecastModels
                })),
                ...(copyForecastProfile?.valueOptimizationEngine && ({
                    valueOptimizationEngineBasicSettings: copyForecastProfile?.valueOptimizationEngineBasicSettings,
                    ...(copyForecastProfile?.valueOptimizationEngineObjectives?.length > 0 && ({
                        valueOptimizationEngineObjectives: copyForecastProfile?.valueOptimizationEngineObjectives
                    })),
                    ...(copyForecastProfile?.valueOptimizationEngineConstraints?.length > 0 && ({
                        valueOptimizationEngineConstraints: copyForecastProfile?.valueOptimizationEngineConstraints
                    })),
                    ...(copyForecastProfile?.valueOptimizationEngineDecisions?.length > 0 && ({
                        valueOptimizationEngineDecisions: copyForecastProfile?.valueOptimizationEngineDecisions
                    })),
                    valueOptimizationEngineBasicKPIs: copyForecastProfile?.valueOptimizationEngineBasicKPIs
                })),
                ...(copyForecastProfile?.aiFusionEngine && ({
                    aiFusionEngineBasicSettings: copyForecastProfile?.aiFusionEngineBasicSettings,
                    aiFusionEngineBasicKPIs: copyForecastProfile?.aiFusionEngineBasicKPIs,
                    ...(copyForecastProfile?.aiFusionEngineHumanForecasts?.length > 0 && ({
                        aiFusionEngineHumanForecasts: copyForecastProfile?.aiFusionEngineHumanForecasts
                    }))
                })),
                causalForecastAdditionalKPIs: copyForecastProfile?.causalForecastAdditionalKPIs?.map((item) => ({
                    kpiMeasureUID: item?.kpiMeasureUID,
                    predictiveKPIName: item?.predictiveKPIName
                })),
                aggregatedLevels: copyForecastProfile?.aggregatedLevels?.length !== 0 ? copyForecastProfile?.aggregatedLevels : undefined,
                probabilisticCausalForecastLevels: copyForecastProfile?.probabilisticCausalForecastLevels?.length !== 0 ? copyForecastProfile?.probabilisticCausalForecastLevels : undefined,
            };

            const result = await dispatch(
                createProfile({ payload, isEdit: false, selectedRows })
            );
            if (result?.meta?.requestStatus === "fulfilled") {
                dispatch(getProfileData());
                setCopyProfileModel(false);
            } else if (result?.meta?.requestStatus === "rejected") {
                toast.error(result?.error?.message);
            }
        }
    };

    return (
        <>
            {!isCreate && !isView && (
                <>
                    <Box sx={{ display: "flex" }}>
                        <MiniDrawer />
                        <Box component="main" sx={{
                            flexGrow: 1, p: 3, overflow: "auto", height: "100vh",
                            background: isDarkTheme ? "whitesmoke" : "white",
                        }}>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "60px",
                                paddingBottom: "20px",
                                position: "relative"
                            }}>
                                <h3 className="heading">Forecast Profiles</h3>
                                <div>
                                    <Tooltip title="Add Profile">
                                        <Button
                                            onClick={() => {
                                                handleCreateUpdate(false)
                                            }}
                                            className="awesome-button"
                                            sx={{ color: "white", margin: "0 4px", fontSize: "1.875rem !important" }}
                                        >
                                            +
                                        </Button>
                                    </Tooltip>
                                    <DeleteProfile
                                        selectedRows={selectedRows}
                                        setSelectedRows={setSelectedRows}
                                        setModalDeleteOpen={setModalDeleteOpen}
                                        isModalDeleteOpen={isModalDeleteOpen}
                                        updatedId={updatedId}
                                    />
                                </div>
                            </div>
                            <ProfileMaterialList
                                handleTableRowSelect={handleTableRowSelect}
                                setUpdatedId={setUpdatedId}
                                handleCreateUpdate={handleCreateUpdate}
                                setModalDeleteOpen={setModalDeleteOpen}
                                setCopyForecastProfile={setCopyForecastProfile}
                                setCopyProfileModel={setCopyProfileModel}
                            />
                        </Box>
                    </Box>
                </>
            )}
            {(isCreate || isView) && (
                <CreateProfile
                    isCreate={isCreate}
                    setIsCreate={setIsCreate}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    isView={isView}
                    setIsView={setIsView}
                    selectedRows={updatedId}
                />
            )}
            {openCopyProfileModel && (
                <Dialog open={openCopyProfileModel} onClose={() => setCopyProfileModel(false)}>
                    <DialogTitle>Copy Forecast Profile</DialogTitle>
                    <DialogContent style={{ width: "500px", margin: "2px" }}>
                        <TextField
                            id="name"
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Profile Name"
                            value={copyForecastProfile?.name}
                            onChange={(e) => {
                                setCopyForecastProfile((prev) => ({ ...prev, name: e.target.value }))
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder="Enter profile name"
                            style={{ marginTop: "10px" }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setCopyProfileModel(false)}>Cancel</Button>
                        <Button color="primary" onClick={handleSubmit}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}

export default ProfileDetail
