import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { API_BASE_URL } from '../../helper/constants';
import { AppBar, Autocomplete, Box, Button, Container, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, TextField, Toolbar, Typography, Checkbox } from '@mui/material';
import { Select as MuiSelect } from '@mui/material';
import { useDispatch } from 'react-redux';
import { createProcess, getExcuteProcess, getProcessList, getScenarios } from '../../store/ApiSlice/processSlice';
import { toast } from 'react-toastify';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { getProfileData } from '../../store/ApiSlice/profileSlice';
import { getFilterDetail } from '../../store/ApiSlice/canvasSlice';
import { getMeasureProfileList } from '../../store/ApiSlice/measuredataslice';
import { getAllParentScenarioList, getAllScenarioList } from '../../store/ApiSlice/scenarioListSlice';
import { getEnterpriseList } from '../../store/ApiSlice/enterpriseSlice';

const CreateProcess = ({
    isCreate,
    setIsCreate,
    isEdit,
    setIsEdit,
    isView,
    setIsView,
    selectedRows,
    props
}) => {
    const { enterpriseData } = useSelector((state) => state?.enterprise)
    const { filterDetail } = useSelector((state) => state.canvas)
    const { profileData } = useSelector((state) => state.profile)
    const { measureProfileDataList } = useSelector((state) => state.measureprofile)
    const { processData, scenarioList } = useSelector((state) => state.process)
    const { parentScenarioData, scenarioData } = useSelector((state) => state?.scenario);

    console.log(parentScenarioData,scenarioData,"hshsh")
    const [stepValues, setStepValues] = useState([
        {
            stepType: 'System Based',
            stepName: '',
            operatorType: '',
            profile: '',
            modelUID: '',
            filter: '',
            scenario: 'Baseline',
            measureProfile: "",
            processStepIndex: 'Step 1'
        }
    ]);

    const [processFormValue, setProcessFormValue] = useState({})
    const [modalName, setModalName] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
    const [stepIndex, setStepIndex] = useState(null)
    const [draggedIndex, setDraggedIndex] = useState(null);
    const dispatch = useDispatch();
    const stepModel = Boolean(anchorEl);
    const selectedProcessDetail = processData?.find((item) => item?._id == selectedRows)

    useEffect(() => {
        dispatch(getProfileData())
        dispatch(getFilterDetail())
        dispatch(getMeasureProfileList())
        dispatch(getEnterpriseList())
    }, [])

    useEffect(() => {
        if (selectedProcessDetail && isEdit) {
            setProcessFormValue({
                processname: selectedProcessDetail?.name,
                enterpriseName: selectedProcessDetail?.enterpriseUID?.enterpriseName,
                enterpriseUID: selectedProcessDetail?.enterpriseUID?._id,
                modelName: selectedProcessDetail?.modelUID?.modelName,
                modelUID: selectedProcessDetail?.modelUID?.modalUID,
                processNotes: selectedProcessDetail?.notes
            })
            setStepValues(selectedProcessDetail?.steps?.map((item) => ({
                stepType: item?.stepType,
                stepName: item?.stepName,
                operatorType: item?.operatorType,
                profile: item?.profile?._id && item?.profile?._id,
                modelUID: item?.modelUID?._id,
                filter: item?.filter?._id,
                scenario: item?.scenario,
                measureProfile: item?.measureProfile,
                processStepIndex: 'Step 1'
            })))
        }
    }, [selectedProcessDetail, isEdit, selectedRows, processData, selectedProcessDetail])

    const handleStepChangeValue = (index, newValue, type, event) => {
        if (type == "modelUID") {
            const updatedFormValues = [...stepValues];
            updatedFormValues[index][type] = newValue?.modalUID;
            setStepValues(updatedFormValues);
        } else if (type == "filter") {
            const updatedFormValues = [...stepValues];
            updatedFormValues[index][type] = newValue?._id;
            setStepValues(updatedFormValues);
        } else if (type == "profile") {
            const updatedFormValues = [...stepValues];
            updatedFormValues[index][type] = newValue?._id;
            setStepValues(updatedFormValues);
        } else if (type == "measureProfile") {
            const updatedFormValues = [...stepValues];
            updatedFormValues[index][type] = newValue?._id;
            setStepValues(updatedFormValues);
        } else if (type == "scenario") {
            console.log(newValue,"hshshddd",event.target.value,"hshsh")
            const updatedFormValues = [...stepValues];
            updatedFormValues[index][type] = newValue || ""
            setStepValues(updatedFormValues);
        }
        else {
            const { name, value } = event.target;
            const updatedFormValues = [...stepValues];
            updatedFormValues[index][name] = value;
            console.log(updatedFormValues,"hshshddd")
            setStepValues(updatedFormValues);
        }
    };


    const handleAddField = () => {
        setStepValues([...stepValues, { stepType: 'System Based', stepName: '', operatorType: '', profile: '', modelName: '', filter: '', measureProfile: "", processStepIndex: `Step ${stepValues.length + 1}`, scenario: 'Baseline', }]);
        setAnchorEl(null)
    };

    const handleRemove = (e, index) => {
        const { value, checked } = e.target
        if (checked) {
            setStepIndex(index);
        } else {
            setStepIndex()
        }
    }
    const handleRemoveField = () => {
        const updatedFormValues = [...stepValues];
        updatedFormValues.splice(stepIndex, 1);
        setStepValues(updatedFormValues);
        setAnchorEl(null)
    };

    const handleCancel = () => {
        setIsCreate(false);
    };

    const handleChangeProcess = (newValue, fieldName, e) => {
        if (fieldName === 'enterpriseName') {
            const selectedData = enterpriseData?.filter((data) => data?._id === newValue?._id);

            setProcessFormValue({
                ...processFormValue,
                [fieldName]: selectedData[0]?.enterpriseName,
                [`enterpriseUID`]: newValue?._id
            });
        } else if (fieldName === 'modelName') {
            const selectedData = modalName?.filter((data) => data?.modalUID === newValue?.modalUID);

            setProcessFormValue({
                ...processFormValue,
                [fieldName]: selectedData[0]?.modelName,
                [`modelUID`]: newValue?.modalUID
            });
        }
        else {
            const { name, value } = e.target;
            setProcessFormValue((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
        }
    }

    const getModelList = async () => {
        try {
            const userId = localStorage.getItem("accessToken");
            const config = {
                headers: {
                    Authorization: `Bearer ${JSON.parse(userId)?.token}`,
                },
            };

            let response = await axios.get(`${API_BASE_URL}/all/getAllModel`, config);
            let filteredData = isEdit
                ? response.data?.enterpriseList?.filter(
                    (item) => item.enterpriseUID?._id === processFormValue.enterpriseUID
                )
                : response.data?.enterpriseList;

            if (processFormValue.enterpriseName) {
                filteredData = filteredData?.filter(
                    (dime) => dime.enterpriseUID?._id === processFormValue.enterpriseUID
                );
            }
            setModalName(filteredData);
        } catch (error) {
            console.error("Error fetching model list:", error);
        }
    };
    useEffect(() => {
        if (processFormValue.enterpriseName) {
            getModelList();
        }
    }, [processFormValue.enterpriseName]);

    const validation = () => {
        if (processFormValue?.processname == '' || processFormValue?.processname == undefined) {
            toast.error("Please enter process name")
            return true;
        } else if (processFormValue?.enterpriseUID == '' || processFormValue?.enterpriseUID == undefined) {
            toast.error("Please select enterprise")
            return true;
        } else {
            const hasInvalidStepType = stepValues?.some(item => !item?.stepType || item?.stepType?.trim() === '');
            const hasInvalidStepName = stepValues?.some(item => !item?.stepName || item?.stepName?.trim() === '');
            const hasInvalidOperatorType = stepValues?.some(item => !item?.operatorType || item?.operatorType?.trim() === '');
            const hasInvalidProfile = stepValues?.some(item => {
                if (item?.operatorType?.trim() === 'AI Forecasting') {
                    if (!item?.profile || item?.profile?.trim() === '') {
                        return true;
                    }
                }
                return false;
            });
            const hasInvalidModelUID = stepValues?.some(item => !item?.modelUID || item?.modelUID?.trim() === '');
            const hasInvalidFilter = stepValues?.some(item => !item?.filter || item?.filter?.trim() === '');
            const hasInvalidScenario = stepValues?.some(item => !item?.scenario || item?.scenario?.trim() === '');
            if (hasInvalidStepType) {
                toast.error("Please select step type")
                return true;
            } else if (hasInvalidStepName) {
                toast.error("Please enter step name")
                return true;
            } else if (hasInvalidModelUID) {
                toast.error("Please select step model")
                return true;
            } else if (hasInvalidOperatorType) {
                toast.error("Please select step operator type")
                return true;
            } else if (hasInvalidScenario) {
                toast.error("Please select scenario")
                return true;
            } else if (hasInvalidProfile) {
                toast.error("Please select step profile")
                return true;
            }
        }
        return false;
    }

    const handleSubmit = async () => {
        if (validation()) {
            return;
        } else {
            const payload = {
                name: processFormValue?.processname,
                notes: processFormValue?.processNotes,
                enterpriseUID: processFormValue?.enterpriseUID,
                steps: stepValues?.map((item, index) => ({
                    stepType: item?.stepType,
                    stepName: item?.stepName,
                    stepOrder: index + 1,
                    operatorType: item?.operatorType,
                    ...(item?.profile && ({
                        profile: item?.profile ? item?.profile : "",
                    })),
                    modelUID: item?.modelUID,
                    ...(item?.filter && ({
                        filter: item?.filter ? item?.filter : "",
                    })),
                    ...(item?.measureProfile && item?.operatorType === "Measure Data" && ({
                        measureProfile: item?.measureProfile || "",
                    })),
                    scenario: item?.scenario,
                }))
            }
            const result = await dispatch(createProcess({ payload, isEdit, selectedRows }))
            if (result?.meta?.requestStatus == 'fulfilled') {
                // toast.success(isEdit ? "Process updated successfully" : "Process created successfully")
                dispatch(getProcessList())
                setProcessFormValue({});
                setIsCreate(false);
            } else if (result?.meta?.requestStatus === 'rejected') {
                toast.error(result?.error?.message)
                setIsCreate(true)
            }
        }
    }

    const handleCloseButton = (item) => {
        setAnchorEl(null);
    };
    const handleClickButton = (event) => {
        setAnchorEl(event.currentTarget);
    };



    const data = stepValues?.find((data) => {
        return data?.modelUID
    })

    useEffect(() => {
        const modelData = modalName?.find((item) => item?.modalUID === data?.modelUID)
        const body = {
            enterpriseName: processFormValue?.enterpriseName,
            modelName: modelData?.modelName,
        };
        const formValues={
            enterpriseUID: modelData?.enterpriseUID?._id,
            modelUID:modelData?.modelUID
        }
        if (processFormValue?.enterpriseName !== "" && processFormValue?.enterpriseName && modelData?.modelName !== undefined && data) {
            dispatch(getAllParentScenarioList({ formValues }))
        }
    }, [processFormValue, data, modalName, dispatch, stepValues])

    const handleDragStart = (index) => {
        setDraggedIndex(index);
    };

    const handleDragOver = (index, event) => {
        event.preventDefault(); // Necessary to allow dropping
    };

    const handleDrop = (index) => {
        const updatedSteps = [...stepValues];
        const [removedStep] = updatedSteps.splice(draggedIndex, 1); // Remove the dragged step
        updatedSteps.splice(index, 0, removedStep); // Insert it at the drop position
        setStepValues(updatedSteps);
        setDraggedIndex(null); // Reset dragged index
    }
    return (
        <div>
            <AppBar position="static" sx={{ backgroundColor: "white" }}>
                <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="h6" sx={{ color: "black", fontFamily: "'Poppins', sans-serif" }}>
                        {/* {getPageHeading()} */}
                        Process
                    </Typography>
                </Toolbar>
            </AppBar>

            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "20px" }}>
                    <Grid item xs={6}>
                        <TextField
                            id="processname"
                            name="processname"
                            variant="outlined"
                            fullWidth
                            label="Process Name"
                            value={processFormValue?.processname}
                            onChange={(e, newValue) => {
                                handleChangeProcess(newValue, "processname", e);
                            }}
                            placeholder="Enter profile name"
                        // onBlur={validateOne}
                        // disabled={isView}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="demo-simple-select-readonly"
                                labelId="Enterprise Name"
                                name="enterpriseName"
                                options={enterpriseData}
                                getOptionLabel={(option) => option.enterpriseName}
                                value={processFormValue?.enterpriseName ?
                                    enterpriseData.find(option => option.enterpriseName === processFormValue.enterpriseName)
                                    || null : null}
                                onChange={(e, newValue) => {
                                    handleChangeProcess(newValue, "enterpriseName", e);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Enterprise Name"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />

            <Container>
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={stepModel ? 'long-menu' : undefined}
                        aria-expanded={stepModel ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={(e) => {
                            handleClickButton(e);
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={stepModel}
                        onClose={handleCloseButton}
                    >
                        <MenuItem>
                            <Button
                                id="demo-customized-button"
                                aria-controls={stepModel ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={stepModel ? 'true' : undefined}
                                // variant="contained"
                                disableElevation
                                disabled={stepValues?.length >= 3}
                                style={{ cursor: stepValues?.length >= 3 ? 'not-allowed' : 'pointer' }}
                                onClick={handleAddField}
                                sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}
                            >
                                Add
                            </Button>
                        </MenuItem>
                        <MenuItem>
                            <Button
                                id="demo-customized-button"
                                aria-controls={stepModel ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={stepModel ? 'true' : undefined}
                                // variant="contained"
                                disableElevation
                                disabled={stepIndex == null}
                                style={{ cursor: stepIndex == null ? 'not-allowed' : 'pointer' }}
                                onClick={handleRemoveField}
                                sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}
                            >
                                Remove
                            </Button>
                        </MenuItem>
                    </Menu>
                </div>

                <div style={{ display: "flex", flexWrap: "wrap", gap: "20px", padding: "15px 0 0" }}>
{console.log("stepValuesstepValues",stepValues)}
                    {stepValues?.map((data, index) => (
                        <div
                            key={index}
                            draggable
                            onDragStart={() => handleDragStart(index)}
                            onDragOver={(event) => handleDragOver(index, event)}
                            onDrop={() => handleDrop(index)}
                        >
                            {console.log(data,"datadata")}
                            <div style={{ border: "1px solid #000000", padding: "10px", borderRadius: "6px", width: '300px' }}>
                                <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "10px", paddingBottom: "15px" }}>
                                    <Checkbox onChange={(e) => handleRemove(e, index)} />
                                    <Typography sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>{data?.processStepIndex}</Typography>
                                </Box>

                                <FormControl fullWidth sx={{ paddingBottom: "15px" }}>
                                    <InputLabel id={`stepType${index}`}>Step Type</InputLabel>
                                    <MuiSelect
                                        labelId={`stepType${index}`}
                                        name='stepType'
                                        value={data.stepType}
                                        fullWidth
                                        onChange={(e, newValue) => {
                                            handleStepChangeValue(index, newValue, "stepType", e);
                                        }}
                                        label="Step Type"
                                        variant="outlined"
                                    >
                                        <MenuItem value="System Based">System Based</MenuItem>
                                        <MenuItem value="Manual">Manual</MenuItem>
                                    </MuiSelect>
                                </FormControl>

                                <FormControl fullWidth sx={{ paddingBottom: "15px" }}>
                                    <TextField
                                        name="stepName"
                                        variant="outlined"
                                        fullWidth
                                        label="Step Name"
                                        value={data.stepName}
                                        onChange={(e, newValue) => {
                                            handleStepChangeValue(index, newValue, "stepName", e);
                                        }}
                                        placeholder="Enter step name"
                                    />
                                </FormControl>

                                <FormControl fullWidth sx={{ paddingBottom: "15px" }}>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Model Name"
                                        name="modelUID"
                                        options={modalName}
                                        getOptionLabel={(option) => option.modelName}
                                        value={data?.modelUID ?
                                            modalName.find(option => option.modalUID === data.modelUID)
                                            || null : null}
                                        onChange={(e, newValue) => {
                                            handleStepChangeValue(index, newValue, "modelUID", e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Model Name"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </FormControl>

                                <FormControl fullWidth sx={{ paddingBottom: "15px" }}>
                                    <InputLabel id="operatorType">Operator Type</InputLabel>
                                    <MuiSelect
                                        labelId="operatorType"
                                        name='operatorType'
                                        id="demo-simple-select-readonly"
                                        value={data.operatorType}
                                        fullWidth
                                        onChange={(event, newValue) => handleStepChangeValue(index, newValue, "operatorType", event)}
                                        label="Operator Type"
                                        variant="outlined"
                                    >
                                        <MenuItem value="AI Forecasting">AI Forecasting</MenuItem>
                                        <MenuItem value="Override Value Prediction">Override Value Prediction</MenuItem>
                                        <MenuItem value="Demand Shaping Optimization">Demand Shaping Optimization</MenuItem>
                                        <MenuItem value="Inventory Optimization">Inventory Optimization</MenuItem>
                                        <MenuItem value="Measure Data">Measure Data</MenuItem>
                                        <MenuItem value="Supply Optimization">Supply Optimization</MenuItem>
                                    </MuiSelect>
                                </FormControl>

                                {data?.operatorType === "Measure Data" && (
                                    <FormControl fullWidth sx={{ paddingBottom: "15px" }}>
                                        <Autocomplete
                                            id="demo-simple-select-readonly"
                                            labelId="Measure Data Profile"
                                            name="measureProfile"
                                            options={measureProfileDataList}
                                            getOptionLabel={(option) => option.name}
                                            value={data?.measureProfile ?
                                                measureProfileDataList.find(option => option._id === data.measureProfile)
                                                || null : null}
                                            onChange={(e, newValue) => {
                                                handleStepChangeValue(index, newValue, "measureProfile", e);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Measure Data Profile"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </FormControl>
                                )}

                                {data?.operatorType == "AI Forecasting" && (
                                    <FormControl fullWidth sx={{ paddingBottom: "15px" }}>
                                        <Autocomplete
                                            id="demo-simple-select-readonly"
                                            labelId="Profile Name"
                                            name="profile"
                                            options={profileData}
                                            getOptionLabel={(option) => option.name}
                                            value={data?.profile ?
                                                profileData.find(option => option._id === data.profile)
                                                || null : null}
                                            onChange={(e, newValue) => {
                                                handleStepChangeValue(index, newValue, "profile", e);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Profile Name"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </FormControl>
                                )}

                                <FormControl fullWidth sx={{ paddingBottom: "15px" }}>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Filter"
                                        name="filter"
                                        options={filterDetail?.length > 0 ? filterDetail
                                            ?.filter((item) => item?.enterpriseUID?._id === processFormValue?.enterpriseUID)
                                            ?.filter((item) => item?.modelUID?.modelUID === data?.modelUID) : []}
                                        getOptionLabel={(option) => option.name}
                                        value={data?.filter ?
                                            filterDetail.find(option => option._id === data.filter)
                                            || null : null}
                                        onChange={(e, newValue) => {
                                            handleStepChangeValue(index, newValue, "filter", e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Filter Name"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </FormControl>

                                <FormControl fullWidth sx={{ paddingBottom: "15px" }}>
                                    <div>
                                        <Autocomplete
                                            // id="demo-simple-select-readonly"
                                            labelId="scenario"
                                            name="scenario"
                                            id="tags-standard"
                                            options={parentScenarioData}
                                            // getOptionLabel={(option) => option}
                                            value={data?.scenario ?
                                                parentScenarioData.find(option => option === data?.scenario)
                                                || null : null}
                                            onChange={(e, newValue) => {
                                                handleStepChangeValue(index, newValue, "scenario", e);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Scenario Name"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </div>
                                </FormControl>
                            </div>
                        </div>
                    ))}
                </div>
            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />

            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container style={{ marginTop: "20px" }}>
                    <Grid item xs={12}>
                        <TextField
                            id="processNotes"
                            name="processNotes"
                            variant="outlined"
                            fullWidth
                            label="Process Notes"
                            multiline
                            rows={6}
                            value={processFormValue.processNotes}
                            onChange={(e, newValue) => {
                                handleChangeProcess(newValue, "processNotes", e);
                            }}
                            placeholder="Enter process note"
                            disabled={isView}
                        />
                    </Grid>
                </Grid>
            </Container>

            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginRight: "30px",
                    marginTop: "20px",
                    marginBottom: "20px",
                }}
            >
                <Button
                    onClick={handleCancel}
                    title="Cancel"
                    style={{
                        padding: "6px 15px",
                        borderRadius: "2px",
                        cursor: "pointer",
                        marginRight: "10px",
                        fontFamily: "'Poppins', sans-serif"
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    title="Create new dimension with selected attributes"
                    style={{
                        padding: "7px 15px",
                        borderRadius: "2px",
                        cursor: "pointer",
                        fontFamily: "'Poppins', sans-serif"
                    }}
                >
                    Save
                </Button>
                <Button
                    onClick={() => {
                        dispatch(getExcuteProcess(selectedProcessDetail?._id))
                    }}
                    title="Create new dimension with selected attributes"
                    disabled={!isEdit && true}
                    style={{
                        padding: "7px 15px",
                        borderRadius: "2px",
                        cursor: "pointer",
                        fontFamily: "'Poppins', sans-serif"
                    }}
                >
                    Run Process
                </Button>
            </div>
        </div>
    )
}

export default CreateProcess
