import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getMeasureList, getMeasureListWithEnterprise } from '../../store/ApiSlice/measureSlice';
import { useSelector } from 'react-redux';
import { AppBar, Autocomplete, Box, Button, Container, Dialog, DialogContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Toolbar, Typography } from '@mui/material';
import { getAttributeOrEnterPrise, getAttributes } from '../../store/ApiSlice/attributeSlice';
import { getFilterDetail, getPrimaryTimeDimension, getSchemaData } from '../../store/ApiSlice/canvasSlice';
import { getProfilePrimaryTimeDimension } from '../../store/ApiSlice/profileSlice';
import { fetchDimensions, getModelListAsync } from '../../store/ApiSlice/dimensionSlice';
import moment from 'moment';
import { toast } from 'react-toastify';

const MeasureDataProfileMock = ({
    measureCreateProfile,
    setMeasureCreateProfile,
    measureEditProfile,
    setMeasureEditProfile,
    setIsCreate,
    setAnchorEl,
    formValues,
    setFormValues,
    dataProfileDetail,
    setDataProfileDetail,
    attributeLevelData,
    setAttributeLevelData,
    isDataProfileDetailUpdated,
    setIsDataProfileDetailUpdated,
    updatedId,
    isEdit
}) => {
    const { measureProfileDataList } = useSelector((state) => state.measureprofile)
    const { dimensionData } = useSelector((state) => state.dimension)
    const { measureData, measureList } = useSelector((state) => state.measure)
    const { filterDetail, timeDimensionData, schemaData } = useSelector((state) => state.canvas)
    const dispatch = useDispatch();
    const selectedProfile = measureProfileDataList?.find((item) => item?._id === updatedId)
    const enterPriseId = isEdit ? selectedProfile?.enterpriseUID?._id : formValues?.enterpriseUID
    const modalId = isEdit ? selectedProfile?.modelUID?.modalUID : formValues?.modelUID
    const dataobject = {
        enterpriseUID: selectedProfile?.enterpriseUID?._id,
        modelUID: selectedProfile?.modelUID?.modalUID
    }
    const enterpriseIdData = isEdit ? dataobject : formValues
    const dimensionLevelList = dimensionData?.length > 0 && dimensionData?.filter((item) => item?.type === "LEVEL")
    const canvasFindData = useMemo(() => ({
        enterpriseUID: {
            _id: enterPriseId
        },
        modelUID: {
            modalUID: modalId
        }
    }), [enterPriseId, modalId]);

    useEffect(() => {
        dispatch(getFilterDetail())
        dispatch(getModelListAsync())
    }, [dispatch])

    useEffect(() => {

        if (canvasFindData?.enterpriseUID?._id && canvasFindData?.modelUID?.modalUID) {
            dispatch(getPrimaryTimeDimension({ canvasFindData }))
        }
        if (formValues?.enterpriseUID && formValues?.modelUID) {
            dispatch(getMeasureListWithEnterprise({ formValues }))
        }
        if (enterPriseId && modalId) {
            dispatch(getAttributeOrEnterPrise({ enterPriseId, modalId }))
        }
    }, [enterPriseId, modalId, formValues, isEdit, canvasFindData, dispatch])



    useEffect(() => {
        if (isDataProfileDetailUpdated) {
            const primaryTimeDimension = formValues?.measure ?
                measureData?.find((data) => data?._id === formValues?.measure) :
                dimensionLevelList?.find((data) => data?._id === formValues?.level)
            setFormValues({
                ...formValues,
                _id: formValues?.measure ? primaryTimeDimension?.baselevelUID?.primaryTimeForLevel : primaryTimeDimension?.primaryTimeForLevel,
                timePeiodName: formValues?.measure ? (primaryTimeDimension && primaryTimeDimension?.baselevelUID["Period Type"]) : (primaryTimeDimension && primaryTimeDimension["Period Type"])
            })
        }

    }, [isDataProfileDetailUpdated])

    useEffect(() => {
        if (formValues?._id) {
            dispatch(getSchemaData({ timeDimensionData: formValues }))
        }
    }, [formValues?._id, formValues?.measure, formValues?.level])

    const handleAddField = () => {
        setAttributeLevelData([...attributeLevelData, { attributeValue: '' }]);
    };

    const handleChangeAttribute = (index, newValue, type, event) => {
        const updatedFormValues = [...attributeLevelData];
        updatedFormValues[index][type] = newValue?._id;
        setAttributeLevelData(updatedFormValues);
    }



    const timePeriodData = (formValues?.measure && measureList?.length > 0) && measureList?.find((item) => item?._id == formValues?.measure)
    const attributeData = (formValues?.sourceMeasure && measureList?.length > 0) && measureList?.find((item) => item?._id === formValues?.sourceMeasure)
    const filterAttribute = (formValues?.measure !== "" && formValues?.sourceMeasure !== "" && timePeriodData?.baselevelUID?.fields?.length > 0)
        && (timePeriodData?.baselevelUID?.fields?.length > 0 && timePeriodData?.baselevelUID?.fields?.filter((item) => attributeData?.baselevelUID?.fields?.some((row) => row?._id === item?._id)))
    const higherLevelTimeAttributes = timePeriodData?.baselevelUID?.higherLevelTimeAttributes?.length > 0 && timePeriodData?.baselevelUID?.higherLevelTimeAttributes
    let timeAttribute = [];

    if (higherLevelTimeAttributes) {
        timeAttribute = [...higherLevelTimeAttributes];
    }

    if (timePeriodData?.baselevelUID?.["Period Type"]) {
        timeAttribute.push(timePeriodData.baselevelUID["Period Type"]);
    }

    const findTimePeriod = schemaData?.length > 0 && schemaData?.filter((item) => item["Period Base Level"] === formValues?.timePeiodName);
    const handleChangeProfile = (newValue, fieldName, e) => {
        if (fieldName === 'sourceMeasure') {
            setFormValues({
                ...formValues,
                [fieldName]: newValue?._id,
            });
        } else if (fieldName === 'measure') {
            const selectedData = measureList?.find((data) => data?._id === newValue?._id);
            setFormValues({
                ...formValues,
                [fieldName]: newValue?._id,
                timePeiodName: selectedData?.baselevelUID["Period Type"],
                _id: selectedData && selectedData?.baselevelUID?.primaryTimeForLevel
            });
        } else if (fieldName === 'level') {
            const selectedData = dimensionLevelList?.find((data) => data?._id === newValue?._id);
            setFormValues({
                ...formValues,
                [fieldName]: newValue?._id,
                timePeiodName: selectedData && selectedData["Period Type"],
                _id: selectedData && selectedData?.primaryTimeForLevel
            });
        } else if (fieldName === 'validFrom') {
            const selectedData = findTimePeriod?.find((data) => data?._id === newValue?._id);
            setFormValues({
                ...formValues,
                [fieldName]: selectedData && selectedData["Time Period Name"],
                startDate: newValue && newValue['Period Start']
            });
        } else if (fieldName === 'validTo') {
            const selectedData = findTimePeriod?.find((data) => data?._id === newValue?._id);
            setFormValues({
                ...formValues,
                [fieldName]: selectedData && selectedData["Time Period Name"],
                endDate: newValue && newValue["Period End"]
            });
        } else if (fieldName === 'filter') {
            setFormValues({
                ...formValues,
                [fieldName]: newValue?._id,
            });
        } else if (fieldName == "timeAttribute") {
            setFormValues({
                ...formValues,
                [fieldName]: newValue
            });
        } else {
            const { name, value } = e.target
            setFormValues({ ...formValues, [name]: value })
        }
    }

    const validToOptions = findTimePeriod?.length > 0 && findTimePeriod?.filter(option => {
        if (!formValues?.startDate) return option;
        // if (option["Time Period Name"] === selectedOptions.validFrom) return false;
        const optionStartDate = option["Period Start"];
        return moment(optionStartDate).format("YYYY-MM-DD") >= moment(formValues?.startDate).format("YYYY-MM-DD");
    });

    const validFromOptions = findTimePeriod?.length > 0 && findTimePeriod?.filter(option => {
        if (!formValues?.endDate) return option;
        const optionEndDate = option["Period End"];
        return moment(optionEndDate).format("YYYY-MM-DD") <= moment(formValues?.endDate).format("YYYY-MM-DD");
    });


    return (
        <>


            <Dialog open={measureCreateProfile} onClose={() => {
                setMeasureCreateProfile(false)
            }}>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        overflow: 'hidden',
                        width: '600px',
                    }}
                >
                    <Container style={{ display: "grid", paddingBottom: "20px" }}>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="action">Action Type</InputLabel>
                                    <Select
                                        labelId="action"
                                        name='action'
                                        id="demo-simple-select-readonly"
                                        value={formValues?.action}
                                        fullWidth
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(newValue, "action", e);
                                        }}
                                        label="Action Type"
                                        variant="outlined"
                                    >
                                        <MenuItem value="Copy">Copy</MenuItem>
                                        <MenuItem value="Delete">Delete</MenuItem>
                                        <MenuItem value="Download">Download</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            {formValues?.action === "Copy" && (
                                <>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="Source Measure"
                                                name="sourceMeasure"
                                                options={measureList}
                                                getOptionLabel={(option) => option.measureName}
                                                value={formValues?.sourceMeasure ?
                                                    measureList?.find((item) => item?._id == formValues?.sourceMeasure) || null : null}
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(newValue, "sourceMeasure", e);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Source Measure"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="targetMeasure"
                                        name="measure"
                                        options={measureList?.length > 0 ? measureList : []}
                                        getOptionLabel={(option) => option.measureName}
                                        value={formValues?.measure ?
                                            measureList?.find((item) => item?._id == formValues?.measure) || null : null}
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(newValue, "measure", e);
                                        }}
                                        disabled={formValues?.level}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Target Measure"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            {(formValues?.action === "Delete" || formValues?.action === "Download") && (
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            id="demo-simple-select-readonly"
                                            labelId="Level"
                                            name="level"
                                            options={dimensionLevelList}
                                            getOptionLabel={(option) => option.name}
                                            value={formValues?.level ?
                                                dimensionLevelList?.find((item) => item?._id == formValues?.level) || null : null}
                                            onChange={(e, newValue) => {
                                                handleChangeProfile(newValue, "level", e);
                                            }}
                                            disabled={formValues?.measure}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Level"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            )}
                        </Grid>

                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            <Grid item xs={6}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="valid From"
                                    name="validFrom"
                                    options={Array.isArray(validFromOptions) ? validFromOptions : []}
                                    getOptionLabel={(option) => option["Time Period Name"]}
                                    value={formValues?.validFrom ?
                                        schemaData?.length > 0 && (schemaData?.find((item) => item["Time Period Name"] == formValues?.validFrom) || null) : null}
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "validFrom", e);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Valid From"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Valid To"
                                    name="validTo"
                                    options={Array.isArray(validToOptions) ? validToOptions : []}
                                    getOptionLabel={(option) => option["Time Period Name"]}
                                    value={
                                        formValues?.validTo
                                            ? schemaData?.length > 0 && (schemaData?.find((item) => item["Time Period Name"] === formValues?.validTo) || null)
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "validTo", e);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Valid To"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Container>

                    <hr
                        style={{
                            borderTop: "2px solid #1976d2",
                            width: "100%",
                            margin: "20px auto",
                        }}
                    />
                    <Container style={{ display: "grid", paddingBottom: "20px" }}>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            <Grid item xs={6}>
                                <FormControl fullWidth sx={{ paddingBottom: "15px" }}>
                                    <Autocomplete
                                        id="demo-simple-select-readonly"
                                        labelId="Filter"
                                        name="filter"
                                        options={Array.isArray(filterDetail) ? filterDetail : []}
                                        getOptionLabel={(option) => option.name}
                                        value={formValues?.filter ?
                                            filterDetail.find(option => option._id === formValues.filter)
                                            || null : null}
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(newValue, "filter", e);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Filter Name"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Container>

                    <hr
                        style={{
                            borderTop: "2px solid #1976d2",
                            width: "100%",
                            margin: "20px auto",
                        }}
                    />

                    {formValues?.action === "Copy" && formValues?.action !== "" && (
                        <>
                            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography> Attribute</Typography>
                                    <Button variant='contained' onClick={handleAddField}>+</Button>
                                </Box>
                                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                    {attributeLevelData?.length > 0 && attributeLevelData?.map((item, index) => {
                                        return (
                                            <>
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth>
                                                        <Autocomplete
                                                            id="demo-simple-select-readonly"
                                                            labelId={`attributeValue${index}`}
                                                            name="attributeValue"
                                                            options={Array.isArray(filterAttribute?.length > 0) ? filterAttribute : timePeriodData?.baselevelUID?.fields ? timePeriodData?.baselevelUID?.fields : []}
                                                            getOptionLabel={(option) => option.name}
                                                            value={item?.attributeValue ?
                                                                filterAttribute?.length > 0 ? filterAttribute?.find((data) => data?._id === item?.attributeValue) : timePeriodData?.baselevelUID?.fields?.find((data) => data?._id == item?.attributeValue) || null : null}
                                                            onChange={(e, newValue) => {
                                                                handleChangeAttribute(index, newValue, "attributeValue", e);
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Attribute"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                />
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </>
                                        )
                                    })}
                                </Grid>
                            </Container>
                            <hr
                                style={{
                                    borderTop: "2px solid #1976d2",
                                    width: "100%",
                                    margin: "20px auto",
                                }}
                            />
                            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                id="demo-simple-select-readonly"
                                                labelId="timeAttribute"
                                                name="timeAttribute"
                                                options={timeAttribute?.length > 0 ? timeAttribute : []}
                                                getOptionLabel={(option) => option}
                                                value={formValues?.timeAttribute ?
                                                    timeAttribute?.find((item) => item == formValues?.timeAttribute) || null : null}
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(newValue, "timeAttribute", e);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="TIme Attribute"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            type='number'
                                            id="periodShift"
                                            name="periodShift"
                                            variant="outlined"
                                            fullWidth
                                            label="Period Shift"
                                            value={formValues?.periodShift}
                                            onChange={(e, newValue) => {
                                                handleChangeProfile(newValue, "periodShift", e);
                                            }}
                                            placeholder="Enter Period Shift"
                                            onKeyDown={(e) => {
                                                if (
                                                    e.key === "Backspace" ||
                                                    (e.key >= "0" && e.key <= "9" && e.location === 3) ||
                                                    (e.key !== "-" &&
                                                        e.key !== "e" &&
                                                        e.keyCode >= 47 &&
                                                        e.keyCode <= 58)
                                                ) {
                                                } else {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                        />
                                    </Grid>
                                </Grid>
                            </Container>
                        </>
                    )}

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            marginRight: "30px",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <Button
                            // disabled={isView}
                            onClick={() => {
                                setMeasureCreateProfile(false)
                                setMeasureEditProfile(false)
                                setAnchorEl(null)
                                setFormValues({
                                    name: formValues?.name,
                                    enterpriseUID: formValues?.enterpriseUID,
                                    modelUID: formValues?.modelUID,
                                    notes: formValues?.notes,
                                    action: "",
                                    measure: "",
                                    formDate: "",
                                    toDate: "",
                                    timeAttribute: "",
                                    periodShift: "",
                                    sourceMeasure: "",
                                    level: "",
                                    filter: ""
                                });
                                setAttributeLevelData([{
                                    attributeValue: ""
                                }]);
                            }}
                            title="Cancel"
                            style={{
                                padding: "6px 15px",
                                borderRadius: "2px",
                                cursor: "pointer",
                                marginRight: "10px",
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            onClick={() => {
                                const currentOperations = dataProfileDetail.operations || [];
                                const indexToUpdate = currentOperations.findIndex((op, index) => index === formValues?.index);
                                if (formValues?.validFrom === "" || formValues?.validFrom === undefined) {
                                    toast.error("Please select valid from")
                                    return
                                } else if (formValues?.validTo === "" || formValues?.validTo === undefined) {
                                    toast.error("Please select valid To")
                                    return
                                } else {
                                    if (indexToUpdate !== -1) {
                                        const updatedOperations = [...currentOperations];
                                        updatedOperations[indexToUpdate] = {
                                            measure: formValues?.measure,
                                            action: formValues?.action,
                                            fromDate: formValues?.validFrom,
                                            toDate: formValues?.validTo,
                                            ...(attributeLevelData?.length > 0 && {
                                                attributes: attributeLevelData?.map((item) => item?.attributeValue)
                                            }),
                                            ...(formValues?.timeAttribute && {
                                                timeAttribute: formValues?.timeAttribute
                                            }),
                                            ...(formValues?.periodShift && {
                                                periodShift: formValues?.periodShift
                                            }),
                                            ...(formValues?.sourceMeasure && {
                                                sourceMeasure: formValues?.sourceMeasure
                                            }),
                                            ...(formValues?.level && {
                                                level: formValues?.level
                                            }),
                                            filter: formValues?.filter
                                        };
                                        const newDataProfileDetail = { ...dataProfileDetail, operations: updatedOperations };
                                        setDataProfileDetail(newDataProfileDetail);
                                    } else {
                                        const newOperation = {
                                            measure: formValues?.measure,
                                            action: formValues?.action,
                                            fromDate: formValues?.validFrom,
                                            toDate: formValues?.validTo,
                                            ...(attributeLevelData?.length > 0 && {
                                                attributes: attributeLevelData?.map((item) => item?.attributeValue)
                                            }),
                                            ...(formValues?.timeAttribute && {
                                                timeAttribute: formValues?.timeAttribute
                                            }),
                                            ...(formValues?.periodShift && {
                                                periodShift: formValues?.periodShift
                                            }),
                                            ...(formValues?.sourceMeasure && {
                                                sourceMeasure: formValues?.sourceMeasure
                                            }),
                                            ...(formValues?.level && {
                                                level: formValues?.level
                                            }),
                                            filter: formValues?.filter
                                        };
                                        setDataProfileDetail((prevDataProfileDetail) => ({
                                            operations: [...prevDataProfileDetail.operations, newOperation]
                                        }));
                                    }
                                    setFormValues({
                                        name: formValues?.name,
                                        enterpriseUID: formValues?.enterpriseUID,
                                        modelUID: formValues?.modelUID,
                                        notes: formValues?.notes,
                                        action: "",
                                        measure: "",
                                        formDate: "",
                                        toDate: "",
                                        timeAttribute: "",
                                        periodShift: "",
                                        sourceMeasure: "",
                                        level: "",
                                        filter: ""
                                    });
                                    setAttributeLevelData([{
                                        attributeValue: ""
                                    }]);
                                    setMeasureCreateProfile(false);
                                    setIsCreate(true);
                                    setMeasureEditProfile(false);
                                    setAnchorEl(null);
                                }
                            }}
                        >
                            Save
                        </Button>

                    </div>
                </Box>
            </Dialog>
        </>
    )
}

export default MeasureDataProfileMock
