import { Autocomplete, Box, Button, Checkbox, Container, FormControl, FormControlLabel, Grid, IconButton, InputLabel, Menu, MenuItem, Select, styled, Switch, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import profileFieldData from "../../profileFieldData.json"
import { CommonAutoComplete } from '../commonComponent/commonSelect/CommonSelect';
import { CommonNumberInput } from '../commonComponent/input/CommonNumberInput';
import { MdMoreVert } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getModelListAsync } from '../../store/ApiSlice/dimensionSlice';


const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'green',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? 'black' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    }
}));

const OverviewTab = ({
    formValues,
    selectedProfile,
    handleChangeProfile,
    enterpriseData,
    modalName,
    timeData,
    isView,
    profileMeasure,
    handleChangehierarchicalForecasting
}) => {
    const [isChecked, setIsChecked] = useState({})
    useEffect(() => {
        setIsChecked(formValues)
    }, [formValues])
    const { dimensionData } = useSelector((state) => state.dimension)
    const dispatch = useDispatch()
    const findModelDimension = dimensionData?.filter((item) => item?.enterpriseUID?._id === formValues?.enterpriseUID)
        ?.filter((item) => item?.entermodelUID?.modelUID === formValues?.modelUID)
        ?.filter((item) => item?.type === "LEVEL")
    const findEnterpriseProfile = profileMeasure?.filter((item) => item?.enterpriseUID === formValues?.enterpriseUID)
        ?.filter((item) => item?.modelUID === formValues?.modelUID)
    const findMeasureName = profileMeasure?.filter((item) => item?.baselevelUID?._id === formValues?.historyBaseLevel)
    const findNavieMeasureName = profileMeasure?.filter((item) => item?.baselevelUID?._id === formValues?.forecastBaseLevel)
    const findHierarchicalForecastingAttribute = profileMeasure?.find((item) => item?.baselevelUID?._id === formValues?.forecastBaseLevel)
    const fields = findHierarchicalForecastingAttribute?.baselevelUID?.fields || [];

    const primaryKeyNames = findHierarchicalForecastingAttribute?.baselevelUID?.primaryKey?.map((key) => key.name);

    const nonPrimaryKeyFields = fields?.filter(
        (field) => !primaryKeyNames.includes(field?.name)
    );


    useEffect(() => {
        dispatch(getModelListAsync())
    }, [])
    return (
        <>
            <Typography variant="h6" component="h6" style={{
                marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
            }}>
                Profile Overview
            </Typography>{" "}
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={6}>
                        <TextField
                            id="name"
                            name="name"
                            variant="outlined"
                            fullWidth
                            label="Profile Name"
                            value={formValues?.name || selectedProfile?.name}
                            onChange={(e, newValue) => {
                                handleChangeProfile(newValue, "name", e);
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder="Enter profile name"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="demo-simple-select-readonly"
                                labelId="Enterprise Name"
                                name="enterpriseName"
                                options={enterpriseData}
                                getOptionLabel={(option) => option.enterpriseName}
                                value={
                                    formValues?.enterpriseName
                                        ? enterpriseData.find(
                                            (option) =>
                                                option.enterpriseName === formValues.enterpriseName
                                        ) || null
                                        : null
                                }
                                onChange={(e, newValue) => {
                                    handleChangeProfile(newValue, "enterpriseName", e);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Enterprise Name"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="demo-simple-select-readonly"
                                labelId="Model Name"
                                name="modelName"
                                options={modalName}
                                getOptionLabel={(option) => option.modelName}
                                value={
                                    formValues?.modelName
                                        ? modalName.find(
                                            (option) => option.modelName === formValues.modelName
                                        ) || null
                                        : null
                                }
                                onChange={(e, newValue) => {
                                    handleChangeProfile(newValue, "modelName", e);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Model Name"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {profileFieldData?.overViewCheckbox?.map((item, index) => {
                        return (
                            <>
                                <Grid item xs={1.71} sx={{
                                    '& .MuiFormControlLabel-root': {
                                        gap: '10px',
                                    },
                                }} style={{
                                    ...(index === 5 ? { paddingTop: "6px" } : {})
                                }}>
                                    <FormControlLabel
                                        control={
                                            <IOSSwitch
                                                id={item?.name}
                                                name={item?.name}
                                                checked={formValues?.[item?.name] || false}
                                                onChange={(e, newValue) => {
                                                    handleChangeProfile(newValue, item?.name, e);
                                                }}
                                            />
                                        }
                                        label={item?.label}
                                    />
                                </Grid>
                            </>
                        )
                    })}
                </Grid>
            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />

            <Typography variant="h6" component="h6" style={{
                marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
            }}>
                Basic Settings
            </Typography>{" "}
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="demo-simple-select-readonly"
                                labelId="History Base Level"
                                name="historyBaseLevel"
                                options={Array.isArray(findModelDimension) ? findModelDimension : []}
                                getOptionLabel={(option) =>
                                    option?.name
                                }
                                value={
                                    formValues?.historyBaseLevel
                                        ? findModelDimension?.find(
                                            (item) => item?._id == formValues?.historyBaseLevel
                                        ) || null
                                        : null
                                }
                                onChange={(e, newValue) => {
                                    handleChangeProfile(newValue, "historyBaseLevel", e);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="History Base Level"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <CommonAutoComplete
                                label="Base History Measure"
                                name="baseHistoryMeasureUID"
                                value={formValues?.historyBaseLevel ? formValues?.baseHistoryMeasureUID : ""}
                                profileMeasure={findMeasureName}
                                handleChangeProfile={handleChangeProfile}
                            />
                        </FormControl>
                    </Grid>
                    {formValues?.outlierCorrectionEngine && (
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <CommonAutoComplete
                                    label="Corrected History Measure"
                                    name="correctedHistoryMeasureUID"
                                    value={formValues?.correctedHistoryMeasureUID}
                                    profileMeasure={findMeasureName}
                                    handleChangeProfile={handleChangeProfile}
                                />
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="demo-simple-select-readonly"
                                labelId="Forecast Base Level"
                                name="forecastBaseLevel"
                                options={Array.isArray(findModelDimension) ? findModelDimension : []}
                                getOptionLabel={(option) =>
                                    option?.name
                                }
                                value={
                                    formValues?.forecastBaseLevel
                                        ? findModelDimension?.find(
                                            (item) => item?._id == formValues?.forecastBaseLevel
                                        ) || null
                                        : null
                                }
                                onChange={(e, newValue) => {
                                    handleChangeProfile(newValue, "forecastBaseLevel", e);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Forecast Base Level"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <CommonAutoComplete
                                label="Naive Forecast Measure"
                                name="naiveForecastMeasureUID"
                                value={formValues?.naiveForecastMeasureUID}
                                profileMeasure={findNavieMeasureName}
                                handleChangeProfile={handleChangeProfile}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    {[
                        { label: "Periodicity", name: "timePeriod" },
                        { label: "Frequency", name: "frequency" }
                    ]?.map((item) => {
                        return (
                            <>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            id="demo-simple-select-readonly"
                                            labelId={item?.label}
                                            name={item?.name}
                                            options={Array.isArray(timeData?.timeSchemaFields) ? timeData.timeSchemaFields : []}
                                            getOptionLabel={(option) =>
                                                option?.enabled === true && option["Period Type"]
                                            }
                                            value={
                                                formValues?.[item?.name]
                                                    ? timeData?.timeSchemaFields?.find(
                                                        (item) => item["Period Type"] == formValues?.timePeriod
                                                    ) || null
                                                    : null
                                            }
                                            onChange={(e, newValue) => {
                                                handleChangeProfile(newValue, item?.name, e);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={item?.label}
                                                    variant="outlined"
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </>
                        )
                    })}
                </Grid>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={3}>
                        <CommonNumberInput
                            name="historyHorizon"
                            label="History Horizon"
                            value={formValues?.historyHorizon}
                            handleChangeProfile={handleChangeProfile}
                            disabled={isView}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CommonNumberInput
                            name="forecastHorizon"
                            label="Forecast Horizon"
                            value={formValues?.forecastHorizon}
                            handleChangeProfile={handleChangeProfile}
                            disabled={isView}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CommonNumberInput
                            name="validationHorizon"
                            label="Validation Horizon"
                            value={formValues?.validationHorizon}
                            handleChangeProfile={handleChangeProfile}
                            disabled={isView}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CommonNumberInput
                            name="naiveForecastCalculationHorizon"
                            label="Naive Forecast Calculation Horizon"
                            value={formValues?.naiveForecastCalculationHorizon}
                            handleChangeProfile={handleChangeProfile}
                            disabled={isView}
                        />
                    </Grid>
                </Grid>
            </Container>
            {(formValues?.probabilisticStatisticalForecastingEngine || formValues?.probabilisticForecstingEngine) && (
                <>
                    <hr
                        style={{
                            borderTop: "2px solid #1976d2",
                            width: "100%",
                            margin: "20px auto",
                        }}
                    />

                    <Typography variant="h6" component="h6" style={{
                        marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                    }}>
                        Probabilistic Forecast Settings
                    </Typography>{" "}
                    <Container style={{ display: "grid", paddingBottom: "20px" }}>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-readonly" shrink>
                                        Probabilistic Metrics
                                    </InputLabel>
                                    <Select
                                        labelId="Probabilistic Metrics"
                                        name="probabilisticMetricsOverview"
                                        id="demo-simple-select-readonly"
                                        value={formValues?.probabilisticMetricsOverview || []} // Ensure the value is always an array
                                        multiple
                                        fullWidth
                                        onChange={(e) => {
                                            handleChangeProfile(e.target.value, "probabilisticMetricsOverview", e);
                                        }}
                                        label="Probabilistic Metrics"
                                        variant="outlined"
                                        renderValue={(selected) => selected.join(", ")} // Display selected values as a comma-separated string
                                    >
                                        {profileFieldData?.probabilisticMetricsOption.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                <Checkbox
                                                    checked={formValues?.probabilisticMetricsOverview?.includes(option)}
                                                />
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={3}>
                                <CommonNumberInput
                                    name="noOfIterationsOverview"
                                    label="# of Iterations"
                                    value={formValues?.noOfIterationsOverview || ""}
                                    handleChangeProfile={handleChangeProfile}
                                    placeholder="Enter iterations"
                                    disabled={isView}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <CommonNumberInput
                                    name="noOfBinsOverview"
                                    label="# of Bins"
                                    value={formValues?.noOfBinsOverview || ""}
                                    handleChangeProfile={handleChangeProfile}
                                    placeholder="Enter bins"
                                    disabled={isView}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-readonly" shrink>Initial Seed Type</InputLabel>
                                    <Select
                                        labelId="Initial Seed Type"
                                        name="initialSeedTypeOverview"
                                        id="demo-simple-select-readonly"
                                        value={formValues?.initialSeedTypeOverview || ""}
                                        fullWidth
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(newValue, "initialSeedTypeOverview", e);
                                        }}
                                        label="Initial Seed Type"
                                        variant="outlined"
                                    >
                                        <MenuItem value="Random">Random</MenuItem>
                                        <MenuItem value="Fixed">Fixed</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {formValues?.initialSeedTypeOverview === "Fixed" && (
                                <Grid item xs={3}>
                                    <CommonNumberInput
                                        name="initialSeedValueOverview"
                                        label="Initial Seed Value"
                                        value={formValues?.initialSeedValueOverview || ""}
                                        handleChangeProfile={handleChangeProfile}
                                        placeholder="Enter initial seed value"
                                        disabled={isView}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Container>
                </>
            )}

            {formValues?.hierarchicyAggregationEngine && (
                <>
                    <hr
                        style={{
                            borderTop: "2px solid #1976d2",
                            width: "100%",
                            margin: "20px auto",
                        }}
                    />
                    <Typography variant="h6" component="h6" style={{
                        marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                    }}>
                        Hierarchy Aggregation Engine
                    </Typography>{" "}
                    <Container style={{ display: "grid", paddingBottom: "20px" }}>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            {nonPrimaryKeyFields?.length > 0 ? nonPrimaryKeyFields
                                ?.filter((item) => !["Cycle ID", "Cycle Periodicity", "Cycle Start", "Cycle End", "Cycle Note", "Cycle Notes"].includes(item?.name))
                                ?.map((item, index) => {
                                    return (
                                        <>
                                            <Grid
                                                item
                                                xs={1.71}
                                                key={item?._id || index}
                                                sx={{
                                                    '& .MuiFormControlLabel-root': {
                                                        gap: '10px',
                                                    },
                                                }}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            id={item?.name}
                                                            name={item?._id}
                                                            checked={
                                                                formValues?.hierarchicalForecastingAttributes?.includes(item?._id) || false
                                                            }
                                                            onChange={(e) => {
                                                                handleChangehierarchicalForecasting(item?._id, e, "hierachicalForecasting");
                                                            }}
                                                        />
                                                    }
                                                    label={item?.name}
                                                />
                                            </Grid>
                                        </>
                                    )
                                }) : []}
                        </Grid>
                    </Container>
                </>
            )}
            {formValues?.timeAggregationEngine && (
                <>
                    <hr
                        style={{
                            borderTop: "2px solid #1976d2",
                            width: "100%",
                            margin: "20px auto",
                        }}
                    />
                    <Typography variant="h6" component="h6" style={{
                        marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                    }}>
                        Time Aggregation Engine
                    </Typography>{" "}

                    <Container style={{ display: "grid", paddingBottom: "20px" }}>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            {findHierarchicalForecastingAttribute?.baselevelUID?.higherLevelTimeAttributes?.length > 0 ?
                                findHierarchicalForecastingAttribute?.baselevelUID?.higherLevelTimeAttributes?.map((item, index) => {
                                    return (
                                        <>
                                            <Grid item xs={1.71} sx={{
                                                '& .MuiFormControlLabel-root': {
                                                    gap: '10px',
                                                },
                                            }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            id={item}
                                                            name={item}
                                                            checked={
                                                                formValues?.timeBucketAggregationPeriods?.includes(item) || false
                                                            }
                                                            onChange={(e) => {
                                                                handleChangehierarchicalForecasting(item, e, "timeBucketAggregation");
                                                            }}
                                                        />
                                                    }
                                                    label={item}
                                                />
                                            </Grid>
                                        </>
                                    )
                                }) : []}
                        </Grid>
                    </Container>
                </>
            )}
            {formValues?.aggregatedLevels?.length > 0 && (
                <>
                    <hr
                        style={{
                            borderTop: "2px solid #1976d2",
                            width: "100%",
                            margin: "20px auto",
                        }}
                    />
                    <Typography variant="h6" component="h6" style={{
                        marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                    }}>
                        Aggregated Levels
                    </Typography>{" "}
                    <Container style={{ display: "grid", paddingBottom: "20px" }}>
                        {formValues?.aggregatedLevels?.map((item) => {
                            return (
                                <>
                                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                        <Grid item xs={4}>
                                            <Typography>{item?.levelDetail}:</Typography>
                                        </Grid>

                                        <Grid item xs={6}>
                                            {item?.levelsUID?.map((row) => (
                                                <Grid item xs={12} sx={{ paddingBottom: "16px" }}>
                                                    <FormControl fullWidth>
                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                            <TextField
                                                                name='aggregatedLevels'
                                                                value={row?.name}
                                                            />
                                                        </div>
                                                    </FormControl>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </>
                            )
                        })}
                    </Container>
                </>
            )}
            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />


            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            >
                <Typography variant="h6" component="h6" style={{
                    marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                    Basic KPIs
                </Typography>{" "}
            </Box>
            <Container style={{ display: "grid", paddingBottom: "10px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid
                        item
                        xs={0.48}
                        sx={{ display: "flex", alignItems: "center" }}
                    >
                        <FormControl fullWidth>
                            <Checkbox
                                checked={formValues?.isCheckedbasicKPIError || false}
                                onChange={(e, newValue) => {
                                    handleChangeProfile(newValue, "isCheckedbasicKPIError", e);
                                }}
                                style={{
                                    padding: "0px !important",
                                    height: "54px",
                                }} />
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        xs={2.3}
                        sx={{ display: "flex", alignItems: "center" }}
                    >
                        <FormControl fullWidth>
                            <TextField
                                type="text"
                                id="error"
                                name="error"
                                variant="outlined"
                                fullWidth
                                label="Error"
                                value={"Error"}
                                placeholder="Enter default value"
                                inpu
                            />
                        </FormControl>
                    </Grid>
                    {formValues?.isCheckedbasicKPIError && (
                        <>
                            <Grid
                                item
                                xs={2.3}
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <FormControl fullWidth>
                                    <InputLabel id="method" shrink>Error Method</InputLabel>
                                    <Select
                                        labelId="method"
                                        name="errorMethod"
                                        id="demo-simple-select-readonly"
                                        value={formValues?.errorMethod || ""}
                                        fullWidth
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(newValue, "errorMethod", e);
                                        }}
                                        label="Error Method"
                                        variant="outlined"
                                    >
                                        {profileFieldData?.errorMethodOption?.map((item) => (
                                            <MenuItem value={item} > {item}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid >
                            {formValues?.errorMethod === "wMAPE" && (
                                <Grid item xs={2.3}>
                                    <FormControl fullWidth>
                                        <CommonAutoComplete
                                            label="Weight"
                                            name="weightErrorMeasureUID"
                                            value={formValues?.weightErrorMeasureUID}
                                            profileMeasure={profileMeasure}
                                            handleChangeProfile={handleChangeProfile}
                                        />
                                    </FormControl>
                                </Grid>
                            )}
                            <Grid item
                                xs={2.3}
                                sx={{ display: "flex", alignItems: "center" }}>
                                <CommonNumberInput
                                    name="defaultErrorValue"
                                    label="Default value"
                                    value={formValues?.defaultErrorValue}
                                    handleChangeProfile={handleChangeProfile}
                                    placeholder="Enter default Error value"
                                    disabled={isView}
                                />
                            </Grid>
                            <Grid item xs={2.3}>
                                <CommonNumberInput
                                    name="movingAverageErrorHorizon"
                                    label="Moving Average Horizon for Error"
                                    value={formValues?.movingAverageErrorHorizon}
                                    handleChangeProfile={handleChangeProfile}
                                    placeholder="Enter moving average horizon for Error"
                                    disabled={isView}
                                />
                            </Grid>
                            {formValues?.errorMethod === "wMAPE" && (
                                <Grid
                                    item
                                    xs={2.78}
                                    sx={{ display: "flex", alignItems: "center" }}
                                >

                                </Grid>
                            )}

                            <Grid item xs={2.3}>
                                <CommonAutoComplete
                                    label="Naive Forecast Error"
                                    name="naiveForecastErrorMeasureUID"
                                    value={formValues?.naiveForecastErrorMeasureUID}
                                    profileMeasure={findNavieMeasureName}
                                    handleChangeProfile={handleChangeProfile}
                                />
                            </Grid>
                            {formValues?.errorMethod !== "wMAPE" && (
                                <Grid
                                    item
                                    xs={2.78}
                                    sx={{ display: "flex", alignItems: "center" }}
                                >

                                </Grid>
                            )}
                            <Grid item xs={2.3}>
                                <CommonAutoComplete
                                    label="Naive Forecast Moving Average Error"
                                    name="naiveForecastMovingAverageErrorMeasureUID"
                                    value={formValues?.naiveForecastMovingAverageErrorMeasureUID}
                                    profileMeasure={findNavieMeasureName}
                                    handleChangeProfile={handleChangeProfile}
                                />
                            </Grid>
                            {formValues?.forecastabilityEngine && (
                                <>
                                    <Grid item xs={2.3}>
                                        <CommonAutoComplete
                                            label="Upper Bound Error Measure"
                                            name="forecastabilityBandErrorUpperBoundMeasureUID"
                                            value={formValues?.forecastabilityBandErrorUpperBoundMeasureUID}
                                            profileMeasure={findNavieMeasureName}
                                            handleChangeProfile={handleChangeProfile}
                                        />
                                    </Grid>
                                    <Grid item xs={2.3} sx={{ display: "flex", alignItems: "center" }}>
                                        <CommonAutoComplete
                                            label="Lower Bound Error Measure"
                                            name="forecastabilityBandErrorLowerBoundMeasureUID"
                                            value={formValues?.forecastabilityBandErrorLowerBoundMeasureUID}
                                            profileMeasure={findNavieMeasureName}
                                            handleChangeProfile={handleChangeProfile}
                                        />
                                    </Grid>
                                </>
                            )}
                        </>
                    )}

                </Grid>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>

                    <>
                        <Grid
                            item
                            xs={0.48}
                            sx={{ display: "flex", alignItems: "center" }}
                        >
                            <FormControl fullWidth>
                                <Checkbox
                                    name="isCheckedbasicKPIBias"
                                    checked={formValues?.isCheckedbasicKPIBias || false}
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "isCheckedbasicKPIBias", e);
                                    }}
                                    style={{
                                        padding: "0px !important",
                                        height: "54px",
                                    }} />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            xs={2.3}
                            sx={{ display: "flex", alignItems: "center" }}
                        >
                            <FormControl fullWidth>
                                <TextField
                                    type="text"
                                    id="Bias"
                                    name="Bias"
                                    variant="outlined"
                                    fullWidth
                                    label="Bias"
                                    value={"Bias"}
                                    placeholder="Enter default value"
                                />
                            </FormControl>
                        </Grid>
                        {formValues?.isCheckedbasicKPIBias && (
                            <>

                                <Grid
                                    item
                                    xs={2.3}
                                    sx={{ display: "flex", alignItems: "center" }}
                                >
                                    <FormControl fullWidth>
                                        <InputLabel id="method" shrink>Bias Method</InputLabel>
                                        <Select
                                            labelId="method"
                                            name="biasMethod"
                                            id="demo-simple-select-readonly"
                                            value={formValues?.biasMethod || ""}
                                            fullWidth
                                            onChange={(e, newValue) => {
                                                handleChangeProfile(newValue, "biasMethod", e);
                                            }}
                                            label="Bias Method"
                                            variant="outlined"
                                        >
                                            {profileFieldData?.biasMethodOption?.map((item) => (
                                                <MenuItem value={item} > {item}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid >
                                {formValues?.biasMethod === "wMPE" && (
                                    <Grid item xs={2.3}>
                                        <FormControl fullWidth>
                                            <CommonAutoComplete
                                                label="Weight"
                                                name="weightBiasMeasureUID"
                                                value={formValues?.weightBiasMeasureUID}
                                                profileMeasure={profileMeasure}
                                                handleChangeProfile={handleChangeProfile}
                                            />
                                        </FormControl>
                                    </Grid>
                                )}
                                <Grid item
                                    xs={2.3}
                                    sx={{ display: "flex", alignItems: "center" }}>
                                    <CommonNumberInput
                                        name="defaultBiasValue"
                                        label="Default value"
                                        value={formValues?.defaultBiasValue}
                                        handleChangeProfile={handleChangeProfile}
                                        placeholder="Enter default bias value"
                                        disabled={isView}
                                    />
                                </Grid>
                                <Grid item xs={2.3}>
                                    <CommonNumberInput
                                        name="movingAverageBiasHorizon"
                                        label="Moving Average Horizon for Bias"
                                        value={formValues?.movingAverageBiasHorizon}
                                        handleChangeProfile={handleChangeProfile}
                                        placeholder="Enter moving average horizon for bias"
                                        disabled={isView}
                                    />
                                </Grid>
                                {formValues?.biasMethod === "wMPE" && (
                                    <Grid
                                        item
                                        xs={2.78}
                                        sx={{ display: "flex", alignItems: "center" }}
                                    >

                                    </Grid>
                                )}
                                <Grid item xs={2.3}>
                                    <CommonAutoComplete
                                        label="Naive Forecast Bias"
                                        name="naiveForecastBiasMeasureUID"
                                        value={formValues?.naiveForecastBiasMeasureUID}
                                        profileMeasure={findNavieMeasureName}
                                        handleChangeProfile={handleChangeProfile}
                                    />
                                </Grid>
                                {formValues?.biasMethod !== "wMPE" && (
                                    <Grid
                                        item
                                        xs={2.78}
                                        sx={{ display: "flex", alignItems: "center" }}
                                    >

                                    </Grid>
                                )}
                                <Grid item xs={2.3}>

                                    <CommonAutoComplete
                                        label="Naive Forecast Moving Average Bias"
                                        name="naiveForecastMovingAverageBiasMeasureUID"
                                        value={formValues?.naiveForecastMovingAverageBiasMeasureUID}
                                        profileMeasure={findNavieMeasureName}
                                        handleChangeProfile={handleChangeProfile}
                                    />
                                </Grid>
                                {formValues?.forecastabilityEngine && (
                                    <>

                                        <Grid item xs={2.3}>
                                            <CommonAutoComplete
                                                label="Upper Bound Bias Measure"
                                                name="forecastabilityBandBiasUpperBoundMeasureUID"
                                                value={formValues?.forecastabilityBandBiasUpperBoundMeasureUID}
                                                profileMeasure={findNavieMeasureName}
                                                handleChangeProfile={handleChangeProfile}
                                            />
                                        </Grid>
                                        <Grid item xs={2.3} sx={{ display: "flex", alignItems: "center" }}>

                                            <CommonAutoComplete
                                                label="Lower Bound Bias Measure"
                                                name="forecastabilityBandBiasLowerBoundMeasureUID"
                                                value={formValues?.forecastabilityBandBiasLowerBoundMeasureUID}
                                                profileMeasure={findNavieMeasureName}
                                                handleChangeProfile={handleChangeProfile}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}

                    </>

                </Grid>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid
                        item
                        xs={0.48}
                        sx={{ display: "flex", alignItems: "center" }}
                    >
                        <FormControl fullWidth>
                            <Checkbox
                                name="isCheckedbasicKPIChurn"
                                checked={formValues?.isCheckedbasicKPIChurn || false}
                                onChange={(e, newValue) => {
                                    handleChangeProfile(newValue, "isCheckedbasicKPIChurn", e);
                                }}
                                style={{
                                    padding: "0px !important",
                                    height: "54px",
                                }} />
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        xs={2.3}
                        sx={{ display: "flex", alignItems: "center" }}
                    >
                        <FormControl fullWidth>
                            <TextField
                                type="text"
                                id="Churn"
                                name="Churn"
                                variant="outlined"
                                fullWidth
                                label="Churn"
                                value={"Churn"}
                                placeholder="Enter default value"
                            />
                        </FormControl>
                    </Grid>
                    {formValues?.isCheckedbasicKPIChurn && (
                        <>

                            <Grid
                                item
                                xs={2.3}
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <FormControl fullWidth>
                                    <InputLabel id="method" shrink>Churn Method</InputLabel>
                                    <Select
                                        labelId="method"
                                        name="churnMethod"
                                        id="demo-simple-select-readonly"
                                        value={formValues?.churnMethod || ""}
                                        fullWidth
                                        onChange={(e, newValue) => {
                                            handleChangeProfile(newValue, "churnMethod", e);
                                        }}
                                        label="Churn Method"
                                        variant="outlined"
                                    >
                                        {profileFieldData?.churnMethodOption?.map((item) => (
                                            <MenuItem value={item} > {item}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid >
                            {formValues?.churnMethod === "wMAPC" && (
                                <Grid item xs={2.3}>
                                    <FormControl fullWidth>
                                        <CommonAutoComplete
                                            label="Weight"
                                            name="weightChurnMeasureUID"
                                            value={formValues?.weightChurnMeasureUID}
                                            profileMeasure={profileMeasure}
                                            handleChangeProfile={handleChangeProfile}
                                        />
                                    </FormControl>
                                </Grid>
                            )}
                            <Grid item
                                xs={2.3}
                                sx={{ display: "flex", alignItems: "center" }}>
                                {/* <TextField
                                    type="number"
                                    id="defaultChurnValue"
                                    name="defaultChurnValue"
                                    variant="outlined"
                                    fullWidth
                                    label="Default value"
                                    value={Number(formValues?.defaultChurnValue) || ''}
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "defaultChurnValue", e);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder="Enter default churn value"
                                /> */}
                                <CommonNumberInput
                                    name="defaultChurnValue"
                                    label="Default value"
                                    value={formValues?.defaultChurnValue}
                                    handleChangeProfile={handleChangeProfile}
                                    placeholder="Enter default churn value"
                                    disabled={isView}
                                />
                            </Grid>
                            <Grid item xs={2.3}>
                                <CommonNumberInput
                                    name="movingAverageChurnHorizon"
                                    label="Moving Average Horizon for Churn"
                                    value={formValues?.movingAverageChurnHorizon}
                                    handleChangeProfile={handleChangeProfile}
                                    placeholder="Enter moving average horizon for churn"
                                    disabled={isView}
                                />
                            </Grid>
                            {formValues?.churnMethod === "wMAPC" && (
                                <Grid
                                    item
                                    xs={2.78}
                                    sx={{ display: "flex", alignItems: "center" }}
                                >

                                </Grid>
                            )}
                            <Grid item xs={2.3}>
                                <CommonAutoComplete
                                    label="Naive Forecast Churn"
                                    name="naiveForecastChurnMeasureUID"
                                    value={formValues?.naiveForecastChurnMeasureUID}
                                    profileMeasure={findNavieMeasureName}
                                    handleChangeProfile={handleChangeProfile}
                                />
                            </Grid>
                            {formValues?.churnMethod !== "wMAPC" && (
                                <Grid
                                    item
                                    xs={2.78}
                                    sx={{ display: "flex", alignItems: "center" }}
                                >

                                </Grid>
                            )}
                            <Grid item xs={2.3}>
                                <CommonAutoComplete
                                    label="Naive Forecast Moving Average Churn"
                                    name="naiveForecastMovingAverageChurnMeasureUID"
                                    value={formValues?.naiveForecastMovingAverageChurnMeasureUID}
                                    profileMeasure={findNavieMeasureName}
                                    handleChangeProfile={handleChangeProfile}
                                />
                            </Grid>
                            {formValues?.forecastabilityEngine && (
                                <>

                                    <Grid item xs={2.3}>
                                        <CommonAutoComplete
                                            label="Upper Bound Churn Measure"
                                            name="forecastabilityBandChurnUpperBoundMeasureUID"
                                            value={formValues?.forecastabilityBandChurnUpperBoundMeasureUID}
                                            profileMeasure={findNavieMeasureName}
                                            handleChangeProfile={handleChangeProfile}
                                        />
                                    </Grid>
                                    <Grid item xs={2.3} sx={{ display: "flex", alignItems: "center" }}>
                                        <CommonAutoComplete
                                            label="Lower Bound Churn Measure"
                                            name="forecastabilityBandChurnLowerBoundMeasureUID"
                                            value={formValues?.forecastabilityBandChurnLowerBoundMeasureUID}
                                            profileMeasure={findNavieMeasureName}
                                            handleChangeProfile={handleChangeProfile}
                                        />

                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                </Grid>
            </Container >

        </>
    )
}

export default OverviewTab
