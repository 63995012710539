import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux'
import { getProfileData } from '../../store/ApiSlice/profileSlice';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MdMoreVert } from 'react-icons/md';
import moment from 'moment';

const ProfileMaterialList = ({
    handleTableRowSelect,
    setUpdatedId,
    handleCreateUpdate,
    setModalDeleteOpen,
    setCopyForecastProfile,
    setCopyProfileModel
}) => {
    const userData = JSON.parse(localStorage.getItem("accessToken"))
    const { profileData } = useSelector((state) => state.profile)
    const [openedMenus, setOpenedMenus] = useState({});
    const dispatch = useDispatch()
    const sortedProfileData = useMemo(() => {
        return [...(profileData || [])].sort((a, b) => {
            const profileA = a.name.toLowerCase();
            const profileB = b.name.toLowerCase();
            if (profileA < profileB) return -1;
            if (profileA > profileB) return 1;
            return 0;
        });
    }, [profileData]);
    const handleClick = (rowId) => (event) => {
        event.stopPropagation();
        setOpenedMenus((prevMenus) => ({
            ...prevMenus,
            [rowId]: event.currentTarget,
        }));
    };

    const handleClose = (id) => {
        setOpenedMenus(prevState => ({
            ...prevState,
            [id]: null
        }));
    };

    useEffect(() => {
        dispatch(getProfileData())
    }, [])

    const columns = useMemo(() => [
        {
            accessorKey: "name",
            enableColumnOrdering: false,
            header: "Profile Name",
            enableSorting: false,
        },
        {
            accessorKey: "baseLevel",
            enableColumnOrdering: false,
            header: "Forecast Base Level",
            enableSorting: false,
            Cell: ({ row }) => (
                <>
                    <p>
                        {row?.original?.forecastBaseLevel?.name}</p>
                </>
            )
        },
        {
            accessorKey: "enterpriseUID.enterpriseName",
            enableColumnOrdering: false,
            header: "Enterprise Name",
            enableSorting: false,
            filterFn: (row, columnId, filterValue) => {
                // Custom filter logic
                const modelName = row?.original?.enterpriseUID?.enterpriseName || "";
                return modelName.toLowerCase().includes(filterValue.toLowerCase());
            },
            Cell: ({ row }) => (
                <p>{row?.original?.enterpriseUID?.enterpriseName}</p>
            )
        },
        {
            accessorKey: "modelUID.modelName",
            enableColumnOrdering: false,
            header: "Model Name",
            enableSorting: false,
            filterFn: (row, columnId, filterValue) => {
                // Custom filter logic
                const enterpriseName = row?.original?.modelUID?.modelName || "";
                return enterpriseName.toLowerCase().includes(filterValue.toLowerCase());
            },
            Cell: ({ row }) => (
                <p>{row?.original?.modelUID?.modelName}</p>
            ),
        },
        {
            accessorKey: "createdOn",
            enableColumnOrdering: false,
            header: "Created On",
            enableSorting: false,
            Cell: ({ row }) => (
                <>
                    <p>{moment(row?.original?.createdOn).format(userData?.data?.dateFormat ? userData?.data?.dateFormat : "DD-MM-YYYY")}</p>
                </>
            )
        },
        {
            accessorKey: "changedOn",
            enableColumnOrdering: false,
            header: "Changed On",
            enableSorting: false,
            Cell: ({ row }) => (
                <>
                    <p>{moment(row?.original?.changedOn).format(userData?.data?.dateFormat ? userData?.data?.dateFormat : "DD-MM-YYYY")}</p>
                </>
            )
        },
        {
            accessorKey: "view",
            header: "",
            enableSorting: false,
            enableColumnOrdering: false,
            Cell: ({ row }) => {
                return (
                    <>
                        <IconButton onClick={handleClick(row.original._id)} size="small">
                            <MdMoreVert />
                        </IconButton>
                        <Menu
                            anchorEl={openedMenus[row.original._id]}
                            open={Boolean(openedMenus[row.original._id])}
                            onClose={() => { handleClose(row.original._id) }}
                        >
                            <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleClose(row.original._id);
                                    handleCreateUpdate(true, row?.original?._id);
                                }}
                                sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                            >
                                Edit Profile
                            </MenuItem>
                            <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleClose(row.original._id);
                                    setCopyForecastProfile({
                                        ...row.original, // Spread all properties of row.original
                                        name: ""         // Explicitly set the name property
                                    });
                                    setCopyProfileModel(true);
                                }}
                                sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                            >
                                Copy Profile
                            </MenuItem>
                            <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleClose(row.original._id);
                                    setUpdatedId(row.original._id);
                                    setModalDeleteOpen(true);
                                }}
                                sx={{ fontSize: "12px", fontFamily: "'Poppins', sans-serif" }}
                            >
                                Delete Profile
                            </MenuItem>
                        </Menu>
                    </>
                )
            }
        }
    ])

    const table = useMaterialReactTable({
        columns: columns.filter(Boolean),
        data: sortedProfileData || [],
        enableColumnOrdering: true,
        enableSelectAll: false,
        enableRowSelection: false,
        muiSelectCheckboxProps: { color: "secondary" },
        positionToolbarAlertBanner: "head-overlay",
        enableFullScreenToggle: false,
        enableColumnPinning: true,
        muiTableHeadCellProps: {
            sx: {
                fontSize: "12px"
            },
        },
        muiTableBodyCellProps: {
            sx: {
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif"
            }
        },
        muiTableBodyRowProps: ({ row }) => ({
            onClick: () => {
                handleClose(row.original._id);
                handleCreateUpdate(true, row?.original?._id);
            }, // Row click event
            sx: {
                cursor: 'pointer', // Add a pointer cursor to indicate clickable rows
                fontSize: "12px",
                fontFamily: "'Poppins', sans-serif",
                '&:hover': {
                    backgroundColor: '#f5f5f5', // Optional: Add hover effect for clarity
                },
            },
        }),
    });

    return <MaterialReactTable table={table} />
}

export default ProfileMaterialList
