import { Autocomplete, Box, Button, Container, Switch, styled, FormControl, FormControlLabel, Grid, IconButton, InputLabel, Menu, MenuItem, Select, TextField, Typography } from '@mui/material'
import React from 'react'
import { CommonAutoComplete } from '../commonComponent/commonSelect/CommonSelect'
import { MdMoreVert } from 'react-icons/md'

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'green',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? 'black' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    }
}));

const AiEnsembleTab = ({
    formValues,
    selectedProfile,
    handleChangeProfile,
    isView,
    profileMeasure,
    aiEnsembleBasicKPI,
    anchorAiEnsembleEl,
    setAnchorAiEnsembleEl,
    deleteAiEnsemble,
    handleAddAiEnsembleKPI,
    handleChangeAiEnsembleBasicKPI,
    handleAdd,
    handleClick,
    handleremove,
    forcastData,
    handleChangeForeCastProfile,
    deleteForCast,
    anchorEl,
    handleClose
}) => {
    return (
        <>
            <Typography variant="h6" component="h6" style={{
                marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
            }}>
            Basic Settings
            </Typography>{" "}
            <Container style={{ display: "grid", paddingBottom: "20px" }}>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={3}>
                        <CommonAutoComplete
                            label="AI Fusion Forecast Measure"
                            name="aiFusionForecastMeasureUID"
                            value={formValues?.aiFusionForecastMeasureUID}
                            profileMeasure={profileMeasure}
                            handleChangeProfile={handleChangeProfile}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CommonAutoComplete
                            label="AI Fusion Forecast Discrepancy Rank Measure"
                            name="aiFusionForecastOverallDiscrepancyRankMeasureUID"
                            value={formValues?.aiFusionForecastOverallDiscrepancyRankMeasureUID}
                            profileMeasure={profileMeasure}
                            handleChangeProfile={handleChangeProfile}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CommonAutoComplete
                            label="AI Fusion Forecast Quality Rank Measure"
                            name="aiFusionForecastOverallQualityRankMeasureUID"
                            value={formValues?.aiFusionForecastOverallQualityRankMeasureUID}
                            profileMeasure={profileMeasure}
                            handleChangeProfile={handleChangeProfile}
                        />
                    </Grid>
                    <Grid item xs={3} sx={{

                        '& .MuiFormControlLabel-root': {
                            marginTop: '11px',
                            gap: '10px',
                            paddingLeft: "20px"
                        },
                    }}>
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    id="humanForecast"
                                    name="humanForecast"
                                    checked={formValues?.humanForecast || false}
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "humanForecast", e);
                                    }}
                                />
                            }
                            label="Expert Forecasts"
                        />
                    </Grid>
                </Grid>
            </Container>

            <hr
                style={{
                    borderTop: "2px solid #1976d2",
                    width: "100%",
                    margin: "20px auto",
                }}
            />

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 10px"
                }}
            >
                <Typography variant="h6" component="h6" style={{
                    marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                }}>
                Basic KPI Measures
                </Typography>{" "}
            </Box>
            {formValues?.isCheckedbasicKPIError && (
                <>
                    <Container style={{ display: "grid" }}>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            <Grid
                                item xs={2.4}
                            >
                                <FormControl fullWidth>
                                    <TextField
                                        type="text"
                                        id="error"
                                        name="error"
                                        variant="outlined"
                                        fullWidth
                                        label="Error"
                                        value={"Error"}
                                        placeholder="Enter default value"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2.4}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="AI Fusion Forecast Error"
                                    name="aiFusionForecastErrorMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.aiFusionForecastErrorMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.aiFusionForecastErrorMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "aiFusionForecastErrorMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="AI Fusion Forecast Error"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="AI Fusion Forecast Moving Average Error"
                                    name="aiFusionForecastMovingAverageErrorMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.aiFusionForecastMovingAverageErrorMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.aiFusionForecastMovingAverageErrorMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "aiFusionForecastMovingAverageErrorMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="AI Fusion Forecast Moving Average Error"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="AI Fusion Forecast Relative Error"
                                    name="aiFusionForecastRelativeErrorMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.aiFusionForecastRelativeErrorMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.aiFusionForecastRelativeErrorMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "aiFusionForecastRelativeErrorMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="AI Fusion Forecast Relative Error"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="AI Fusion Forecast Error Rank"
                                    name="aiFusionForecastErrorRankMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.aiFusionForecastErrorRankMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.aiFusionForecastErrorRankMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "aiFusionForecastErrorRankMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="AI Fusion Forecast Error Rank"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2.4}></Grid>
                            <Grid item xs={2.4}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="AI Fusion Forecast Accuracy Rank"
                                    name="aiFusionForecastAccuracyRankMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.aiFusionForecastAccuracyRankMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.aiFusionForecastAccuracyRankMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "aiFusionForecastAccuracyRankMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="AI Fusion Forecast Accuracy Rank"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="AI Fusion Forecast Error FVA"
                                    name="aiFusionForecastErrorFVAMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.aiFusionForecastErrorFVAMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.aiFusionForecastErrorFVAMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "aiFusionForecastErrorFVAMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="AI Fusion Forecast Error FVA"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Weight Parameter %"
                                    name="weightParameterMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.weightParameterMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.weightParameterMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "weightParameterMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Weight Parameter %"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </>
            )}

            {formValues?.isCheckedbasicKPIBias && (
                <>
                    <Container style={{ display: "grid" }}>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            <Grid
                                item xs={2.4}
                            >
                                <FormControl fullWidth>
                                    <TextField
                                        type="text"
                                        id="Bias"
                                        name="Bias"
                                        variant="outlined"
                                        fullWidth
                                        label="Bias"
                                        value={"Bias"}
                                        placeholder="Enter default value"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2.4}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="AI Fusion Forecast Bias"
                                    name="aiFusionForecastBiasMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.aiFusionForecastBiasMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.aiFusionForecastBiasMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "aiFusionForecastBiasMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="AI Fusion Forecast Bias"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="AI Fusion Forecast Moving Average Bias"
                                    name="aiFusionForecastMovingAverageBiasMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.aiFusionForecastMovingAverageBiasMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.aiFusionForecastMovingAverageBiasMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "aiFusionForecastMovingAverageBiasMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="AI Fusion Forecast Moving Average Bias"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="AI Fusion Forecast Relative Bias"
                                    name="aiFusionForecastRelativeBiasMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.aiFusionForecastRelativeBiasMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.aiFusionForecastRelativeBiasMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "aiFusionForecastRelativeBiasMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="AI Fusion Forecast Relative Bias"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="AI Fusion Forecast Bias Rank"
                                    name="aiFusionForecastBiasRankMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.aiFusionForecastBiasRankMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.aiFusionForecastBiasRankMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "aiFusionForecastBiasRankMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="AI Fusion Forecast Bias Rank"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2.4}></Grid>
                            <Grid item xs={2.4}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="AI Fusion Forecast Neutrality Rank"
                                    name="aiFusionForecastNeutralityRankMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.aiFusionForecastNeutralityRankMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.aiFusionForecastNeutralityRankMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "aiFusionForecastNeutralityRankMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="AI Fusion Forecast Neutrality Rank"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="AI Fusion Forecast Bias FVA"
                                    name="aiFusionForecastBiasFVAMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.aiFusionForecastBiasFVAMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.aiFusionForecastBiasFVAMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "aiFusionForecastBiasFVAMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="AI Fusion Forecast Bias FVA"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Weight Parameter %"
                                    name="weightParameterBiasMeasure"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.weightParameterBiasMeasure
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.weightParameterBiasMeasure
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "weightParameterBiasMeasure", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Weight Parameter %"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                               
                            </Grid>
                        </Grid>
                    </Container>
                </>
            )}

            {formValues?.isCheckedbasicKPIChurn && (
                <>
                    <Container style={{ display: "grid" }}>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            <Grid
                                item xs={2.4}
                            >
                                <FormControl fullWidth>
                                    <TextField
                                        type="text"
                                        id="Churn"
                                        name="Churn"
                                        variant="outlined"
                                        fullWidth
                                        label="Churn"
                                        value={"Churn"}
                                        placeholder="Enter default value"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2.4}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="AI Fusion Forecast Churn"
                                    name="aiFusionForecastChurnMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.aiFusionForecastChurnMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.aiFusionForecastChurnMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "aiFusionForecastChurnMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="AI Fusion Forecast Churn"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="AI Fusion Forecast Moving Average Churn"
                                    name="aiFusionForecastMovingAverageChurnMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.aiFusionForecastMovingAverageChurnMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.aiFusionForecastMovingAverageChurnMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "aiFusionForecastMovingAverageChurnMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="AI Fusion Forecast Moving Average Churn"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="AI Fusion Forecast Relative Churn"
                                    name="aiFusionForecastRelativeChurnMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.aiFusionForecastRelativeChurnMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.aiFusionForecastRelativeChurnMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "aiFusionForecastRelativeChurnMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="AI Fusion Forecast Relative Churn"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="AI Fusion Forecast Churn Rank"
                                    name="aiFusionForecastChurnRankMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.aiFusionForecastChurnRankMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.aiFusionForecastChurnRankMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "aiFusionForecastChurnRankMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="AI Fusion Forecast Churn Rank"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2.4}></Grid>
                            <Grid item xs={2.4}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="AI Fusion Forecast Stability Rank"
                                    name="aiFusionForecastStabilityRankMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.aiFusionForecastStabilityRankMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.aiFusionForecastStabilityRankMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "aiFusionForecastStabilityRankMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="AI Fusion Forecast Stability Rank"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="AI Fusion Forecast Churn FVA"
                                    name="aiFusionForecastChurnFVAMeasureUID"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.aiFusionForecastChurnFVAMeasureUID
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.aiFusionForecastChurnFVAMeasureUID
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "aiFusionForecastChurnFVAMeasureUID", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="AI Fusion Forecast Churn FVA"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2.4}>
                                <Autocomplete
                                    id="demo-simple-select-readonly"
                                    labelId="Weight Parameter %"
                                    name="weightParameterChurnMeasure"
                                    // options={profileMeasure}
                                    options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                    getOptionLabel={(option) => option.measureName}
                                    value={
                                        formValues?.weightParameterChurnMeasure
                                            ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                (row) => row?._id == formValues?.weightParameterChurnMeasure
                                            ) || null : [])
                                            : null
                                    }
                                    onChange={(e, newValue) => {
                                        handleChangeProfile(newValue, "weightParameterChurnMeasure", e);
                                    }}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Weight Parameter %"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </>
            )}

            {formValues?.humanForecast && (
                <>
                    <hr
                        style={{
                            borderTop: "2px solid #1976d2",
                            width: "100%",
                            margin: "20px auto",
                        }}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "0px 10px"
                        }}
                    >
                        <Typography variant="h6" component="h6" style={{
                            marginTop: "10px", marginLeft: "10px", fontFamily: "'Poppins', sans-serif"
                        }}>
                        Expert Forecasts
                        </Typography>{" "}
                        <Button variant="contained" onClick={handleAdd}>
                            +
                        </Button>
                    </Box>

                    <Container style={{ display: "grid", paddingBottom: "20px" }}>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            {forcastData?.map((item, index) => {
                                return (
                                    <Grid
                                        item
                                        xs={6}
                                        sx={{ display: "flex", alignItems: "center" }}
                                    >
                                        <Autocomplete
                                            id="demo-simple-select-readonly"
                                            labelId="Expert Forecast Measure"
                                            name="humanforecasts"
                                            // options={profileMeasure}
                                            options={Array.isArray(profileMeasure) ? profileMeasure : []}
                                            getOptionLabel={(option) => option.measureName}
                                            value={
                                                item?.humanforecasts
                                                    ? (profileMeasure?.length > 0 ? profileMeasure?.find(
                                                        (row) => row?._id == item?.humanforecasts
                                                    ) || null : [])
                                                    : null
                                            }
                                            onChange={(e, newValue) => {
                                                handleChangeForeCastProfile(
                                                    index,
                                                    newValue,
                                                    "humanforecasts",
                                                    e
                                                );
                                            }}
                                            sx={{ width: "100%" }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Expert Forecast Measure"
                                                    variant="outlined"
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                        <IconButton
                                            aria-label="more"
                                            id="long-button"
                                            aria-controls={deleteForCast ? "long-menu" : undefined}
                                            aria-expanded={deleteForCast ? "true" : undefined}
                                            aria-haspopup="true"
                                            onClick={(e) => handleClick(e, index, "humanforecasts")}
                                            size="small"
                                        >
                                            <MdMoreVert />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                "aria-labelledby": "long-button",
                                            }}
                                            anchorEl={anchorEl}
                                            open={deleteForCast}
                                            onClose={handleClose}
                                        >
                                            <MenuItem onClick={() => { handleremove("humanforecasts") }} sx={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px" }}>Delete</MenuItem>
                                        </Menu>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Container>
                </>
            )}
        </>
    )
}

export default AiEnsembleTab
