import React, { useEffect, useState } from "react";
import axios from "axios";
import "./createdimension.css";
import { toast } from "react-toastify";
import { Select, MenuItem, FormControl, TableCell, Box } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Grid,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import AttributesTable from "./AttributesTable";
import { options } from "../../../helper/constants";
import SelectedDimensionsTable from "./SelectedDimensionsTable";
import ReferenceAttributes from "./ReferenceAttributes";
import TimeDimensionsTable from "./TimeDimensionsTable";
import { API_BASE_URL } from "../../../helper/constants";
import ScenariosTable from "./Scenarios";
import TimeAttribute from "./TimeAttribute";
import CustomTimeAttribute from "./CustomTimeAttribute";
import PlanningLevelDimension from "./PlanningLevelDimension";
import PlanningDimensionModel from "./PlanningDimensionModel";
import { useDispatch } from "react-redux";
import { createUpdateDimensionAsync, getModelListAsync } from "../../../store/ApiSlice/dimensionSlice";
import PlanningKeyAttribute from "./PlanningKeyAttribute";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const CreateUpdateDimension = ({
  setIsCreate,
  setIsEdit,
  isEdit,
  isCreate,
  selectedRows,
  data,
  isView,
  handleView,
  setIsView,
}) => {
  console.log("datadatadata", data);

  const timeSchemaFields = [
    {
      "Period Type": "Day",
      "Base Level": "",
      Level: 1,
      "Display Settings": "D## YYYY",
      enabled: true,
    },
    {
      "Period Type": "SysWeek",
      "Base Level": "Day",
      Level: 2,
      "Display Settings": "SW## YYYY",
      enabled: true,
    },
    {
      "Period Type": "Week",
      "Base Level": "SysWeek",
      Level: 3,
      "Display Settings": "W## YYYY",
      enabled: true,
    },
    {
      "Period Type": "Month",
      "Base Level": "SysWeek",
      Level: 4,
      "Display Settings": "M## YYYY",
      enabled: true,
    },
    {
      "Period Type": "Quarter",
      "Base Level": "Month",
      Level: 5,
      "Display Settings": "Q## YYYY",
      enabled: true,
    },
    {
      "Period Type": "Year",
      "Base Level": "Quarter",
      Level: 6,
      "Display Settings": "YYYY",
      enabled: true,
    },
  ];
  const AssignedAttributedFiled = [
    {
      name: "Time Period Id",
      type: "VARCHAR",
      primaryKey: "true",
      required: "true",
    },
    {
      name: "Period Base Level",
      type: "VARCHAR",
      primaryKey: "false",
      required: "true",
    },
    {
      name: "Period Start",
      type: "TIMESTAMP",
      primaryKey: "false",
      required: "true",
    },
    {
      name: "Period End",
      type: "TIMESTAMP",
      primaryKey: "false",
      required: "true",
    },
  ];

  let selectedDimension = data?.find((item) => item._id === selectedRows);
  const timeAttributeSchemaField = isEdit && selectedDimension ? selectedDimension?.timeSchemaFields : timeSchemaFields;
  const userId = localStorage.getItem("accessToken");
  let initialVal = {
    name: "",
    note: "",
    type: "",
    referenceDim: "",
    enterpriseName: "",
    modelName: "",
    validFrom: "",
    validTo: "",
    primaryTimeDimension: false,
    AttributeUID: "",
    CustomAttribute: "",
    PeriodType: "",
    primaryKey: [],
    fields: [],
    requiredFields: []
  };
  let dimensionAttr = [];
  let dimensionSelected = [];
  let initialRefMapping = [];
  if (selectedDimension && (isEdit || isView)) {
    initialVal.name = selectedDimension.name;
    initialVal.note = selectedDimension.description;
    initialVal.type = selectedDimension.type;
    initialVal["Period Type"] = selectedDimension["Period Type"];
    initialVal.referenceDim = selectedDimension?.referencedDimension?.name;
    initialVal.modelName = selectedDimension?.entermodelUID?.id;
    initialVal.enterpriseName = selectedDimension?.enterpriseUID?._id;
    initialVal.fields = selectedDimension.fields;
    initialVal.primaryKey = selectedDimension.primaryKey;
    dimensionAttr = selectedDimension.fields;
    dimensionAttr = dimensionAttr.map((attribute) => {
      const updatedAttribute = { ...attribute };
      if (
        selectedDimension.primaryKey
          .map((item) => item._id)
          .includes(attribute._id)
      ) {
        updatedAttribute["primaryKey"] = true;
      }
      if (
        selectedDimension.requiredFields
          .map((item) => item._id)
          .includes(attribute._id)
      ) {
        updatedAttribute["required"] = true;
      }
      return updatedAttribute;
    });
    dimensionSelected = selectedDimension.compoundDimensions;
    dimensionSelected = selectedDimension.compoundDimensions.map(attribute => {
      return {
        ...attribute,
        fields: attribute.fields.map(item => {
          const updatedItem = { ...item };
          if (selectedDimension.primaryKey.some(row => row._id === item._id)) {
            updatedItem.primaryKey = true;
          }
          if (selectedDimension.requiredFields.some(row => row._id === item._id)) {
            updatedItem.required = true;
          }
          return updatedItem;
        })
      };
    });


    initialRefMapping = selectedDimension.refAttributeMapping
      ? selectedDimension.refAttributeMapping
      : [];
    initialVal.validFrom = selectedDimension.validFrom || "";
    initialVal.validTo = selectedDimension.validTo || "";
    initialVal.primaryTimeDimension =
      selectedDimension.primaryTimeDimension || false;
  }

  useEffect(() => {
    initialVal.modelName = selectedDimension?.entermodelUID?.id;
  }, [selectedDimension]);
  const [periodData, setPeriodData] = useState([])
  const [timeDimensionData, setTimeDimensionData] = useState(timeAttributeSchemaField);
  const [assignedAttributedData, setAssignedAttributedData] = useState(
    AssignedAttributedFiled
  );
  const [assignedAttributedDataCustom, setAssignedAttributedDataCustom] = useState(
    []
  );
  const [dimensionAttributes, setDimensionAttributes] = useState(dimensionAttr);
  const [selectedDimensions, setSelectedDimensions] =
    useState(dimensionSelected);
  const [validations, setValidations] = useState({
    name: "",
    note: "",
    type: "",
    AttributeUID: "",
    CustomAttribute: "",
    PeriodType: ""
  });
  const [formValues, setformValues] = useState(initialVal);
  const [refAttributeMapping, setRefAttributeMapping] = useState(initialRefMapping);
  const [refrenceDimensions, setRefrenceDimensions] = useState([]);
  const validateOne = (e) => {
    const { name } = e.target;
    const value = formValues[name];
    let message = "";
    if (!value) {
      message = `${name} is required`;
    }
    setValidations({ ...validations, [name]: message });
  };
  const handleDimensionFieldChange = (fieldName, event) => {
    const value = event?.target?.value;
    if (fieldName === 'modelName') {
      const selectedData = modalData.filter((data) => data.modalUID === value);
      setformValues({
        ...formValues,
        [fieldName]: selectedData[0]?.modelName,
        [`modelUID`]: value
      });
    } else if (fieldName === 'enterpriseName') {
      const selectedData = datas.filter((data) => data._id === value);
      setformValues({
        ...formValues,
        [fieldName]: selectedData[0]?.enterpriseName,
        [`enterpriseUID`]: value
      });

    } else if (fieldName === 'Period Type') {
      setformValues((prevFormValues) => ({
        ...prevFormValues,
        [fieldName]: value,
      }));
    }
    else {
      setformValues((prevValues) => ({
        ...prevValues,
        [fieldName]: event.target.value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedDimensions.length < 2 && formValues.type === "COMPOUND") {
      toast.error("Please add more than one dimension");
      return;
    }
    if (!formValues.referenceDim && formValues.type === "REFERENCE") {
      toast.error("Please select reference dimension");
      return;
    }
    if (!formValues.name || !formValues.type) {
      toast.error("Please fill required fields");
      return;
    }
    await createUpdateDimension();
  };

  const handleCancel = () => {
    setIsCreate(false);
  };
  let selectedReferencedDimansion = data.find(
    (item) => item.name === formValues.referenceDim
  );

  const dispatch = useDispatch();
  const createUpdateDimension = async () => {
    // try {
    const payload = {
      name: formValues.name,
      type: formValues.type,
      description: formValues.note,
      fields: formValues.type === "TIME"
        ? selectedDimension?.fields
        : formValues.type === "LEVEL"
          ? selectedDimensions?.flatMap(item => item.fields.map(row => row._id))
          : dimensionAttributes?.map(item => ({ _id: item._id })),

      primaryKey: formValues.type === "TIME"
        ? selectedDimension?.primaryKey?.map(item => ({ _id: item._id }))
        : formValues.type === "LEVEL"
          ? selectedDimensions?.flatMap(item => item.fields.filter(row => row.primaryKey).map(row => row._id))
          : dimensionAttributes.filter(item => item.primaryKey).map(item => ({ _id: item._id })),

      requiredFields: formValues.type === "TIME" ? selectedDimension?.requiredFields?.map((item) => item?._id) : dimensionAttributes
        .filter((item) => item.required)
        .map((item) => item?._id),
      compoundDimensions: selectedDimensions.map((item) => item?._id),
      referencedDimension: selectedReferencedDimansion?._id,
      enterpriseUID: isEdit ? formValues.enterpriseName : formValues.enterpriseUID,
      entermodelUID: isEdit ? formValues.modelName : formValues.modelUID,
      validFrom: formValues.validFrom,
      validTo: formValues.validTo,
      "Period Type": formValues.type === "LEVEL" && formValues["Period Type"],
      primaryTimeForLevel: periodData?._id,
      // ...(formValues.type === "LEVEL" && {
      //   primaryTimeDimension: true
      // }),
      ...(formValues.type == "TIME" && {
        primaryTimeDimension: formValues.type === "TIME" && formValues.primaryTimeDimension,
      }),
      ...(formValues?.type == "TIME" && {
        timeSchemaFields: formValues.type === "TIME" && timeDimensionData,
      }),
      ...(formValues?.type == "TIME" && {
        timeCustomAttributes: formValues.type === "TIME" && dimensionAttributes.map(item => ({
          "Custom Attribute": item.name,
          AttributeUID: item._id,
          "Period Type": item['Period Type'] ? item['Period Type'] : null,
          required: item?.required
        })),
      }),
      ...(formValues?.type == "REFERENCE" && {
        refAttributeMapping: formValues?.type === "REFERENCE" && refAttributeMapping
      }),
    };
    const actionPayload = {
      payload,
      isEdit,
      selectedRows,
      userId,
    };
    const resultAction = await dispatch(createUpdateDimensionAsync(actionPayload));
    if (resultAction?.meta?.requestStatus === 'fulfilled') {
      // toast.success(isEdit ? "Dimension updated successfully" : "Dimension created successfully");
      setIsCreate(false);
      dispatch(getModelListAsync())
    } else if (resultAction.meta.requestStatus === 'rejected') {
      toast.error(resultAction?.error?.message || "Registration Failed")
    }
  };

  const getPageHeading = () => {
    if (isEdit || isView) {
      return formValues.name;
    } else {
      return "New Planning Dimension";
    }
  };
  const handleViewUpdate = () => {
    setIsEdit(true);
    setIsView(false);
    setIsCreate(true);
  };
  const dimenstionData = data?.find((item) => {
    return item.name === formValues.referenceDim;
  });
  const [datas, setDatas] = useState([]);
  const [modalData, setModalData] = useState([]);

  const getEnterpriseList = async () => {
    try {
      const userId = localStorage.getItem("accessToken");
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(userId)?.token}`,
        },
      };

      let response = await axios.get(
        `${API_BASE_URL}/all/getAllEnterprise`,
        config
      );
      setDatas(response.data.enterpriseList);
    } catch (error) {
      console.error("Error fetching enterprise list:", error);
    }
  };

  const getModelList = async () => {
    try {
      const userId = localStorage.getItem("accessToken");
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(userId)?.token}`,
        },
      };

      let response = await axios.get(`${API_BASE_URL}/all/getAllModel`, config);
      let filteredData = isEdit
        ? response.data?.enterpriseList?.filter(
          (item) => item.enterpriseUID?._id === formValues.enterpriseUID
        )
        : response.data?.enterpriseList;

      if (formValues.enterpriseUID) {
        filteredData = filteredData?.filter(
          (dime) => dime.enterpriseUID?._id === formValues.enterpriseUID
        );
      }
      setModalData(filteredData);
    } catch (error) {
      console.error("Error fetching model list:", error);
    }
  };

  useEffect(() => {
    const getPeriodDataList = async () => {
      const userId = localStorage.getItem("accessToken");
      let enterpriseId = isEdit ? formValues?.enterpriseName : formValues?.enterpriseUID
      let modelId = isEdit ? formValues?.modelName : formValues?.modelUID
      const baseURL = `${API_BASE_URL}/dimensions/primaryTimeDimension/${enterpriseId}/${modelId}`;
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(userId)?.token}`,
        },
      };
      let response = await axios.get(baseURL, config);
      setPeriodData(response?.data?.data);
    };
    if ((formValues?.enterpriseUID && formValues?.modelUID) || (formValues?.enterpriseName && formValues?.modelName)) {
      getPeriodDataList();
    }
  }, [formValues])

  useEffect(() => {

    getEnterpriseList();
    getModelList();
  }, []);

  useEffect(() => {
    if (formValues.enterpriseName) {
      getModelList();
    }
  }, [formValues.enterpriseName]);

  // filter dimension based on enterprise and enter model
  useEffect(() => {
    if (formValues?.enterpriseName && formValues?.modelName) {
      let refreDimensions =
        data.filter(
          (dime) =>
            dime.entermodelUID?.id === formValues?.modelName &&
            dime.enterpriseUID?._id === formValues?.enterpriseName
        ) || [];
      setRefrenceDimensions(refreDimensions);
    }
  }, [formValues?.enterpriseName, formValues?.modelName, data]);

  const [isPrimaryChecked, setIsPrimaryChecked] = useState(formValues.primaryTimeDimension);

  const handlePrimaryCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsPrimaryChecked(isChecked);
    setformValues((prevValues) => ({
      ...prevValues,
      primaryTimeDimension: isChecked,
    }));
  };
  return (
    <div style={{ overflow: "hidden" }}>
      {/* <AppBar position="static" sx={{ backgroundColor: "white" }}> */}
      <Toolbar sx={{ minHeight: "22px !important" }}>
        <Box onClick={() => {
          setIsCreate(false)
        }}
          sx={{ color: 'black', cursor: "pointer", display: "flex", alignItems: "center", gap: "10px" }}
        >
          <ChevronLeftIcon />
          <Typography>Back To Dimension List</Typography>
        </Box>
      </Toolbar>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" sx={{ color: "black" }}>
          {getPageHeading()}
        </Typography>
        {isView && (
          <Button
            onClick={() => {
              handleViewUpdate();
            }}
            className="awesome-button"
            sx={{ color: "white", margin: "0 4px", float: "right" }}
          >
            Edit
          </Button>
        )}
      </Toolbar>
      {/* </AppBar> */}

      <Container style={{ display: "grid" }}>
        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          <Grid item xs={6}>
            <TextField
              id="name"
              name="name"
              variant="outlined"
              fullWidth
              label="Planning Dimension name"
              value={formValues.name}
              onChange={(e) => handleDimensionFieldChange("name", e)}
              placeholder="Enter name"
              onBlur={validateOne}
              disabled={isView}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              {isEdit || isView ? (
                <TextField
                  id="enterpriseType"
                  name="enterpriseType"
                  variant="outlined"
                  fullWidth
                  label="Enterprise Name"
                  value={selectedDimension?.enterpriseUID?.enterpriseName}
                  disabled={true}
                />
              ) : (
                <>
                  <InputLabel id="enterpriseType">Enterprise Name</InputLabel>
                  <Select
                    labelId="enterpriseType"
                    id="demo-simple-select-readonly"
                    value={formValues.enterpriseUID}
                    fullWidth
                    onChange={(e) => handleDimensionFieldChange("enterpriseName", e)}
                    label="Enterprise Type"
                    variant="outlined"
                  >
                    {datas?.map((data1) => (
                      <MenuItem key={data1._id} value={data1._id}>
                        {data1.enterpriseName}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              {isEdit || isView ? (
                <TextField
                  id="entermodelType"
                  name="entermodelType"
                  variant="outlined"
                  fullWidth
                  label="Model Name"
                  value={selectedDimension?.entermodelUID?.modelName}
                  disabled={true}
                />
              ) : (
                <>
                  <InputLabel htmlFor="modelName">Model Name</InputLabel>
                  <Select
                    labelId="enterpriseType"
                    id="demo-simple-select-readonly"
                    value={formValues.modelUID}
                    fullWidth
                    onChange={(e) => handleDimensionFieldChange("modelName", e)}
                    label="Model Name"
                    variant="outlined"
                    disabled={formValues.enterpriseName === "" ? true : false}
                  >
                    {modalData?.map((data1) => (
                      <MenuItem key={data1.modalUID} value={data1.modalUID}>
                        {data1.modelName}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            {/* {( isView) && ( */}
            <>
              <FormControl fullWidth>
                <InputLabel> Planning Dimension Type</InputLabel>
                <Select
                  id="type"
                  name="type"
                  value={formValues.type}
                  onChange={(e) => handleDimensionFieldChange("type", e)}
                  fullWidth
                  label="Planning Dimension Type"
                  variant="outlined"
                  style={{ marginBottom: "16px" }}
                  onBlur={validateOne}
                  disabled={isView}
                >
                  <MenuItem value="" disabled>
                    Select Type
                  </MenuItem>
                  {options.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

            </>
            {/* )} */}

            {formValues.type === "REFERENCE" && (
              <>
                <InputLabel htmlFor="type">
                  Reference Dimension
                  <span
                    style={{
                      color: "red",
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginLeft: "2px",
                    }}
                  >
                    *
                  </span>
                </InputLabel>
                <Select
                  id="referenceDim"
                  name="referenceDim"
                  value={formValues.referenceDim}
                  onChange={(e) => handleDimensionFieldChange("referenceDim", e)}
                  fullWidth
                  variant="outlined"
                  style={{ marginBottom: "16px" }}
                  onBlur={validateOne}
                  disabled={isView || isEdit}
                >
                  <MenuItem value="" disabled>
                    Select Dimension
                  </MenuItem>
                  {data?.length > 0 ? data?.filter((item) => item?.type === "SIMPLE")
                    ?.filter((item) => item?.enterpriseUID?._id === formValues?.enterpriseUID)
                    ?.filter((item) => item?.entermodelUID?.modelUID === formValues?.modelUID)
                    .map((item) => (
                      <MenuItem key={item?.name} value={item?.name}>
                        {item.name}
                      </MenuItem>
                    )) : (
                    <MenuItem>
                      Not available dimension
                    </MenuItem>
                  )}
                </Select>

              </>
            )}
          </Grid>

          {formValues.type === "LEVEL" && (
            <>
              <Grid item xs={6}>
                {!isView &&
                  <FormControl fullWidth>
                    <InputLabel htmlFor="Period Type">Period Type</InputLabel>
                    <Select
                      fullWidth
                      id="Period Type"
                      name="Period Type"
                      label="Period Type"
                      variant="outlined"
                      onBlur={validateOne}
                      value={formValues["Period Type"]}
                      disabled={isView === "" ? true : false}
                      onChange={(e) => {
                        handleDimensionFieldChange("Period Type", e); // Call a function to handle the change
                      }}
                    >

                      {periodData?.timeSchemaFields?.filter((item) => item?.enabled === true)?.map((data1) => (
                        <MenuItem key={data1.Level} value={data1["Period Type"]}>
                          {data1["Period Type"]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }

              </Grid>
            </>
          )}
        </Grid>
      </Container>
      {formValues.type === "LEVEL" && (
        <>
          <PlanningLevelDimension
            dimensionAttributes={dimensionAttributes}
            setDimensionAttributes={setDimensionAttributes}
            isView={isView}
            isEdit={isEdit}
            isCreate={isCreate}
            newData={formValues}
            formValues={formValues}
            setformValues={setformValues}
            data={data}
            selectedDimension={selectedDimension}
            selectedDimensions={selectedDimensions}
            setSelectedDimensions={setSelectedDimensions}
            setRefAttributeMapping={setRefAttributeMapping}
            refAttributeMapping={refAttributeMapping}
            dimenstionData={dimenstionData}
          />
        </>
      )}

      {formValues.type === "COMPOUND" && (
        <>
          <SelectedDimensionsTable
            selectedDimensions={selectedDimensions}
            setSelectedDimensions={setSelectedDimensions}
            dimensionAttributes={dimensionAttributes}
            setDimensionAttributes={setDimensionAttributes}
            isView={isView}
            isEdit={isEdit}
            formValues={formValues}
            newData={formValues}
          />
        </>
      )}

      {formValues.type === "TIME" && (
        <>
          <Container style={{ display: "grid" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="validFrom"
                  label="Valid From"
                  variant="outlined"
                  fullWidth
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formValues.validFrom} // Add value attribute
                  onChange={(e) => handleDimensionFieldChange("validFrom", e)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="validTo"
                  label="Valid To"
                  variant="outlined"
                  fullWidth
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: formValues.validFrom,
                  }}
                  value={formValues.validTo}
                  onChange={(e) => handleDimensionFieldChange("validTo", e)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="note"
                  name="note"
                  label="Planning Dimension notes"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                  value={formValues.note}
                  onChange={(e) => handleDimensionFieldChange("note", e)}
                  placeholder="Enter note"
                  onBlur={validateOne}
                  disabled={isView}
                />
              </Grid>

              <Grid item xs={6}>
                <Checkbox
                  checked={isPrimaryChecked}
                  onChange={handlePrimaryCheckboxChange}
                  value={formValues.primaryTimeDimension}

                />
                Primary
              </Grid>
            </Grid>
          </Container>
          <TimeDimensionsTable
            selectedDimensions={selectedDimensions}
            setSelectedDimensions={setSelectedDimensions}
            dimensionAttributes={dimensionAttributes}
            setDimensionAttributes={setDimensionAttributes}
            isView={isView}
            isEdit={isEdit}
            formValues={formValues}
            setformValues={setformValues}
            setTimeDimensionData={setTimeDimensionData}
            timeDimensionData={timeDimensionData}
          />
          <TimeAttribute
            dimensionAttributes={dimensionAttributes}
            setDimensionAttributes={setDimensionAttributes}
            isView={isView}
            isEdit={isEdit}
            formValues={formValues}
            setformValues={setformValues}
            data={data}
            setRefAttributeMapping={setRefAttributeMapping}
            refAttributeMapping={refAttributeMapping}
            dimenstionData={dimenstionData}
            setTimeDimensionData={setTimeDimensionData}
            timeDimensionData={timeDimensionData}
            assignedAttributedData={assignedAttributedData}
            setAssignedAttributedData={setAssignedAttributedData}
          />
          <CustomTimeAttribute
            dimensionAttributes={dimensionAttributes}
            setDimensionAttributes={setDimensionAttributes}
            isView={isView}
            isEdit={isEdit}
            newData={formValues}
            formValueData={formValues}
            selectedDimension={selectedDimension}
            setformValues={setformValues}
            data={data}
            setRefAttributeMapping={setRefAttributeMapping}
            refAttributeMapping={refAttributeMapping}
            dimenstionData={dimenstionData}
            setTimeDimensionData={setTimeDimensionData}
            timeDimensionData={timeDimensionData}
            assignedAttributedData={assignedAttributedDataCustom}
            setAssignedAttributedData={setAssignedAttributedDataCustom}
          />
        </>
      )}

      {formValues.type === "SCENARIO" && (
        <>
          <Container style={{ display: "grid" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="note"
                  name="note"
                  label="Planning Dimension notes"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                  value={formValues.note}
                  onChange={(e) => handleDimensionFieldChange("note", e)}
                  placeholder="Enter note"
                  onBlur={validateOne}
                  disabled={isView}
                />
              </Grid>
            </Grid>
          </Container>
          <ScenariosTable
            dimensionAttributes={dimensionAttributes}
            setDimensionAttributes={setDimensionAttributes}
            isView={isView}
            isEdit={isEdit}
            formValues={formValues}
            setformValues={setformValues}
            data={data}
            setRefAttributeMapping={setRefAttributeMapping}
            refAttributeMapping={refAttributeMapping}
            dimenstionData={dimenstionData}
          />
        </>
      )}

      {formValues.type === "COMPOUND" && (
        <AttributesTable
          dimensionAttributes={dimensionAttributes}
          setDimensionAttributes={setDimensionAttributes}
          isView={isView}
          isEdit={isEdit}
          isCreate={isCreate}
          newData={formValues}
          formValues={formValues}
          setformValues={setformValues}
          data={data}
          selectedDimension={selectedDimension}
          setRefAttributeMapping={setRefAttributeMapping}
          refAttributeMapping={refAttributeMapping}
          dimenstionData={dimenstionData}
        />
      )}

      {formValues.type === "SIMPLE" && (
        <AttributesTable
          dimensionAttributes={dimensionAttributes}
          setDimensionAttributes={setDimensionAttributes}
          isView={isView}
          isEdit={isEdit}
          isCreate={isCreate}
          newData={formValues}
          formValues={formValues}
          setformValues={setformValues}
          data={data}
          selectedDimension={selectedDimension}
          setRefAttributeMapping={setRefAttributeMapping}
          refAttributeMapping={refAttributeMapping}
          dimenstionData={dimenstionData}
        />
      )}

      {formValues.type === "REFERENCE" && formValues.referenceDim && (
        <>
          <ReferenceAttributes dimenstionData={dimenstionData} />
          <AttributesTable
            dimensionAttributes={dimensionAttributes}
            setDimensionAttributes={setDimensionAttributes}
            isView={isView}
            isEdit={isEdit}
            isCreate={isCreate}
            newData={formValues}
            formValues={formValues}
            setformValues={setformValues}
            data={data}
            selectedDimension={selectedDimension}
            setRefAttributeMapping={setRefAttributeMapping}
            refAttributeMapping={refAttributeMapping}
            dimenstionData={dimenstionData}
          />
        </>
      )}

      {formValues.type === "LEVEL" && (
        <>
          <hr
            style={{
              borderTop: "2px solid #1976d2",
              width: "100%",
              margin: "30px auto",
            }}
          />

          <Container style={{ display: "grid" }}>
            <Grid container spacing={2} style={{ marginTop: "20px" }}>
              <Grid item xs={12}>
                <TextField
                  id="note"
                  name="note"
                  label="Planning Dimension notes"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={6}
                  value={formValues.note}
                  onChange={(e) => handleDimensionFieldChange("note", e)}
                  placeholder="Enter note"
                  onBlur={validateOne}
                  disabled={isView}
                />
              </Grid>
            </Grid>
          </Container>
        </>
      )}


      {!isView && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginRight: "30px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Button
            disabled={isView}
            onClick={handleCancel}
            title="Cancel"
            style={{
              // border: "1px solid #00000040",
              // color: "blue",
              padding: "6px 15px",
              borderRadius: "2px",
              cursor: "pointer",
              marginRight: "10px",
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={isView}
            onClick={handleSubmit}
            title="Create new dimension with selected attributes"
            style={{
              // color: "white",
              // backgroundColor: "#1976d2",
              padding: "7px 15px",
              borderRadius: "2px",
              cursor: "pointer",
            }}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
};

export default CreateUpdateDimension;
