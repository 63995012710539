import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MiniDrawer from '../common/drawer/DrawerToggle';
import {
    Box,
    Button,
    Tooltip
    // Autocomplete
} from '@mui/material';
import { API_BASE_URL } from '../../helper/constants';
import axios from 'axios';
import { getMeasureList } from '../../store/ApiSlice/measureSlice';
import { getAttributes } from '../../store/ApiSlice/attributeSlice';
import CanvasMaterialList from './CanvasMaterialList';
import { deleteCanvasList, getCanvasList, getPageList, getWorkBookDetail } from '../../store/ApiSlice/canvasSlice';
import { loadUsersList } from '../../store/ApiSlice/authSlice';
import moment from 'moment';
import DeleteModal from '../commonComponent/DeleteComponent/DeleteModal';
import CanvasModal from '../commonComponent/CanvasModal/CanvasModal';
import { ThemeContext } from '../../ThemeContext';

const CanvasList = () => {
    const { isDarkTheme } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const { canvasData } = useSelector((state) => state.canvas)
    const [isEdit, setIsEdit] = useState(false)
    const [isCreate, setIsCreate] = useState(false)
    const [isView, setIsView] = useState(false);
    const [CanvasOpenModal, setCanvasOpenModal] = useState(false)
    const [updatedId, setUpdatedId] = useState(null);
    const [modalName, setModalName] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isModalOpenDelete, setModalOpenDelete] = useState(false);
    const [attriId, setAttriId] = useState(null);
    const [formValues, setFormValues] = useState({
        pages: []
    });
    useEffect(() => {
        let editRow = canvasData?.length > 0 && canvasData?.find((item) => item._id === updatedId);
        if (editRow && isEdit) {
            setFormValues({
                canvasName: editRow?.name,
                createdOn: moment(editRow?.createdOn).format("YYYY-MM-DD"),
                changedOn: moment(editRow?.changedOn).format("YYYY-MM-DD"),
                enterpriseUID: editRow?.enterpriseUID?._id,
                enterpriseName: editRow?.enterpriseUID?.enterpriseName,
                modelName: editRow?.modelUID?.modelName,
                modelUID: editRow?.modelUID?.modalUID,
            })
        }
    }, [canvasData, isEdit, updatedId])

    const getModelList = async () => {
        try {
            const userId = localStorage.getItem("accessToken");
            const config = {
                headers: {
                    Authorization: `Bearer ${JSON.parse(userId)?.token}`,
                },
            };

            let response = await axios.get(`${API_BASE_URL}/all/getAllModel`, config);
            let filteredData = isEdit
                ? response.data?.enterpriseList?.filter(
                    (item) => item.enterpriseUID?._id === formValues.enterpriseUID
                )
                : response.data?.enterpriseList;

            if (formValues.enterpriseName) {
                filteredData = filteredData?.filter(
                    (item) => item.enterpriseUID?._id === formValues.enterpriseUID
                );
            }
            setModalName(filteredData);
        } catch (error) {
            console.error("Error fetching model list:", error);
        }
    };

    useEffect(() => {
        if (formValues.enterpriseName) {
            getModelList();
        }
    }, [formValues.enterpriseName]);

    useEffect(() => {
        dispatch(getMeasureList())
        dispatch(getAttributes())
        dispatch(getCanvasList())
        dispatch(getWorkBookDetail())
        dispatch(getPageList())
    }, [])

    const handleOpenCanvas = () => {
        setCanvasOpenModal(true);
        setUpdatedId(null)
    };

    return (
        <>
            {!isCreate && !isView && (
                <>
                    <Box sx={{ display: "flex" }}>
                        <MiniDrawer />
                        <Box component="main" sx={{
                            flexGrow: 1, p: 3, overflow: 'auto', height: "100vh",
                            background: isDarkTheme ? "whitesmoke" : "white",
                        }}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginTop: "60px",
                                    paddingBottom: "20px",
                                    position: "relative"
                                }}
                            >
                                <h3 className="heading">
                                    Canvas
                                </h3>
                                <Tooltip title="Add Canvas">
                                    <Button
                                        onClick={() => {
                                            handleOpenCanvas()
                                        }}
                                        className="awesome-button"
                                        sx={{ color: "white", margin: "0 4px", fontSize: "1.875rem !important" }}
                                    >
                                        +
                                    </Button>
                                </Tooltip>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {CanvasOpenModal && (

                                    <CanvasModal
                                        formValues={formValues}
                                        setFormValues={setFormValues}
                                        updatedId={updatedId}
                                        isEdit={isEdit}
                                        setCanvasOpenModal={setCanvasOpenModal}
                                        CanvasOpenModal={CanvasOpenModal}
                                        setIsEdit={setIsEdit}
                                        isView={isView}
                                    />
                                )}
                            </div>
                            <CanvasMaterialList
                                setModalOpenDelete={setModalOpenDelete}
                                setAttriId={setAttriId}
                            />

                            <DeleteModal
                                isModalOpenDelete={isModalOpenDelete}
                                setModalOpenDelete={setModalOpenDelete}
                                attriId={attriId}
                                setSelectedRows={setSelectedRows}
                                title="Canvas"
                                deleteAPI={deleteCanvasList}
                                getAPI={getCanvasList}
                            />
                            {/* <div>
                                <HighchartsReact highcharts={Highcharts} options={options} />
                            </div> */}
                        </Box>
                    </Box>
                </>
            )}

        </>
    )
}

export default CanvasList
